import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'mvm-radio-button-with-label',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './radio-button-with-label.component.html',
  styleUrls: ['./radio-button-with-label.component.scss']
})
export class RadioButtonWithLabelComponent {
  @Input() label: string  = ''
  @Input() inputId: string = ''
  @Input() inputName: string = 'radio'
  @Input() control!: FormControl;
  @Input() value!: string

}
