import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AngularSvgIconModule} from "angular-svg-icon";
import {RouterLink} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {LocalizeRouterModule} from "@gilsdav/ngx-translate-router";

@Component({
  selector: 'mvm-more-programs-card',
  standalone: true,
    imports: [CommonModule, AngularSvgIconModule, RouterLink, TranslateModule, LocalizeRouterModule],
  templateUrl: './more-programs-card.component.html',
  styleUrls: ['./more-programs-card.component.scss']
})
export class MoreProgramsCardComponent {

}
