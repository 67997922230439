import {animate, style, transition, trigger} from "@angular/animations";


export const slideFadeInOut = [
  trigger('slideFadeInOut', [
    transition(':enter', [
      style({ position: 'relative', opacity: 0, transform: 'translateY(50px)' }),
      animate('200ms 200ms', style({ opacity: 1, transform: 'translateY(0)' })),
    ]),
    transition(':leave', [
      style({ opacity: 1, height: 0, transform: 'translateY(0)'}),
      animate('200ms', style({ opacity: 0, transform: 'translateY(50px)' }))
    ])
  ]),
]

export const slideDownFadeInOut = [
  trigger('slideDownFadeInOut', [
    transition(':enter', [
      style({ position: 'relative', opacity: 0, transform: 'translateY(-50px)' }),
      animate('200ms 200ms', style({ opacity: 1, transform: 'translateY(0)' })),
    ]),
    transition(':leave', [
      style({ opacity: 0, height: 0, }),
      animate('1ms', style({ opacity: 0 }))
    ])
  ]),
]
