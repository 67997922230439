import {Component, OnDestroy, Renderer2} from '@angular/core';
import { CommonModule } from '@angular/common';
import {CardSendTicketComponent} from "../cards/card-send-ticket/card-send-ticket.component";
import {Subscription} from "rxjs";
import {SendTicketModalService} from "../../services/sendTicketService/send-ticket-modal.service";

@Component({
  selector: 'mvm-modal-send-ticket',
  standalone: true,
    imports: [CommonModule, CardSendTicketComponent],
  templateUrl: './modal-send-ticket.component.html',
  styleUrls: ['./modal-send-ticket.component.scss']
})
export class ModalSendTicketComponent implements OnDestroy{

  visible: boolean = true
  ticketId?: string
  ticketType?: "parking" | "simple"
  private listener?: () => void
  private subscription?: Subscription;

  constructor(
    private renderer: Renderer2, 
    private service: SendTicketModalService
    
    ) {
    this.subscription = this.service.state$.subscribe(state => {
      this.visible = state.visible
      this.ticketId = state.ticketId
      this.ticketType = state.ticketType
      if(this.ticketId === undefined || this.ticketType === undefined){
        // do not let it open if anything undefined
        this.visible = false
      }
      if (this.visible) {
        this.listener = this.renderer.listen("document", "keyup.escape", _e => {
          this.close()
        })
      } else {
        this.close()
      }
    })
  }

  close() {
    this.visible = false
    this.listener?.()
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

}
