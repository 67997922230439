import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VipMessageComponent } from "../../../shared/components/cards/vip-message/vip-message.component";
import { GreyFrameComponent } from "../../../shared/components/grey-frame/grey-frame.component";
import { MyTicketComponent } from "../../../shared/components/cards/my-ticket/my-ticket.component";
import { CardFoodAdComponent } from "../../../shared/components/cards/card-food-ad/card-food-ad.component";
import { BillsComponent } from "../../../shared/components/bills/bills.component";
import { IncomingEventsComponent } from "../../../shared/components/cards/incoming-events/incoming-events.component";
import { HowToApproachComponent } from "../../../shared/components/how-to-approach/how-to-approach.component";
import { MapsModule } from "../../../maps/maps.module";
import { ContainerComponent, ContainerTypes } from "../../../shared/components/container/container.component";
import { FooterComponent } from "../../components/footer/footer.component";
import { NavbarComponent } from "../../components/navbar/navbar.component";
import { HomeDetailsComponent } from "../../components/home/home-details/home-details.component";
import { PartnerBannerComponent } from "../../../shared/components/partner-banner/partner-banner.component";
import { CoreComponent } from "../../layouts/core-layout/core.component";
import { TranslateModule } from "@ngx-translate/core";
import { DashboardApiService } from "../../../shared/services/apiServices/dashboardApiService/dashboard-api.service";
import { Store } from "@ngrx/store";
import {
  selectDashboardEvents,
  selectDashboardInvoices,
  selectDashboardVipMessage,
  selectDashboardShowVipFunctions,
} from "../../../store/selectors/dashboard.selector";
import { selectAuth } from "../../../store/selectors/auth.selector";
import { HandleErrorAndSuccess } from 'src/app/shared/interfaces/common/handle-error-and-success';
import { BuyParkingModalService } from "../../../shared/services/buyParkingTicketModalService/buy-parking-modal.service";
import { addGoogleAds } from "../../../shared/addGoogleAds";

@Component({
  selector: 'mvm-dashboard',
  standalone: true,
  imports: [CommonModule, VipMessageComponent, GreyFrameComponent, MyTicketComponent, CardFoodAdComponent, BillsComponent, IncomingEventsComponent, HowToApproachComponent, MapsModule, ContainerComponent, FooterComponent, NavbarComponent, HomeDetailsComponent, PartnerBannerComponent, CoreComponent, TranslateModule],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, HandleErrorAndSuccess {
  ContainerType = ContainerTypes
  auth$ = this.store.select(selectAuth)

  events$ = this.store.select(selectDashboardEvents)
  invoices$ = this.store.select(selectDashboardInvoices)
  vipMessage$ = this.store.select(selectDashboardVipMessage)
  showVipFunctions$ = this.store.select(selectDashboardShowVipFunctions)
  isRequesting: boolean = true

  constructor(
    private dashboardService: DashboardApiService,
    private store: Store,
    ) {
  }
  
  ngOnInit(): void {
    this.dashboardService.loadData(this)
  }

  /* handles success subscription request */
  public handleSuccessResponse(res: any): void {
    this.isRequesting = false
  }

  /* handles failed subscription request */
  public handleErrorResponse(err: string): void {
    this.isRequesting = false
  }

  ngAfterViewInit(): void {
    addGoogleAds("div-gpt-ad-1680630181401-0");
  }

}
