import {createFeatureSelector, createSelector} from "@ngrx/store";
import {HomeState} from "../states/home.state";

export const homeFeatureKey = 'home';

export const selectHome = createFeatureSelector<HomeState>(homeFeatureKey)

export const selectHomeAdvert = createSelector(
    selectHome,
    (state: HomeState) => state.advert
)

export const selectHomeEvents = createSelector(
    selectHome,
    (state: HomeState) => state.events
)

export const selectCoverImage = createSelector(
    selectHome,
    (state: HomeState) => state.cover_image
)