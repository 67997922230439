import {Injectable} from '@angular/core';
import {ApiRequestService} from "../apiRequestService/api-request.service";
import {Store} from "@ngrx/store";
import {EventsResponse} from "../../../interfaces/events/events.types";
import {HttpParams} from "@angular/common/http";
import {eventsLoaded} from "../../../../store/actions/events.actions";

@Injectable({
  providedIn: 'root'
})
export class EventsApiService {

  constructor(private _apiRequestService: ApiRequestService,
              private _store: Store) {
  }

  loadDataQuery(pageNumber?: number, isBatch?: boolean, type?: "concert" | "corporate" | "sport", querystring?: string) {

    let params = new HttpParams()
    pageNumber ? params = params.append('pageNumber', pageNumber) : ''
    isBatch ? params = params.append('isBatch', isBatch) : ''
    type ? params = params.append('type', type) : ''
    querystring ? params = params.append('q', querystring) : ''

    this._apiRequestService.getRequest("search", (res) => {
      this.eventsSuccess(res)
    }, undefined, true, params)
  }

  private eventsSuccess(res: EventsResponse) {
    this._store.dispatch(eventsLoaded({
      events: res.data
    }))
  }
}
