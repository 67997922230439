<footer class="mobile-footer">
  <mvm-container [type]="ContainerTypes.Footer">
    <div class="logo login flex justify-between items-center">
      <svg-icon name="mvm" [stretch]="false" [svgStyle]="{'width.px':'150'}"></svg-icon>

      <button class="btn-yellow" *ngIf="!(loggedIn | async)" [routerLink]="['/login'] | localize">{{'footer.login' | translate}}</button>
    </div>
    <div class="flex flex-col links">
      <div class="contact">
        <h2 class="text-h1 text-white">{{'footer.contact' | translate}}</h2>
        <a href="mailto:mvm-dome.vip@sportfive.com" aria-label="Contact" class="text-default text-white">mvm-dome.vip@sportfive.com</a>
        <p class="text-default text-white">{{'footer.address' | translate}}</p>
      </div>
      <div class="docs">
        <h2 class="text-h1 text-white">{{'footer.docs' | translate}}</h2>
        <a href="/assets/docs/Altalanos_hasznalati_felltetelek_hun.pdf" [attr.aria-label]="'footer.aszf' | translate" target="_blank" class="text-default text-white">{{'footer.aszf' | translate}}</a>
        <a target="_blank"  href="/assets/docs/Adatkezelési_Tajekoztato_hun_eng.pdf" class="text-default text-white" [attr.aria-label]="'footer.privacy' | translate">{{'footer.privacy' | translate}}</a>
        <a href="https://mvm-dome.hu/pdf/S%C3%BCti_t%C3%A1j%C3%A9koztat%C3%B3_hun_eng.pdf" target="_blank" class="text-default text-white" [attr.aria-label]="'footer.cookie' | translate">{{'footer.cookie' | translate}}</a>
        <a href="/assets/docs/Hazirend_hun.pdf" target="_blank" class="text-default text-white" [attr.aria-label]="'footer.rules' | translate">{{'footer.rules' | translate}}</a>
        <a href="/assets/docs/MVM_Dome_GYIK.pdf" class="text-default text-white" [attr.aria-label]="'footer.gyik' | translate">{{'footer.gyik' | translate}}</a>
        <a [routerLink]="['/impressum'] | localize" class="text-default text-white" [attr.aria-label]="'footer.imprint' | translate">{{'footer.imprint' | translate}}</a>
      </div>

    </div>
    <div class="images flex justify-between items-center">
      <div class="flex justify-between items-center social">
        <a href="https://www.facebook.com/mvmdomeofficial/" target="_blank" [attr.aria-label]="'Facebook'"><svg-icon name="facebook-white"></svg-icon></a>
        <a href="https://www.instagram.com/mvm_dome/" target="_blank" [attr.aria-label]="'Instagram'"><svg-icon name="instagram-white"></svg-icon></a>
      </div>
      <a href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" [attr.aria-label]="'SimplePay'" target="_">
        <img class="simplepay" src="/assets/images/png/simplepay.png" alt="Simplepay logos">
      </a>
    </div>
    <p class="text-default text-white copyright">Copyright © 2023 MVM Dome.</p>
    <div class="prog-oak-logo flex justify-center items-center w-full">
      <a href="https://www.prog-oak.com" rel="noreferrer noopener" [attr.aria-label]="'Prog-Oak Kft.'">
        <svg-icon name="progoak-logo"></svg-icon>
      </a>
    </div>
  </mvm-container>
</footer>

<footer class="desktop-footer">
  <mvm-container [type]="ContainerTypes.Footer">
    <div class="logo login flex justify-between items-center">
      <svg-icon name="mvm"  [stretch]="true" [svgStyle]="{'width.px':'190'}"></svg-icon>
      <div class="flex justify-between items-center social">
        <a href="https://www.facebook.com/mvmdomeofficial/" target="_blank" [attr.aria-label]="'Facebook'"><svg-icon name="facebook-white"></svg-icon></a>
        <a href="https://www.instagram.com/mvm_dome/" target="_blank" [attr.aria-label]="'Instagram'"><svg-icon name="instagram-white"></svg-icon></a>
        <button class="btn-yellow" *ngIf="!(loggedIn | async)"  [routerLink]="['/login'] | localize">{{'footer.login' | translate}}</button>
      </div>
    </div>
    <div class="flex content-container justify-between">
      <div class="flex links-container">
        <div class="contact">
          <h2 class="text-h1 alt text-white">{{'footer.contact' | translate}}</h2>
          <!--<a href="tel:+3612345678" class="text-default text-white">+36 1 234 5678</a>-->
          <a href="mailto:mvm-dome.vip@sportfive.com" class="text-default text-white" [attr.aria-label]="'E-mail'">mvm-dome.vip@sportfive.com</a>
          <p class="text-default text-white">{{'footer.address' | translate}}</p>
        </div>
        <div class="legal">
          <h2 class="text-h1 alt text-white">{{'footer.docs' | translate}}</h2>
          <a href="/assets/docs/Altalanos_hasznalati_felltetelek_hun.pdf" target="_blank" [attr.aria-label]="'footer.aszf' | translate" class="text-default text-white">{{'footer.aszf' | translate}}</a>
          <a href="/assets/docs/Adatkezelési_Tajekoztato_hun_eng.pdf" target="_blank" [attr.aria-label]="'footer.privacy' | translate"   class="text-default text-white">{{'footer.privacy' | translate}}</a>
          <a href="https://mvm-dome.hu/pdf/S%C3%BCti_t%C3%A1j%C3%A9koztat%C3%B3_hun_eng.pdf" target="_blank" class="text-default text-white" [attr.aria-label]="'footer.cookie' | translate" >{{'footer.cookie' | translate}}</a>
        </div>
        <div class="legal">
          <h2 class="text-h1 alt text-white opacity-0">{{'footer.docs' | translate}}</h2>
          <div class="legal-split-wrapper flex">
            <div>
              <a href="/assets/docs/Hazirend_hun.pdf" target="_blank"  class="text-default text-white" [attr.aria-label]="'footer.rules' | translate">{{'footer.rules' | translate}}</a>
              <a href="/assets/docs/MVM_Dome_GYIK.pdf" target="_" class="text-default text-white" [attr.aria-label]="'footer.gyik' | translate">{{'footer.gyik' | translate}}</a>
            </div>
            <div>
              <a [routerLink]="['/impressum'] | localize" class="text-default text-white" [attr.aria-label]="'footer.imprint' | translate">{{'footer.imprint' | translate}}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="simplepay-copyright flex flex-col">
        <img class="simplepay" src="/assets/images/png/simplepay.png" alt="Simplepay logos">
        <p class="text-default text-white copyright">Copyright © 2023 MVM Dome.</p>
      </div>
    </div>
    <div class="prog-oak-logo flex justify-center items-center w-full">
      <a href="https://www.prog-oak.com" rel="noreferrer noopener" [attr.aria-label]="'Prog-Oak Kft.'">
        <svg-icon name="progoak-logo"></svg-icon>
      </a>
    </div>
  </mvm-container>
</footer>
