import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackButtonDirective } from './directives/back-button.directive';
import { ResendActivationTokenComponent } from './components/resend-activation-token/resend-activation-token.component';

@NgModule({
    declarations: [
        BackButtonDirective,
    ],
    exports: [
        BackButtonDirective
    ],
    imports: [
        CommonModule
    ]
})
export class SharedModule { }
