import {Component, Input, OnInit} from '@angular/core';
import {CommonModule, Location} from '@angular/common';
import { NavbarComponent } from "../../components/navbar/navbar.component";
import {ContainerComponent, ContainerTypes} from "../../../shared/components/container/container.component";
import {AngularSvgIconModule} from "angular-svg-icon";
import {HowToApproachComponent} from "../../../shared/components/how-to-approach/how-to-approach.component";
import {FooterComponent} from "../../components/footer/footer.component";
import {TranslateModule} from "@ngx-translate/core";
import {ActivatedRoute, RouterModule} from "@angular/router";
import { HelperService } from 'src/app/utils/helper.service';
@Component({
  selector: 'mvm-checkout-layout',
  standalone: true,
    imports: [CommonModule, NavbarComponent, ContainerComponent, AngularSvgIconModule, HowToApproachComponent, FooterComponent, TranslateModule, RouterModule],
  templateUrl: './checkout-layout.component.html',
  styleUrls: ['./checkout-layout.component.scss']
})
export class CheckoutLayoutComponent implements OnInit{
  ContainerTypes = ContainerTypes

  @Input() title: string = "Checkout"
  @Input() done: boolean = false
  public hasFooter: boolean = true
  public hasNavbar: boolean = true
  public isWebView: boolean = false
  checkOutStep = 0

  constructor(
    private activatedRoute: ActivatedRoute, 
    private location: Location,
    private _helperService: HelperService,
    ) {
    const webview = this._helperService.isWebView()
    if(webview) {
      this.isWebView = true
      this.hasNavbar = false
      this.hasFooter = false
    }
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(url => {
      const path = url?.[0]?.path ?? ""
      this.setStep(path)
    })


  }

  private setStep(path: string) {
    if(["checkout-cart", "kosar-megtekintese"].includes(path)) {
      this.checkOutStep = 1
    } else if (["checkout-billing-payment", "adatok-megadasa"].includes(path)) {
      this.checkOutStep = 2
    } else if (["checkout-done", "megrendeles"].includes(path)){
      this.checkOutStep = 4
    } else {
      this.checkOutStep = 0
    }
  }

  get backToLastPage() {
    /**
     *  /cart --> /checkout-cart --> /
    **/
    return '/'
  }

  back() {
    this.location.back()
  }
}
