
<div class="wrapper">
  <mvm-container [type]="ContainerTypes.MobileNavMenu">
    <div class="nav-buttons flex flex-col gap-12px w-full">
      <a (click)="close()" class="btn-nav-mobile-svg-home full" [routerLink]="['/'] | localize" [attr.aria-label]="'navbar.home' | translate">{{'navbar.home' | translate}}</a>
      <a (click)="close()" class="full" [ngClass]="(loggedIn$ | async) ? 'btn-nav-mobile-svg-dashboard' : 'btn-nav-mobile-svg-calendar'" [routerLink]="(loggedIn$ | async) ? (['/dashboard'] | localize) : (['/events'] | localize)">{{(loggedIn$ | async) ? ('navbar.dashboard' | translate) : ('navbar.calendar' | translate)}}</a>
      <a (click)="close()" class="full" [ngClass]="(loggedIn$ | async) ? 'btn-nav-mobile-svg-profile' : 'btn-nav-mobile-svg-crown'" [routerLink]="(loggedIn$ | async) ? (['/edit-profile'] | localize) : (['/vip'] | localize)">{{(loggedIn$ | async) ? ('navbar.profile' | translate) : ('navbar.vip' | translate)}}</a>
      <a (click)="close()" class="btn-nav-mobile-svg-star full" [routerLink]="['/'] | localize" [fragment]="'contact'">{{'navbar.partner' | translate}}</a>
    </div>
    <div class="w-full flex">
     <!-- <mvm-card-program class="w-full"></mvm-card-program>-->
    </div>
    <button (click)="close()" class="btn-yellow w-full" *ngIf="!(loggedIn$ | async)" [routerLink]="['/login'] | localize">{{'login' | translate}}</button>
    <button class="btn-text-yellow text-subtitle w-full" (click)="logout()" *ngIf="(loggedIn$ | async)" [routerLink]="['/'] | localize">{{'navbar.logout' | translate}}</button>
    <p class="tx-copyright">Copyright © 2023 MVM Dome.</p>
  </mvm-container>
</div>
