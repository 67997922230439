import { createAction, props } from '@ngrx/store';
import {User} from "../../shared/interfaces/auth/user.model";

export const loginSuccess = createAction(
    '[LoginPage] Successful login',
    props<{ user: User }>()
)

export const logoutSuccess = createAction(
    '[AuthState] Logout successful'
)

export const registerSuccess = createAction(
    '[RegisterPage] Successful registration',
    props<{ user: User }>()
)