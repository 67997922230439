import { Component } from '@angular/core';

@Component({
  selector: 'mvm-food-order',
  templateUrl: './food-order.component.html',
  styleUrls: ['./food-order.component.scss']
})
export class FoodOrderComponent {

}
