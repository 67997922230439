import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AngularSvgIconModule} from "angular-svg-icon";
import {TranslateModule} from "@ngx-translate/core";
import {RouterLink} from "@angular/router";
import {LocalizeRouterModule} from "@gilsdav/ngx-translate-router";
import { HelperService } from 'src/app/utils/helper.service';

@Component({
  selector: 'mvm-empty-cart-card',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, TranslateModule, RouterLink, LocalizeRouterModule],
  templateUrl: './empty-cart-card.component.html',
  styleUrls: ['./empty-cart-card.component.scss']
})
export class EmptyCartCardComponent {
  public isWebView: boolean = false 
  
  constructor(
    private _helperService: HelperService,
    ) {
    const webview = this._helperService.isWebView()
    if(webview) {
      this.isWebView = true
    }
  }
}
