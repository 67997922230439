    <div id="details-holder">
        <div id="text-holder">
            <h2>{{'home.details.title' | translate}}</h2>
            <p>{{'home.details.description' | translate}}</p>
        </div>
        <div id="details">
            <ul>
                <li>
                    <svg-icon class="justify-center" name="home-vip-1"></svg-icon>
                    <p>{{'home.details.area' | translate}}</p>
                </li>
                <li>
                    <svg-icon class="justify-center" name="home-vip-2"></svg-icon>
                    <p>{{'home.details.seats' | translate}}</p>
                </li>
                <li>
                    <svg-icon class="justify-center" name="home-vip-3"></svg-icon>
                    <p>{{'home.details.disabled' | translate}}</p>
                </li>
                <li>
                    <svg-icon class="justify-center" name="home-vip-4"></svg-icon>
                    <p>{{'home.details.menu' | translate}}</p>
                </li>
            </ul>
        </div>
    </div>
