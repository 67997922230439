<div class="ticket-selector-card">
  <div class="card-border">
    <img class="border-image" [src]="borderImageSrc" alt="Ticket border">
  </div>
  <div [class]="'card-body ' + type">

    <div class="card-body-upper">
      <img @fadeInOut *ngIf="(state !== 'seat-choosing' || mapType !== 'skybox') && state !== 'parking'" class="cover-image" [src]="coverImageSrc"
          alt="Cover image">

      <div @fadeInOut *ngIf="state !== 'parking' && state !== 'seat-selected'" class="flex justify-between card-body-upper-text">
        <p class="text-title text-dark">{{getTitle()}}</p>
      </div>

      <p @fadeInOut *ngIf="state === 'seat-selected'" class="text-500-14 text-dark">{{parkingSelected ? parkingQuantitySelected : quantity}} {{'db' | translate}} {{ticket.name}} {{parkingSelected ? ('home.ticketSelector.titleParking'  | translate) : ('home.ticketSelector.title' | translate) }}</p>

      <div @fadeInOut *ngIf="state !== 'parking' && state !== 'seat-selected' && type != 'mvm-parking'" class="flex justify-between mt-15px mb-15px">
        <app-chooser-quantity [db]="true" *ngIf="state === 'default' || state === 'selected'"
                              [(quantity)]="quantity" [disabled]="!isTicketTypeAvaible"></app-chooser-quantity>

        <p *ngIf="state==='seat-choosing'" class="text-title text-dark">{{quantity}} {{'db' | translate}}</p>
        <p  class="text-title text-dark">{{ticket.is_available ? changeDivider(quantity * price | number: '1.0-2') + '  Ft' : ''}}</p>
      </div>
    </div>

    <div class="card-body-bottom">
      <button  *ngIf="state==='default'" class="btn-yellow" (click)="scrolling ? undefined : type != 'mvm-parking' ? state = 'selected' :  toggleParking()" [disabled]="!isTicketTypeAvaible">{{!isTicketTypeAvaible ? ('home.ticketSelector.notAvailable' | translate) : ('home.ticketSelector.choose' | translate)}}</button>
      <button  *ngIf="state==='selected'" class="btn-yellow mb-10px" (click)="scrolling ? undefined : chooseSeatsState(false)">{{ type == 'mvm-vip-skybox' ? ('home.ticketSelector.chooseSkybox' | translate)  : ('home.ticketSelector.chooseSeat' | translate) }}
      </button>
      <button  *ngIf="state==='selected'" class="btn-yellow" (click)="scrolling ? undefined : chooseSeatsState(true)">{{'home.ticketSelector.auto' | translate}}</button>

      <div style="overflow: hidden" *ngIf="state==='seat-choosing' && !autoSeatChooser" class="seat-map">
        <mvm-seat-map [eventSlug]="event_slug" [eventType]="ticket.type"></mvm-seat-map>
      </div>

      <!--<p *ngIf="state === 'seat-choosing' && quantity > 5  && mapType !== 'skybox' && type !== TicketTypes.Skybox" (click)="mapType = 'skybox'"
        class="blue-info mt-20px mb-20px">{{'home.ticketSelector.skybox' | translate}}</p>-->

      <div @slideDownFadeInOut *ngIf="state === 'parking'" class="flex flex-col gap-25px items-center w-full">
        <svg-icon name="parking-ticket"></svg-icon>
        <p class="text-dark text-parking-title">{{'home.ticketSelector.parking' | translate}}</p>
        <app-chooser-parking [ticket]="getTicketType" class="w-full" (parkingChange)="changeSelectedParkingTicket($event.type, $event.quantity)"></app-chooser-parking>
      </div>

      <div *ngIf="state==='seat-selected' || state === 'seat-choosing' || state === 'parking'"
          class="flex flex-col items-center mt-15px mb-15px">
        <button class="btn-text-yellow mb-10px"
                (click)="scrolling ? undefined : type != 'mvm-parking' ? toggleParking() : state = 'default'">{{state === 'parking' ? ('home.ticketSelector.parkingBack' | translate) : ('home.ticketSelector.parkingBuy' | translate)}}</button>
        <a class="btn-yellow mb-10px" [attr.aria-label]="'save' | translate" (click)="scrolling ? undefined : addParkingTicketToCart()" [routerLink]="state === 'parking' ? undefined : ['/cart'] | localize">{{state === 'parking' ? ('save' | translate) : ('home.ticketSelector.next' | translate)}}</a>
        <button class="btn-text-yellow" *ngIf="state !== 'parking'"
                (click)="scrolling ? undefined : chooseSeatsState(!autoSeatChooser)">{{autoSeatChooser ? ('home.ticketSelector.manualChoose' | translate) : ('home.ticketSelector.autoChoose' | translate)}}</button>
      </div>

      <mvm-dropdown-ticketinfo [label]="ticket.description.label" [text]="ticket.description.text" class="mt-10px" *ngIf="state === 'default'"></mvm-dropdown-ticketinfo>

      <mvm-dropdown-ticketinfo class="mt-10px"
                              *ngIf="state === 'seat-choosing' && type !== TicketTypes.Skybox && quantity > 5"
        ></mvm-dropdown-ticketinfo>
    </div>

  </div>
</div>
