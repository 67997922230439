export const countries = {
    "hu" : [
        "Afganisztán",
        "Albánia",
        "Algéria",
        "Amerikai Egyesült Államok",
        "Andorra",
        "Angola",
        "Antigua és Barbuda",
        "Arab Emírségek",
        "Afganisztán",
        "Afganisztán",
        "Argentína",
        "Ausztrália",
        "Ausztria",
        "Azerbajdzsán",
        "Bahama-szigetek",
        "Bahrein",
        "Banglades",
        "Barbados",
        "Belgium",
        "Belize",
        "Benin",
        "Bhután",
        "Bissau-Guinea",
        "Bolívia",
        "Bosznia-Hercegovina",
        "Botswana",
        "Brazília",
        "Brunei",
        "Bulgária",
        "Burkina Faso",
        "Burundi",
        "Chile",
        "Ciprus",
        "Comore-szigetek",
        "Costa Rica",
        "Csád",
        "Csehország",
        "Dánia",
        "Dél-afrikai Köztársaság",
        "Dél-Korea",
        "Dominikai Közösség",
        "Dominikai Köztársaság",
        "Dzsibuti",
        "Ecuador",
        "Egyenlítői-Guinea",
        "Egyiptom",
        "Elefántcsontpart",
        "Eritrea",
        "Észtország",
        "Etiópia",
        "Fehéroroszország",
        "Fidzsi-szigetek",
        "Finnország",
        "Franciaország (európai része)",
        "Fülöp-szigetek",
        "Gabon",
        "Gambia",
        "Ghána",
        "Görögország",
        "Grenada",
        "Grúzia",
        "Guatemala",
        "Guinea",
        "Guyana",
        "Haiti",
        "Hollandia",
        "Honduras",
        "Horvátország",
        "India",
        "Indonézia",
        "Irak",
        "Irán",
        "Írország",
        "Izland",
        "Izrael",
        "Jamaica",
        "Japán",
        "Jemen",
        "Jordánia",
        "Kambodzsa",
        "Kamerun",
        "Kanada",
        "Katar",
        "Kazahsztán",
        "Kelet-Timor",
        "Kenya",
        "Kína",
        "Kirgizisztán",
        "Kiribati",
        "Kolumbia",
        "Kongói Demokratikus Köztársaság",
        "Kongói Köztársaság",
        "Koreai Népi Demokratikus Köztársaság",
        "Közép-afrikai Köztársaság",
        "Kuba",
        "Kuvait",
        "Laosz",
        "Lengyelország",
        "Lesotho",
        "Lettország",
        "Libanon",
        "Libéria",
        "Líbia",
        "Liechtenstein",
        "Litvánia",
        "Luxemburg",
        "Macedónia",
        "Madagaszkár",
        "Magyarország",
        "Malajzia",
        "Malawi",
        "Maldív-szigetek",
        "Mali",
        "Málta",
        "Marokkó",
        "Marshall-szigetek",
        "Mauritánia",
        "Mauritius",
        "Mexikó",
        "Mianmar",
        "Mikronéziai Szövetségi Államok",
        "Moldova",
        "Monaco",
        "Mongólia",
        "Mozambik",
        "Nagy-Britannia",
        "Namíbia",
        "Nauru",
        "Németország",
        "Nepál",
        "Nicaragua",
        "Niger",
        "Nigéria",
        "Norvégia",
        "Olaszország",
        "Omán",
        "Oroszország",
        "Örményország",
        "Pakisztán",
        "Palau",
        "Panama",
        "Pápua Új-Guinea",
        "Paraguay",
        "Peru",
        "Portugália",
        "Románia",
        "Ruanda",
        "Saint Kitts és Nevis",
        "Saint Lucia",
        "Saint Vincent",
        "Salamon-szigetek",
        "Salvador",
        "San Marino",
        "São Tomé és Príncipe",
        "Seychelle-szigetek",
        "Sierra Leone",
        "Spanyolország",
        "Srí Lanka",
        "Suriname",
        "Svájc",
        "Svédország",
        "Szamoa",
        "Szaúd-Arábia",
        "Szenegál",
        "Szerbia és Montenegró",
        "Szingapúr",
        "Szíria",
        "Szlovákia",
        "Szlovénia",
        "Szomália",
        "Szudán",
        "Szváziföld",
        "Tádzsikisztán",
        "Tajvan",
        "Tanzánia",
        "Thaiföld",
        "Togo",
        "Tonga",
        "Törökország",
        "Trinidad és Tobago",
        "Tunézia",
        "Tuvalu",
        "Türkmenisztán",
        "Uganda",
        "Új-Zéland",
        "Ukrajna",
        "Uruguay",
        "Üzbegisztán",
        "Vanuatu",
        "Vatikán",
        "Venezuela",
        "Vietnam",
        "Zambia",
        "Zimbabwe",
        "Zöld-foki-szigetek"
    ],
    "en" : [
        "Afghanistan",
        "Albania",
        "Algeria",
        "United States of America",
        "Andorra",
        "Angola",
        "Antigua and Barbuda",
        "United Arab Emirates",
        "Argentina",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belgium",
        "Belize",
        "Benin",
        "Bhutan",
        "Guinea-Bissau",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Chile",
        "Cyprus",
        "Comoros Islands",
        "Costa Rica",
        "Chad",
        "Czech Republic",
        "Denmark",
        "Republic of South Africa",
        "South Korea",
        "Dominican Community",
        "Dominican Republic",
        "Djibouti",
        "Ecuador",
        "Equatorial Guinea",
        "Egypt",
        "Côte d'Ivoire",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Belarus",
        "Fiji",
        "Finland",
        "France (European part)",
        "Philippines",
        "Gabon",
        "Gambia",
        "Ghana",
        "Greece",
        "Grenada",
        "Georgia",
        "Guatemala",
        "Guinea",
        "Guyana",
        "Haiti",
        "Netherlands",
        "Honduras",
        "Croatia",
        "India",
        "Indonesia",
        "Iraq",
        "Iran",
        "Ireland",
        "Iceland",
        "Israel",
        "Jamaica",
        "Japan",
        "Yemen",
        "Jordan",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Qatar",
        "Kazakhstan",
        "East Timor",
        "Kenya",
        "China",
        "Kyrgyzstan",
        "Kiribati",
        "Colombia",
        "Democratic Republic of the Congo",
        "Republic of Congo",
        "Democratic People's Republic of Korea",
        "Central African Republic",
        "Cuba",
        "Laos",
        "Poland",
        "Lesotho",
        "Latvia",
        "Lebanon",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macedonia",
        "Madagascar",
        "Hungary",
        "Malaysia",
        "Malawi",
        "Maldives",
        "Mali",
        "Malta",
        "Morocco",
        "Marshall Islands",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Myanmar",
        "Federated States of Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Mozambique",
        "United Kingdom",
        "Namibia",
        "Nauru",
        "Germany",
        "Nepal",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Norway",
        "Italy",
        "Oman",
        "Russia",
        "Armenia",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Portugal",
        "Romania",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent",
        "Solomon Islands",
        "Salvador",
        "San Marino",
        "São Tomé and Príncipe",
        "Seychelles",
        "Sierra Leone",
        "Spain",
        "Sri Lanka",
        "Suriname",
        "Switzerland",
        "Sweden",
        "Samoa",
        "Saudi Arabia",
        "Senegal",
        "Serbia and Montenegro",
        "Singapore",
        "Syria",
        "Slovakia",
        "Slovenia",
        "Somalia",
        "Sudan",
        "Swaziland",
        "Tajikistan",
        "Taiwan",
        "Tanzania",
        "Thailand"
    ],
}