import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MobileMenuService {

  constructor() { }
  private open: BehaviorSubject<boolean> = new BehaviorSubject(false)

  closeMenu() {
    this.open.next(false)
  }

  openMenu() {
    this.open.next(true)
  }

  toggleMenu() {
    this.open.next(!this.open.value)
  }

  getObservable(): Observable<boolean> {
    return this.open.asObservable()
  }

}
