import {Advert} from "../../shared/interfaces/home/advert.model";
import {Program} from "../../shared/model/program.model";
export interface HomeState {
    advert: Advert | null,
    events: Program[],
    cover_image: string | null,
}

export const initialHomeState: HomeState = {
    advert: null,
    cover_image: null,
    events: [],
}