import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class CartIdInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const cartId = localStorage.getItem('cart_id')

    let request = cartId ? httpRequest.clone({
      setHeaders: {
        "Cart": cartId
      }
    }) : httpRequest

    return next.handle(request);
  }
}
