<mvm-core class="edit-profile">
    <section class="header">
        <h1 class="text-super-h1 text-center text-white">{{'profile.title' | translate}}</h1>
    </section>
    <section class="profile-form">
        <mvm-container [type]="ContainerTypes.DefaultForm">
            <a [routerLink]="['/dashboard'] | localize" class="btn-text-yellow back-to-dashboard" [attr.aria-label]="'profile.back' | translate">{{'profile.back' | translate}}</a>

            <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                <h2 class="text-title">{{'profile.title' | translate}}</h2>
                <div class="personal-details flex flex-col">
                    <input [(ngModel)]="profileData.firstName" type="text" placeholder="{{'checkoutBilling.firstname' | translate}}" class="input-text" formControlName="lastName">
                    <mvm-error *ngIf="getError('lastName', 'required')" classes="mb-5" [show]="getError('lastName', 'required')" [text]="'profile.validation.lastName.required' | translate"></mvm-error>

                    <input type="text" [(ngModel)]="profileData.lastName" placeholder="{{'checkoutBilling.lastname' | translate}}" class="input-text" formControlName="firstName">
                    <mvm-error *ngIf="getError('firstName', 'required')" classes="mb-5" [show]="getError('firstName', 'required')" [text]="'profile.validation.firstName.required' | translate"></mvm-error>

                    <input type="text" [(ngModel)]="profileData.phone" placeholder="{{'checkoutBilling.phone' | translate}}" class="input-text" formControlName="phone">
                    <mvm-error *ngIf="getError('phone', 'required')" classes="mb-5" [show]="getError('phone', 'required')" [text]="'profile.validation.phone.required' | translate"></mvm-error>

                    <input type="email" [(ngModel)]="profileData.email" placeholder="{{'checkoutBilling.email' | translate}}" class="input-text" formControlName="email">
                    <mvm-error *ngIf="getError('email', 'required')" classes="mb-5" [show]="getError('email', 'required')" [text]="'profile.validation.email.required' | translate"></mvm-error>
                    <mvm-error *ngIf="getError('email', 'email')" classes="mb-5" [show]="getError('email', 'email')" [text]="'profile.validation.email.email' | translate"></mvm-error>
                </div>
                <div class="personal-address flex flex-col">
                    <mvm-dropdown-chooser (countrySelected)="setCountry($event)" [name]="profileForm.get('personalCountry')?.value ? profileForm.get('personalCountry')?.value : (profileData.personalCountry ? profileData.personalCountry : 'Ország')" [options]="countries"></mvm-dropdown-chooser>
                    <input type="text" [(ngModel)]="profileData.personalPostalCode" placeholder="*{{'checkoutBilling.zipcode' | translate}}" class="input-text" formControlName="personalPostalCode">
                    <mvm-error *ngIf="getError('personalPostalCode', 'required')" classes="mb-5" [show]="getError('personalPostalCode', 'required')" [text]="'profile.validation.personalPostalCode.required' | translate"></mvm-error>

                    <input type="text" [(ngModel)]="profileData.personalCity" placeholder="*{{'checkoutBilling.city' | translate}}" class="input-text" formControlName="personalCity">
                    <mvm-error *ngIf="getError('personalPostalCode', 'required')" classes="mb-5" [show]="getError('personalPostalCode', 'required')" [text]="'profile.validation.personalPostalCode.required' | translate"></mvm-error>

                    <input type="text" [(ngModel)]="profileData.personalAddressLine" placeholder="*{{'checkoutBilling.address' | translate}}" class="input-text" formControlName="personalAddressLine">
                    <mvm-error *ngIf="getError('personalPostalCode', 'required')" classes="mb-5" [show]="getError('personalPostalCode', 'required')" [text]="'profile.validation.personalPostalCode.required' | translate"></mvm-error>
                </div>

                <h2 class="text-title">{{'profile.billing' | translate}}</h2>
                <div class="billing-details flex flex-col">
                    <input [(ngModel)]="profileData.billingName" type="text" placeholder="Számlázási Név" class="input-text" formControlName="billingName">
                    <mvm-dropdown-chooser (countrySelected)="setInvoiceCountry($event)" [name]="profileForm.get('billingCountry')?.value ? profileForm.get('billingCountry')?.value : (profileData.billingCountry ? profileData.billingCountry :  'Ország')"  [options]="countries"></mvm-dropdown-chooser>
                    <input type="text" [(ngModel)]="profileData.billingPostalCode" placeholder="{{'checkoutBilling.zipcode' | translate}}" class="input-text" formControlName="billingPostalCode">
                    <input type="text" [(ngModel)]="profileData.billingCity" placeholder="{{'checkoutBilling.city' | translate}}" class="input-text" formControlName="billingCity">
                    <input type="text" [(ngModel)]="profileData.billingAddressLine" placeholder="{{'checkoutBilling.address' | translate}}" class="input-text" formControlName="billingAddressLine">
                </div>
                <h2 class="text-title">{{'profile.password' | translate}}</h2>
                <div class="password-fields flex flex-col">
                    <input type="password" autocomplete="new-password" placeholder="*{{'profile.currentPassword' | translate}}" class="input-text" formControlName="currentPassword">
                    <mvm-error *ngIf="getError('currentPassword', 'required')" classes="mb-5" [show]="getError('currentPassword', 'required')" [text]="'profile.validation.currentPassword.required' | translate"></mvm-error>

                    <input type="password"  autocomplete="new-password" placeholder="{{'profile.newPass' | translate}}" class="input-text" formControlName="newPassword">
                    <input type="password"  autocomplete="new-password" placeholder="{{'profile.newPassAgain' | translate}}" class="input-text" formControlName="newPasswordAgain">
                </div>
                <button type="submit" class="btn-yellow w-full">{{'save' | translate}}</button>
            </form>
        </mvm-container>
    </section>
</mvm-core>
