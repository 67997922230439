import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, take} from 'rxjs';
import {Store} from "@ngrx/store";
import {selectIsLoggedIn} from "../../store/selectors/auth.selector";
import {LocalizeRouterService} from "@gilsdav/ngx-translate-router";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  public loggedIn!: boolean

  constructor(
      _store: Store,
      private _router: Router,
      private _localize: LocalizeRouterService

  ) {
    _store.select(selectIsLoggedIn).subscribe((loggedIn) => this.loggedIn = loggedIn)
  }

  readonly mustBeLoggedIn = [
    this._localize.translateRoute('/dashboard'),
    this._localize.translateRoute('/checkout-cart'),
    this._localize.translateRoute('/checkout-billing-payment'),
    this._localize.translateRoute('/checkout-done')
  ]

  readonly mustBeLoggedOut= [
    this._localize.translateRoute('/login'),
    this._localize.translateRoute('/register'),
    this._localize.translateRoute('/reset-password'),
    this._localize.translateRoute('/forgot-password'),
    this._localize.translateRoute('/activate')
  ]

  readonly redirections: {[key: string]: string}  = {
   '/checkout-cart' : '/checkout-cart',
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.loggedIn ? this.redirectDashboard(state.url) : this.redirectLogin(state.url)
  } 

  private redirectLogin(url: string) {
    const redirectUrl = this.changeRedirectionUrl(url )
    return this.mustBeLoggedIn.indexOf(url) === -1 ? true : this._router.navigate([this._localize.translateRoute('/login')], { queryParams: { ref: encodeURIComponent(redirectUrl) } })
  }

  private redirectDashboard(url: string) {
    return this.mustBeLoggedOut.indexOf(url) === -1 ? true : this._router.navigate([this._localize.translateRoute('/dashboard')])
  }

  private changeRedirectionUrl(url: string): string {
    /* find matching redirections */
    const result = Object.keys(this.redirections).filter((index) => {
      return this._localize.translateRoute(index) == url
    })
    /* if no result found */
    if(!result.length) return url

    const redirectUrl = this._localize.translateRoute(this.redirections[result[0]])

    return redirectUrl as string
  }
}
