import {createFeatureSelector, createSelector} from "@ngrx/store";
import {CartState} from "../states/cart.state";

const cartFeatureKey = 'cart';

export const selectCart = createFeatureSelector<CartState>(cartFeatureKey)

export const selectCartCart = createSelector(
    selectCart,
    (state: CartState) => state.cart
)
