import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterLink} from '@angular/router';
import {TranslateModule} from "@ngx-translate/core";
import {Event} from "../../../interfaces/events/events.types";
import {LocalizeRouterModule} from "@gilsdav/ngx-translate-router";
import {ScrollingCardService} from "../../../../core/services/scrolling/scrolling-card.service";

@Component({
  selector: 'mvm-card-program',
  standalone: true,
  imports: [CommonModule, RouterLink, TranslateModule, LocalizeRouterModule],
  templateUrl: './card-program.component.html',
  styleUrls: ['./card-program.component.scss']
})
export class CardProgramComponent implements OnInit {

  constructor(private homeService: ScrollingCardService) {
  }

  @Input() program: Event = {} as Event

  @Input("darkFood") darkFood: boolean = false
  @Input() scrollable?: boolean = false
  scrolling: boolean = false

  ngOnInit(): void {
    if (this.scrollable) {
      this.homeService.scrolling.subscribe(scrolling => {
        setTimeout(() => {
          this.scrolling = scrolling
        }, 200);
      });
    }
  }
}
