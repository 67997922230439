<div id="home-vip-holder" class="flex only-mobile from-tablet">
    <mvm-container [type]="ContainerTypes.HomeVip">
        <div id="home-vip-image-holder">
            <img src="/assets/images/jpg/home-vip.jpg" alt="MVM Dome VIP" />
        </div>
    </mvm-container>
    <mvm-container>
        <div id="home-vip-text-holder">
            <div>
                <h2>{{'home.vip.title' | translate}}</h2>
                <p class="btn-text-yellow">{{'home.vip.desc1' | translate}}</p>
                <p>{{'home.vip.desc2' | translate}}</p>
                <a class="btn-yellow" [attr.aria-label]="'home.vip.details' | translate" [routerLink]="['/vip'] | localize">{{'home.vip.details' | translate}}</a>
            </div>
        </div>
    </mvm-container>
</div>

<mvm-container [type]="ContainerTypes.HomeVipC">
    <div id="home-vip-holder" class="flex only-desktop-flex from-tablet">
        <div id="home-vip-image-holder">
            <img src="/assets/images/jpg/home-vip.jpg" alt="MVM Dome VIP" />
        </div>

        <div id="home-vip-text-holder">
            <div>
                <h2>{{'home.vip.title' | translate}}</h2>
                <p class="btn-text-yellow">{{'home.vip.desc1' | translate}}</p>
                <p>{{'home.vip.desc2' | translate}}</p>
                <a class="btn-yellow" [attr.aria-label]="'home.vip.details' | translate" [routerLink]="['/vip'] | localize">{{'home.vip.details' | translate}}</a>
            </div>
        </div>
    </div>

    <div id="home-vip-3">
        <img src="/assets/images/png/vip_gold_6.png" alt="MMV Dome Vip" />
        <img src="/assets/images/png/vip_gold_2.png" alt="MMV Dome Vip" />
        <img src="/assets/images/png/vip_gold_1.png" alt="MMV Dome Vip" />
    </div>
</mvm-container>
