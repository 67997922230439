<div class="card-mail">
  <div class="flex flex-col gap-12px">
    <div class="flex flex-col gap-10px items-center">
      <svg-icon name="mail"></svg-icon>
      <p class="text-parking-title">{{'cardSendTicket.title' | translate}}</p>
    </div>
    <p class="text-400-10 text-center">{{'cardSendTicket.data' | translate}}</p>
    <div class="flex flex-col gap-8px">
      <form [formGroup]="ticketForm" (ngSubmit)="onSubmit()">
        <input formControlName="email" class="input-text mb-8" placeholder="{{'email' | translate}}">
        <input formControlName="name" class="input-text" placeholder="{{'checkoutBilling.firstname' | translate}}">
        <button type="submit" [disabled]="!ticketForm.valid" class="btn-yellow w-full mt-20px">{{'send' | translate}}</button>
      </form>
    </div>
  </div>
</div>
