<mvm-auth-layout>
<div class="container">
  <div class="forgot-password content">
    <div class="logo-container flex justify-center">
      <svg-icon class="mvm" [applyClass]="true" [routerLink]="['/'] | localize" name="mvm"></svg-icon>
    </div>
    <div class="animated-content">
      <h1 class="text-super-h1">{{'forgot.title' | translate}}</h1>
      <p class="text-default text-white text-center">{{'forgot.desc' | translate}}</p>
      <form [formGroup]="forgotPasswordForm" (ngSubmit)="!isRequesting ? onSubmit() : null">
        <input type="email" class="input-text dark" placeholder="{{'forgot.placeholderEmail' | translate}}" formControlName="emailAddress">
        <mvm-error *ngIf="getError('emailAddress', 'required')" [show]="getError('emailAddress', 'required')" [text]="'forgot.validation.emailAddress.required' | translate"></mvm-error>
        <mvm-error *ngIf="getError('emailAddress', 'email')" [show]="getError('emailAddress', 'email')" [text]="'forgot.validation.emailAddress.email' | translate"></mvm-error>

        <mvm-button-save
                    [disabled]="done"
                    [saving]="isRequesting"
                    defaultText="{{'forgot.send' | translate}}"
                    savedText="{{'forgot.sent' | translate}}"
                    [saved]="done"
            ></mvm-button-save>
      </form>
      <div class="login-wrapper flex justify-center">
        <button class="btn-text-yellow" [routerLink]="['/login'] | localize" mvmBackButton>{{'back' | translate}}</button>
      </div>
    </div>
    <p class="text-white text-center copyright">Copyright © 2023 MVM Dome.</p>
  </div>
</div>
</mvm-auth-layout>
