import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DashboardEvent } from 'src/app/shared/interfaces/dashboard/dashboard.types';
import { BuyParkingModalService } from 'src/app/shared/services/buyParkingTicketModalService/buy-parking-modal.service';
import { SendTicketModalService } from 'src/app/shared/services/sendTicketService/send-ticket-modal.service';
import { SingleTicketTypeComponent } from './_partials/single-ticket-type/single-ticket-type.component';
import { ParkingTicketComponent } from './_partials/parking-ticket/parking-ticket.component';
import { growInOut } from 'src/app/shared/animations/growInOut';

@Component({
  selector: 'mvm-single-event',
  standalone: true,
  animations: growInOut,
  imports: [CommonModule, AngularSvgIconModule, TranslateModule, SingleTicketTypeComponent, ParkingTicketComponent],
  templateUrl: './single-event.component.html',
  styleUrls: ['./single-event.component.scss']
})

export class SingleEventComponent {
  @Input() event: DashboardEvent = {} as DashboardEvent
  public isOpen: boolean = false

  getEventTicketNumber(event: DashboardEvent): number {
    let n = event?.parking_tickets?.length ?? 0
    event.tickets.forEach(t => {
      n += t.number_of_tickets
    })
    return n
  }
  get hasParkingTickets() {
    return this.event?.parking_tickets?.length ? true : false
  }


}
