import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AngularSvgIconModule} from "angular-svg-icon";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'mvm-vip-message',
  standalone: true,
    imports: [CommonModule, AngularSvgIconModule, TranslateModule],
  templateUrl: './vip-message.component.html',
  styleUrls: ['./vip-message.component.scss']
})
export class VipMessageComponent {

  @Input() message: string = "Michael Bublé, a többszörös Grammy-, és Juno-díjas művész küldetést vállalt, amikor majdnem két évtizede aláírta lemezszerződését a Reprise Records-szal."

  state: 'default' | 'open' | 'close' = 'open'

  stateToggle() {
    this.state = this.state === 'default' ? 'open' : 'default';
  }
}
