import {DashboardEvent, Invoice} from "../../shared/interfaces/dashboard/dashboard.types";
export interface DashboardState {
  vip_message: string,
  events: DashboardEvent[],
  invoices: Invoice[],
  show_vip_functions: boolean,
}

export const initialDashboardState: DashboardState = {
  vip_message: "",
  events: [],
  invoices: [],
  show_vip_functions: true,
}
