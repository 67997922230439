import { Injectable } from '@angular/core';
import { VipContactBody } from 'src/app/shared/interfaces/api-services/vip-contant/vip-contact-body';
import { HandleErrorAndSuccess } from 'src/app/shared/interfaces/common/handle-error-and-success';
import { ApiRequestService } from '../apiRequestService/api-request.service';

@Injectable({
  providedIn: 'root'
})
export class VipContactApiService {

  constructor(
    private _apiRequestService: ApiRequestService,
  ) { }

  public contact(vipContactBody: VipContactBody, self: HandleErrorAndSuccess ) {

    this._apiRequestService.putRequest('partner-contact', 
    vipContactBody, 
      (res) => { self.handleSuccessResponse() },  
      (err) => { self.handleErrorResponse(err) },
      true
    )
  } 
}
