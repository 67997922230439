import { Injectable } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Body as ForgotBodyInterface } from 'src/app/shared/interfaces/api-services/auth/forgot-password/body';
import { HandleErrorAndSuccess } from 'src/app/shared/interfaces/common/handle-error-and-success';
import { ApiRequestService } from '../apiRequestService/api-request.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordApiService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private localize: LocalizeRouterService
  ) { }

  public forgotPassword(forgotPasswordForm: ForgotBodyInterface, self: HandleErrorAndSuccess ) {
    /* temporary sending url for redirect */
    let translatedPath: any = this.localize.translateRoute('/reset-password');
    forgotPasswordForm.url = environment.domain + translatedPath

    this._apiRequestService.postRequest('forgottenpassword', 
    forgotPasswordForm, 
      (res) => { self.handleSuccessResponse(res) },  
      (err) => { self.handleErrorResponse(err) },
      true
    )
  } 
}
