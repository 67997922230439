import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AngularSvgIconModule} from "angular-svg-icon";
import {TranslateModule} from "@ngx-translate/core";
import {SendTicketBody, SendTicketService} from "../../../services/apiServices/sendTicketService/send-ticket.service";
import {SendTicketModalService} from "../../../services/sendTicketService/send-ticket-modal.service";

@Component({
  selector: 'mvm-card-send-ticket',
  standalone: true,
    imports: [CommonModule, ReactiveFormsModule, AngularSvgIconModule, TranslateModule],
  templateUrl: './card-send-ticket.component.html',
  styleUrls: ['./card-send-ticket.component.scss']
})
export class CardSendTicketComponent {

  public ticketForm!: FormGroup
  @Input() ticketId: string = ""
  @Input() ticketType: "parking" | "simple" = "simple"

  constructor(private fb: FormBuilder, private sendTicketService: SendTicketService, private modalS: SendTicketModalService) {
    this.ticketForm = this.fb.group({
      email: ['', [
        Validators.required, Validators.email
      ]],
      name: ['', Validators.required]
    })
  }

  public onSubmit(): void {
    if(this.ticketId !== "") {
      const ticketData: SendTicketBody = {emailAddress: this.ticketForm.value.email, firstName: this.ticketForm.value.name, id: this.ticketId}
      if(this.ticketType === "simple"){
        this.sendTicketService.sendSimpleTicket(ticketData)
      } else {
        this.sendTicketService.sendParkingTicket(ticketData)
      }
      this.ticketForm.reset()
      this.modalS.close()
    }
  }
}
