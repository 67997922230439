import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {AngularSvgIconModule} from "angular-svg-icon";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'mvm-grey-frame',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, TranslateModule],
  templateUrl: './grey-frame.component.html',
  styleUrls: ['./grey-frame.component.scss']
})
export class GreyFrameComponent {
  @Input() type: 'foods' | 'tickets' | 'bills' = 'tickets'
}
