<mvm-cart-layout>
    <mvm-container [type]="ContainerTypes.Cart">
        <div class="mobile-title-row flex justify-between items-center">
            <h1 class="text-h1 text-white">{{'cart.title' | translate}}</h1>
            <svg-icon mvmBackButton [stretch]="true" class="close-icon" name="close-white-big" [applyClass]="true"></svg-icon>
        </div>
<!--
      <p class="text-white text-center items-center text-super-h1 empty" *ngIf="(cart$ | async)?.events?.length === 0 || !(cart$ | async)?.events">{{'emptyCart' | translate}}</p>
-->
      <div *ngIf="(cart$ | async)?.events?.length === 0 || !(cart$ | async)?.events" class="empty-cart-card">
        <mvm-empty-cart-card></mvm-empty-cart-card>
      </div>
      <div class="cart-wrapper flex justify-between" *ngIf="(cart$ | async)?.events!.length > 0">
          <div class="cart-items">
              <ng-container *ngFor="let event of (cart$ | async)?.events">
                <mvm-cart-item-card [cartEvent]="event" [cartTicket]="ticket" [title]="event.name" *ngFor="let ticket of event.tickets" [edit]="false"></mvm-cart-item-card>
                <mvm-cart-item-card [type]="cartItemTypes().PARKINGTICKET" [cartEvent]="event" [cartTicket]="parkingTicket"  *ngFor="let parkingTicket of event.parking_tickets"  [title]="event.name" [edit]="false"></mvm-cart-item-card>
              </ng-container>
            </div>
          <div class="summary-wrapper">
                <div class="summary">
                    <div class="price-summary">
                        <p class="text-default text-white">{{'cart.sum' | translate}}</p>
                        <h3 class="text-title text-white">{{(cart$ | async)?.sum_price}}</h3>
                    </div>
                    <button [routerLink]="['/checkout-cart'] | localize" class="btn-yellow">{{'cart.next' | translate}}</button>
                </div>
            </div>
        </div>
    </mvm-container>
    <mvm-container *ngIf="(cart$ | async)?.events!.length > 0" [type]="ContainerTypes.Full">
        <div class="summary-wrapper">
            <div class="summary">
                <div class="price-summary">
                    <p class="text-default text-white">{{'cart.sum' | translate}}</p>
                    <h3 class="text-title text-white">{{(cart$ | async)?.sum_price}}</h3>
                </div>
                <button [routerLink]="['/checkout-cart'] | localize" class="btn-yellow">{{'cart.next' | translate}}</button>
            </div>
        </div>
    </mvm-container>
</mvm-cart-layout>
