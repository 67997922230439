import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

export enum ContainerTypes {
  Default = 'default',
  Full = 'full',
  HowToApproach = 'how-to-approach',
  InteractiveMapControls = 'interactive-map-controls',
  ProgramDetails = 'program-details',
  ProgramDetailsTicketSelector = 'program-details-ticket-selector',
  Partners = 'partners',
  Navbar = 'navbar',
  Footer = 'footer',
  Searchbar = 'search-bar',
  HomeVipDetails = 'home-vip-details',
  MobileFullDesktopNarrow = 'mobile-full-desktop-narrow',
  MobileDefaultDesktopExtraNarrow = 'mobile-default-desktop-extra-narrow',
  Cart = 'cart',
  HomeVip = 'home-vip',
  DefaultForm = 'default-form',
  MobileNavMenu = "mobile-nav-menu",
  HomeVipC = 'home-vip-container',
}

@Component({
  selector: 'mvm-container',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './container.component.html',
  styleUrls: [
      './container.mobile.component.scss',
      './container.tablet-portrait.component.scss',
      './container.tablet.component.scss',
      './container.desktop.component.scss',
      './container.large-desktop.component.scss'
  ]
})
export class ContainerComponent {
  @Input() type: ContainerTypes = ContainerTypes.Default
}
