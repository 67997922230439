<div class="parking">

  <div *ngFor="let p of parkingTypes" class="parkingType">

    <div class="flex gap-15px">
      <input class="radio-bw" type="radio" [value]="p.name+ticket" [checked]="selectedValue === p.name+ticket" [ngModel]="selectedValue" (ngModelChange)="onchange(p.name)"/>
      <div (click)="onchange(p.name)">
        <p class="tx-park-name">{{p.name}} {{'parkingName' | translate}}</p>
        <p class="tx-park-desc">{{p.desc}} {{'parkingDist' | translate}}</p>
      </div>
    </div>

    <app-chooser-quantity [quantity]="p.quantity" (quantityChange)="emit($event, p.name)"></app-chooser-quantity>


  </div>

</div>
