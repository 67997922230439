import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InteractiveMapComponent } from './components/interactive-map/interactive-map.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BorderedCheckboxButtonComponent } from './components/bordered-checkbox-button/bordered-checkbox-button.component';
import {TranslateModule} from "@ngx-translate/core";
import {AngularSvgIconModule} from "angular-svg-icon";



@NgModule({
  declarations: [
    InteractiveMapComponent,
    BorderedCheckboxButtonComponent
  ],
  exports: [
    InteractiveMapComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        AngularSvgIconModule
    ]
})
export class MapsModule { }
