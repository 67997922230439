import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute, Params, Router, RouterLink} from "@angular/router";
import {LocalizeRouterModule, LocalizeRouterService} from "@gilsdav/ngx-translate-router";
import {ProgramTypes} from "../../../enums/program-types.enum";

export enum BorderedButtonColors {
  green = '#008C8D',
  yellow = '#F5B126',
  blue = '#00AEDD'
}

@Component({
  selector: 'mvm-colored-border-button',
  standalone: true,
  imports: [CommonModule, LocalizeRouterModule, RouterLink],
  templateUrl: './colored-border-button.component.html',
  styleUrls: ['./colored-border-button.component.scss']
})
export class ColoredBorderButtonComponent implements OnInit {
  Colors = BorderedButtonColors
  @Input() color: BorderedButtonColors = BorderedButtonColors.green
  @Input() text: string = 'Button'
  @Input() selected: boolean = false
  @Input() type!: ProgramTypes

  private types: string = ""
  public q: string = ""

  constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private localizeRouterService: LocalizeRouterService
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      //this.types = params['type']?.split(',') ?? []
      //this.selected = this.types.includes(this.text.toLowerCase())
      this.types = params['type'] ?? ""
      this.q = params['q']
      this.selected = this.types.toLowerCase() === this.type
    })
  }

  private setParams() {
    let queryParams: Params = {}

    /*if (this.types.length > 0) {
      queryParams = {
        type: this.types.toString()
      }
    }*/

    if(!this.selected) {
      queryParams = {
        type: this.text.toLowerCase()
      }
    }


    this.router.navigate(
      this.localizeRouterService.translateRoute(['/events']) as unknown[],
      {
        queryParams: queryParams,
        queryParamsHandling: ''
      });
  }

  get queryParams(): object {
    let params = {}

    if(this.q) params = { q: this.q }
    if(this.type && !this.selected) params = { type: this.type, ...params }

    return params
  }
}
