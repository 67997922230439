import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AngularSvgIconModule} from "angular-svg-icon";
import {TranslateModule} from "@ngx-translate/core";
import {LocalizeRouterModule} from "@gilsdav/ngx-translate-router";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'mvm-incoming-events',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, TranslateModule, LocalizeRouterModule, RouterLink],
  templateUrl: './incoming-events.component.html',
  styleUrls: ['./incoming-events.component.scss']
})
export class IncomingEventsComponent {

}
