import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'mvm-how-to-approach',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './how-to-approach.component.html',
  styleUrls: ['./how-to-approach.component.scss']
})
export class HowToApproachComponent {

}
