<mvm-checkout-layout title="{{'checkoutCart.title' | translate}}">

  <section class="frame-cart border-bottom">

    <div class="frame-cart-content">
      <p class="text-center items-center text-super-h1" *ngIf="(cart$ | async)?.events?.length! < 1">{{'emptyCart' | translate}}</p>

      <div class="tickets" [ngClass]="{'tickets-odd': odd((cart$ | async)?.events) }">
        <ng-container *ngFor="let event of (cart$ | async)?.events">

          <mvm-cart-item-card [cartEvent]="event" [cartTicket]="ticket" [title]="event.name" *ngFor="let ticket of event.tickets" [backgroundColor]="CartItemBgColors.SolidDark" [edit]="true"></mvm-cart-item-card>
          <mvm-cart-item-card [type]="cartItemTypes().PARKINGTICKET" [cartEvent]="event" [cartTicket]="parkingTicket"  *ngFor="let parkingTicket of event.parking_tickets" [title]="event.name" [backgroundColor]="CartItemBgColors.SolidDark" [edit]="false"></mvm-cart-item-card>
        </ng-container>

        <!--<mvm-cart-item-card [backgroundColor]="CartItemBgColors.SolidDark" [edit]="true"></mvm-cart-item-card>
        <mvm-cart-item-card [backgroundColor]="CartItemBgColors.SolidDark" [edit]="true"></mvm-cart-item-card>-->
      </div>
      <div class="checkout-btn-container w-full">
        <button [routerLink]="['/checkout-billing-payment'] | localize" class="btn-yellow w-full">{{'checkoutCart.continueBuying' | translate}}</button>
      </div>
    </div>

  </section>

</mvm-checkout-layout>
