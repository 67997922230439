import { ParkingTypes } from "src/app/shared/model/parking-types";
import {Program} from "../../shared/model/program.model";

export interface ParkingState {
    events: Program[],
    parkingTypes: ParkingTypes[],
}

export const initialParkingState: ParkingState = {
    events: [],
    parkingTypes: [],
}