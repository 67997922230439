import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavbarComponent} from "../../components/navbar/navbar.component";
import {MobileNavMenuComponent} from "../../../shared/components/mobile-nav-menu/mobile-nav-menu.component";
import {trigger} from "@angular/animations";
import {mobileNavbarFade} from "../../../shared/animations/navbar/mobile-navbar-fade.animation";
import {MobileMenuService} from "../../services/mobile-menu/mobile-menu.service";
import { HelperService } from 'src/app/utils/helper.service';

@Component({
  selector: 'mvm-auth-layout',
  standalone: true,
  imports: [CommonModule, NavbarComponent, MobileNavMenuComponent],
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  animations: [
    trigger('fade', mobileNavbarFade)
  ]
})
export class AuthLayoutComponent {
  public hasFooter: boolean = true
  public hasNavbar: boolean = true

  constructor(
    private _helperService: HelperService,
    private menuService: MobileMenuService,
    ) {
    const webview = this._helperService.isWebView()
    if(webview) {
      this.hasNavbar = false
    }
  }

  public mobileNavOpened = this.menuService.getObservable()

}
