
<mvm-auth-layout>
  <div class="container">
    <div class="login content" *ngIf="!isUserNotActivatedError">
     <svg-icon id="logo" class="mvm" [svgStyle]="{'margin-bottom':'2rem'}" [applyClass]="true" [routerLink]="['/'] | localize" name="mvm"></svg-icon>
      <div class="animated-content">
        <h1 class="text-super-h1">{{'login' | translate}}</h1>
        <mvm-social-login-buttons  type="login" id="login-social"></mvm-social-login-buttons>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <input id="email" type="email" class="input-text dark" placeholder="{{'loginPage.email' | translate}}" formControlName="emailAddress">
          <mvm-error classes="mb-5" [show]="getError('emailAddress', 'required')" [text]="'register.validation.emailAddress.required' | translate"></mvm-error>
          <mvm-error classes="mb-5" [show]="getError('emailAddress', 'email')" [text]="'register.validation.emailAddress.required' | translate"></mvm-error>

          <input id="password" type="password" class="input-text dark" placeholder="{{'loginPage.password' | translate}}" formControlName="password">
          <mvm-error classes="mb-5" [show]="getError('password', 'required')" [text]="'register.validation.password.required' | translate"></mvm-error>

          <button id="submit" type="submit" class="btn-yellow" [disabled]="!loginForm.valid">{{'login' | translate}}</button>
        </form>
        <div class="register-wrapper flex justify-center">
          <p>{{'loginPage.newAccount' | translate}}</p>
          <a class="text-subtitle text-yellow" [routerLink]="['/register'] | localize" [attr.aria-label]="'loginPage.register' | translate">{{'loginPage.register' | translate}}</a>
        </div>
        <div class="forgot-password-wrapper flex justify-center">
          <button type="submit" class="btn-text-yellow" [routerLink]="['/forgot-password'] | localize">{{'loginPage.forgot' | translate}}</button>
        </div>
      </div>
      <p class="text-white text-center copyright">Copyright © 2023 MVM Dome.</p>
    </div>
    <div class="login content" *ngIf="isUserNotActivatedError">
      <svg-icon class="mvm" [applyClass]="true" [routerLink]="['/'] | localize" name="mvm"></svg-icon>
      <div class="animated-content">
        <h1 class="text-super-h1">{{'resend-activation-token' | translate}}</h1>
        <a (click)="isUserNotActivatedError = false" class="text-subtitle text-yellow" id="back-to-login">Bejelentkezés</a>
        <mvm-resend-activation-token [style] = "'light'"></mvm-resend-activation-token>
      </div>
    </div>
  </div>
</mvm-auth-layout>
