<mvm-checkout-layout [title]="title" [done]="true" checkOutStep="4">

  <div class="loader-holder" *ngIf="requesting">
    <mvm-loader/>
  </div>

  <section class="flex justify-center border-bottom">

    <div class="frame-white" *ngIf="needText">
      <p *ngIf="isOrderSuccess" class="text-center text-title">{{'checkoutDone.desc1' |translate}} {{ (order$ | async)?.data?.email }} {{'checkoutDone.desc2' |translate}}</p>

      <div id="order-response-holder" [innerHTML]="(order$ | async)?.data?.response_string">
      </div>

      <a *ngIf="isOrderSuccess" [routerLink]="['/'] | localize" class="btn-yellow" [attr.aria-label]="'checkoutDone.back' | translate">{{'checkoutDone.back' | translate}}</a>

      <a *ngIf="isOrderFailed" class="btn-yellow"  [attr.aria-label]="'checkoutDone.retry' | translate" [routerLink]="['/checkout-billing-payment'] | localize">{{ 'checkoutDone.retry' | translate }}</a>
    </div>

    <div *ngIf="!needText">
      <div id="order-response-holder" class="banktransfer" [innerHTML]="(order$ | async)?.data?.response_string"></div>
    </div>


  </section>

</mvm-checkout-layout>
