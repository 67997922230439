import { createAction, props } from '@ngrx/store';
import {OrderState} from "../states/order.state";

export const orderLoaded = createAction(
    '[OrderState] Order state loaded',
    props<OrderState>()
)

export const orderCleared = createAction(
  '[OrderState] Order state cleared'
)
