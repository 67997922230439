<div class="flex flex-col relative" (click)="$event.stopPropagation()">
  <div class="chooser" (click)="open = !open">
    <p class="text-white">{{selectedLanguage | uppercase}}</p>
    <svg-icon [@rotate]="open" name="triangle-white"></svg-icon>
  </div>
  <div [@openClose]="open" class="dropdown">
    <p (click)="switchLang('hu');" class="lang-option">HU</p>
    <p (click)="switchLang('en');" class="lang-option">EN</p>
  </div>
</div>

