<mvm-auth-layout>
  <div class="container">
    <div class="reset-password content">
      <svg-icon class="logo from-tablet svg-icon" [applyClass]="true" [routerLink]="['/'] | localize" name="mvm"></svg-icon>
      <div class="animated-content">
        <h1 class="text-super-h1">{{'reset.title' | translate}}</h1>
        <p class="text-default text-white text-center">{{'reset.desc' | translate}}</p>
        <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
          <input type="password" class="input-text dark" placeholder="{{'reset.pass' | translate}}" formControlName="password">
          <mvm-error classes="mb-5" [show]="getError('password', 'required')" [text]="'reset.validation.pass.required' | translate"></mvm-error>
          <input type="password" class="input-text dark" placeholder="{{'reset.passAgain' | translate}}" formControlName="passwordConfirmation">
          <mvm-error classes="mb-5" [show]="getError('passwordConfirmation', 'required')" [text]="'reset.validation.passConfirm.required' | translate"></mvm-error>
          <mvm-error classes="mb-5" [show]="getError('passwordConfirmation', 'forbiddenName')" [text]="'reset.validation.passConfirm.notSame' | translate"></mvm-error>

          <div class="flex justify-center w-full">
            <mvm-button-save
                    [disabled]=" done"
                    [saving]="isRequesting"
                    savedText="{{'reset.savedText' | translate}}"
                    [saved]="done"
            ></mvm-button-save>
          </div>
        </form>
        <div @fade *ngIf="done" class="login-wrapper flex justify-center">
          <button class="btn-text-yellow" [routerLink]="['/login'] | localize">{{'login' | translate}}</button>
        </div>
      </div>
      <p class="text-white text-center copyright" [ngClass]="{ 'saved': done }">Copyright © 2023 MVM Dome.</p>
    </div>
  </div>
</mvm-auth-layout>

