import { trigger, transition } from "@angular/animations";
import { loginToRegisterAnimation, registerToLoginAnimation } from "./login-register.animation";
import { fadeAnimatedContent } from "./fade-animated-content";
import { fadeAnimation } from "./fade.animation";
import {slideUpAnimation} from "./slide-up.animation";
import {slideDownAnimation} from "./slide-down.animation";

export const routeAnimaitons =
  trigger('routeAnimations', [
    //transition('home => login', homeToLogin),
    //transition('login => home', slideUpAnimation),
    transition('login => register', loginToRegisterAnimation),
    transition('register => login', registerToLoginAnimation),
    transition('login <=> forgotPassword', fadeAnimatedContent),
    transition('forgotPassword <=> resetPassword', fadeAnimatedContent),
    transition('resetPassword <=> login', fadeAnimatedContent),
    transition('* => ticket-modify', slideDownAnimation),
    transition('ticket-modify => *', slideUpAnimation),
    transition('* => *', fadeAnimation)
  ]);
