import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterLink} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {LocalizeRouterModule} from "@gilsdav/ngx-translate-router";

@Component({
  selector: 'mvm-card-food-ad',
  standalone: true,
    imports: [CommonModule, RouterLink, TranslateModule, LocalizeRouterModule],
  templateUrl: './card-food-ad.component.html',
  styleUrls: ['./card-food-ad.component.scss']
})
export class CardFoodAdComponent {

}
