import {createFeatureSelector, createSelector} from "@ngrx/store";
import {OrderState} from "../states/order.state";

const featureKey = 'order';

export const selectOrder = createFeatureSelector<OrderState>(featureKey)

export const selectOrderStatus = createSelector(
  selectOrder,
    (state: OrderState) => state.order
)
