import {
  EventDetailed,
  EventSimple, MetaData,
  Ticket
} from "../../shared/services/apiServices/detailsPageService/details-page.service";

export interface DetailPageState {
  event: EventDetailed | null,
  meta: MetaData | null
  tickets: Ticket[],
  other_events: EventSimple[]

}

export const initialDetailPageState: DetailPageState = {
  event: null,
  meta: null,
  tickets: [],
  other_events: []
}
