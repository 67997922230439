<mvm-core>

  <div class="impressum-container">
    <p class="text-super-h1">Impresszum</p>
    <section class="card-impressum">
      <div class="flex gap-5px">
        <p class="bold">Cégnév:</p>
        <p class="text-default">{{'impressum.name' | translate}}</p>

      </div>
      <div class="flex gap-5px">
        <p class="bold">Cím:</p>
        <p class="text-default">{{'impressum.address' | translate}}</p>
      </div>
      <div class="flex gap-5px">
        <p class="bold">Adószám:</p>
        <p class="text-default">{{'impressum.tax' | translate}}</p>
      </div>
      <div class="flex gap-5px">
        <p class="bold">Cégjegyzékszám:</p>
        <p class="text-default">{{'impressum.number' | translate}}</p>
      </div>
      <div class="flex gap-5px">
        <p class="bold">E-mail:</p>
        <a href="mailto:mvmdome@sporfive.com" class="text-default" [attr.aria-label]="'mvmdome@sporfive.com'" style="color: black">mvmdome@sporfive.com</a>
      </div>
    </section>
  </div>

</mvm-core>
