<div class="relative flex flex-col card" [ngClass]="darkFood ? 'card-dark' : ''">
  <a class="flex" 
     [routerLink]="scrolling ? undefined : ([darkFood ? '/food-order' : '/event/' + program.slug] | localize)">
    <!--<img style="width: 100%" [src]="program.image['350'].jpg" [alt]="program.title">-->
    <picture>
      <source [srcset]="program.image['600'].jpg" media="(min-width: 400px)" type="image/jpg"/>
      <source [srcset]="program.image['600'].webp" media="(min-width: 400px)" type="image/webp"/>
      <source [srcset]="program.image['350'].jpg" media="(min-width: 360px)" type="image/jpg"/>
      <source [srcset]="program.image['350'].webp" media="(min-width: 360px)" type="image/webp"/>
      <source [srcset]="program.image['300'].webp" type="image/webp"/>

      <img *ngIf="scrollable" style="width: 100%; height: 100%" [src]="program.image['350'].jpg" [alt]="program.title">
      <img *ngIf="!scrollable"  style="width: 100%; height: 100%" [src]="program.image['350'].jpg" [alt]="program.title">
    </picture>
  </a>

  <!--<a [routerLink]="scrolling ? undefined : ([darkFood ? '/food-order' : '/event/' + program.slug] | localize)"
     class="absolute tag-yellow tag-position">{{ !program.is_seasonal ? program.label : ('cardProgram.season_ticket' | translate) }}</a>-->
  <div class="card-detail-wrapper flex flex-col justify-between" [ngClass]="darkFood ? 'dark-bg': 'white-bg'">
    <div class="flex flex-col card-details">
      <p class="tx-details" [ngClass]="darkFood ? 'text-white' : ''">{{program.date}}</p>
      <a [routerLink]="scrolling ? undefined : ([darkFood ? '/food-order' : '/event/' + program.slug] | localize)" [attr.aria-label]="program.title"
         class="tx-title mb-10px break-all" [ngClass]="darkFood ? 'text-white' : ''">{{program.title}}</a>
    </div>
    <div class="flex card-details w-full alt">
      <a class="btn-yellow w-full" [attr.aria-label]="darkFood ? ('cardProgram.checkout' | translate) : ('cardProgram.buy' | translate)"
         [routerLink]="scrolling ? undefined : ([darkFood ? '/food-order' : '/event/' + program.slug] | localize)">{{darkFood ? ('cardProgram.checkout' | translate) : ('cardProgram.buy' | translate)}}</a>
    </div>
  </div>

</div>
