import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthApiService } from '../../services/apiServices/authApiService/auth-api.service';
import { SuccessModalService } from '../../services/SuccessService/success-modal.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ErrorStatusCode } from '../../enums/erros-status-code.enum';
import { ErrorComponent } from '../input/error/error.component';

@Component({
  selector: 'mvm-resend-activation-token',
  templateUrl: './resend-activation-token.component.html',
  styleUrls: ['./resend-activation-token.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, ErrorComponent],
})
export class ResendActivationTokenComponent {
  public resendValidationEmailForm!: FormGroup
  public isRequesting: boolean = false

  public isResendingValidationTokenDone: boolean = false
  public email: string = ""
  @Input() style: "light" | "dark" = "dark"

  constructor(
    private _router: Router,
    private _authApiService: AuthApiService,
    private _successModalService: SuccessModalService,
    private _localize: LocalizeRouterService,
    private _fb: FormBuilder,

  ) {
    this.resendValidationEmailForm = this._fb.group({
      emailAddress: ['', [
        Validators.required, Validators.email
      ]],
    })
  }


  // todo: refactor, its only a prototype
  onSubmit() {
    this.isRequesting = true
    this._markAllInputs()

    if (!this.resendValidationEmailForm.valid) { return; }

    this._authApiService.resendValidationEmail(this.resendValidationEmailForm.get('emailAddress')?.value, this)
  }

  /* handles success validate request */
  public handleSuccessResponse(res: any): void {
    this.isRequesting = false
    this.resendValidationEmailForm.reset()
    this.isResendingValidationTokenDone = true
    this._successModalService.showSuccess('Az aktiváló e-mail újraküldése sikeres!')

  }

  /* handles failed validate request */
  public handleErrorResponse(err: any): void {
    this.isRequesting = false
  }


  /* returns validation errors */
  public getError(attribute: string, validationRule: string) {
    return this.resendValidationEmailForm.get(attribute)?.touched && this.resendValidationEmailForm.get(attribute)?.errors?.[validationRule]
  }

  private _markAllInputs() {
    Object.keys(this.resendValidationEmailForm.controls).map((input) => {
      this.resendValidationEmailForm.get(input)?.markAsTouched()
    })
  }
} 
