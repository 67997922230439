<div class="relative flex flex-col card card-dark w-full">
  <img src="assets/images/png/placehorder-food.png" alt="food">
  <div class="flex flex-col card-details">
    <p class="tx-details text-center mb-10px text-white">{{'cardFoodTitle' | translate}}</p>
    <a class="" href="tel:+3616598596" [attr.aria-label]="'+3616598596'">
      <button class="btn-yellow w-full">+36 1 659 8596</button>
    </a>

  </div>
</div>

