<div @growInOut class="ticket-card" *ngIf="event.parking_tickets?.length">
  <ng-container *ngFor="let parkingTicket of event.parking_tickets">
    <div class="card-border">
      <img class="border-image" src="/assets/images/png/ticket/ticket-border-parking.png" alt="Ticket border">
    </div>
    <div class="card-body top-border-radius">

      <div class="flex justify-between">
        <p class="tag-yellow" *ngIf="event.infos.date">{{event.infos.date}}</p>
        <p class="tag-countdown">{{event.infos.day_left}}</p>
      </div>
      <p class="text-ticket-title text-dark">{{event.name}}</p>
      <div class="flex gap-10px">
        <p class="tag-db">{{parkingTicket.label }}</p>
      </div>
    </div>
    <div class="flex justify-between ticket-details cursor-pointer" (click)="isOpen = !isOpen"
         [ngClass]="isOpen ? '' : 'border-radius-bottom'">
      <p class="text-info-bold">{{'details' | translate}}</p>
      <svg-icon class="transition-3" [ngClass]="isOpen ? 'rotate-180' : ''" name="triangle-black"></svg-icon>
    </div>

    <div @growInOut *ngIf="isOpen" class="ticket-parkingTicket">
      <div class="w-full flex justify-between mb-4px">
        <p class="text-white uppercase text-800-10">{{'myTicket.text8' | translate}}</p>
        <p class="text-white text-400-10">{{'myTicket.text9' | translate}}</p>
      </div>

        <div class="parkingTicket" *ngFor="let pt of parkingTicket.items">
          <div class="flex gap-6px">
            <svg-icon name="pdf-white"></svg-icon>
            <p class="white-link-700">{{pt.name}}</p>
          </div>
          <div class="flex gap-6px">
            <a class="tag-white cursor-pointer" [href]="pt.url" target="_blank">
              <img src="/assets/images/png/download.png" width="10" height="10"/>
            </a>
            <p class="tag-white cursor-pointer" (click)="sendParkingTicket(pt.id)">{{'send' | translate}}</p>
          </div>
        </div>
        <p class="white-link-600 mt-12px cursor-pointer" *ngIf="parkingTicket.items"
          (click)="downloadAllTickets(parkingTicket.download_all)">{{'myTicket.text7' | translate}}</p>
      <button class="btn-dark w-full mt-10px btn-plus"
              (click)="buyParkingTicket(event.slug)">{{'myTicket.text10' | translate}}</button>
    </div>
  </ng-container>
  </div>
