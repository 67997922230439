import {Injectable} from '@angular/core';
import {BehaviorSubject, timeoutWith} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class SuccessModalService {

  constructor(private translate: TranslateService) {
  }
  state$: BehaviorSubject<{ visible: boolean, message: string, warning: boolean }> = new BehaviorSubject<{ visible: boolean, message: string, warning: boolean }>({
    visible: false,
    message: "",
    warning: false
  });

  showSuccessTranslate(stringId: string) {
    if(!stringId.length) return

    this.translate.get(stringId).subscribe(res => {
      this.state$.next({
        visible: true,
        message: res,
        warning: false
      })

      setTimeout(()=>{
        this.close()
      }, 3000)
    })
  }

  showWarningTranslate(stringId: string) {
    if(!stringId.length) return

    this.translate.get(stringId).subscribe(res => {
      this.state$.next({
        visible: true,
        message: res,
        warning: true
      })

      setTimeout(()=>{
        this.close()
      }, 3000)
    })
  }

  showSuccess(successMessage: string) {
    if(!successMessage.length) return

    this.state$.next({
      visible: true,
      message: successMessage,
      warning: false
    })

    setTimeout(()=>{
      this.close()
    }, 3000)
  }

  showWarning(message: string) {
    if(!message.length) return

    this.state$.next({
      visible: true,
      message: message,
      warning: true
    })
    setTimeout(()=>{
      this.close()
    }, 3000)
  }



  close() {
    this.state$.next({visible: false, message: "", warning: false})
  }
}
