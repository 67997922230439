import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CheckboxComponent} from "../input/checkbox/checkbox.component";
import {TranslateModule} from "@ngx-translate/core";
import {PartnerApiService} from '../../services/apiServices/PartnerApiService/partner-api.service';
import {ButtonSaveComponent} from '../buttons/button-save/button-save.component';
import {ErrorComponent} from '../input/error/error.component';
import {HandleErrorAndSuccess} from '../../interfaces/common/handle-error-and-success';
import {SuccessModalService} from "../../services/SuccessService/success-modal.service";
import {LocalizeRouterModule} from "@gilsdav/ngx-translate-router";
import {RouterLink} from "@angular/router";
import {ContactSimpleBody} from "../../interfaces/api-services/contact/body";


@Component({
  selector: 'mvm-partners',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, CheckboxComponent, TranslateModule, ButtonSaveComponent, ErrorComponent, LocalizeRouterModule, RouterLink],
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements HandleErrorAndSuccess {
  public partners: Array<{[key: string] : string}> = [
    { img:'dreher' , url: "https://www.dreherzrt.hu/"},
    { img:'pepsi' , url: "https://pepsi.hu/"},
    { img:'hell', url: "https://www.hellenergy.com/"},
    { img:'chio' , url: "https://www.chio.hu/"},
    { img:'unicum' , url: "https://zwackunicum.hu/hu/eletkor-ellenorzes/"},
  ]

  public becomePartnerForm!: FormGroup
  public isRequesting: boolean = false
  public done: boolean = false

  constructor(
    private _fb: FormBuilder,
    private _partnerApiService: PartnerApiService,
    private successService: SuccessModalService
  ) {
    this.becomePartnerForm = _fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.email]],
      termsAndConds: [false, Validators.requiredTrue]
    })
  }

  public onSubmit(): void {
    /* mark all inputs */
    this._markAllInputs()

    /* if form is invalid */
    if (!this.becomePartnerForm.valid) {
      return;
    }

    /* set isrequesting */
    this._requestStarted()

    const postData: ContactSimpleBody = {
      emailAddress: this.becomePartnerForm.value.emailAddress ?? '',
      first_name: this.becomePartnerForm.value.firstName ?? '',
      last_name: this.becomePartnerForm.value.lastName ?? '',
      termsAndConds: this.becomePartnerForm.value.termsAndConds ?? false
    }
    /* sent data */
    this._partnerApiService.contact2(postData, this)
  }

  /* handles success subscription request */
  public handleSuccessResponse(): void {
    this._requestStopped()
    this.done = true
    this.becomePartnerForm.reset()
    this.successService.showSuccessTranslate("dynamic.text2")
  }

  /* handles failed subscription request */
  public handleErrorResponse(err: string): void {
    this._requestStopped()
  }

  /* returns validation errors */
  public getError(attribute: string, validationRule: string) {
    return this.becomePartnerForm.get(attribute)?.touched && this.becomePartnerForm.get(attribute)?.errors?.[validationRule]
  }

  /* requesting started */
  private _requestStarted(): void {
    this.isRequesting = true
  }

  /* requesting finihsed */
  accepted: boolean = false;
  private _requestStopped(): void {
    this.isRequesting = false
  }

  private _markAllInputs() {
    Object.keys(this.becomePartnerForm.controls).map((input) => {
      this.becomePartnerForm.get(input)?.markAsTouched()
    })
  }

}
