import { animate, query, sequence, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { simpleFade } from 'src/app/shared/animations/buttons/simple-fade.animation';
import { HandleErrorAndSuccess } from 'src/app/shared/interfaces/common/handle-error-and-success';
import { ForgotPasswordApiService } from 'src/app/shared/services/apiServices/forgotPasswordApiService/forgot-password-api.service';
import { ResetPasswordApiService } from 'src/app/shared/services/apiServices/resetPasswordApiService/reset-password-api.service';
import { SuccessModalService } from 'src/app/shared/services/SuccessService/success-modal.service';
import { passwordAgainSame } from 'src/app/shared/validators/password-again-same.validator';

@Component({
  selector: 'mvm-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss', '../forgot-password/forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [ trigger('fade', simpleFade)]
})
export class ResetPasswordComponent implements HandleErrorAndSuccess {
  public resetPasswordForm!: FormGroup
  public isRequesting: boolean = false
  public done: boolean = false
  private destroy$ = new Subject<void>();

  constructor(
    private _fb: FormBuilder, 
    private route: ActivatedRoute,
    private _successModalService: SuccessModalService,
    private resetPasswordApiService : ResetPasswordApiService) {

      const token = this.route.snapshot.queryParams['token']

      this.resetPasswordForm = this._fb.group({
        passwordResetToken: [token, Validators.required],
        password: ['', Validators.required],
        passwordConfirmation: ['', [Validators.required, passwordAgainSame()]]
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete(); 
  }

  public ngOnInit() {
    this._registerValueChangeHandlers()
  }

  private _registerValueChangeHandlers() {
    this.resetPasswordForm.get('password')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.resetPasswordForm.controls['passwordConfirmation'].updateValueAndValidity()
    })
  }

  public onSubmit(): void {
    /* mark all inputs */
    this._markAllInputs()

    /* if form is invalid */
    if(!this.resetPasswordForm.valid) { return ;}

    /* set isrequesting */
    this._requestStarted()
    
    /* sent data */
    this.resetPasswordApiService.resetPassword(this.resetPasswordForm.value, this)
  }

  /* handles success subscription request */
  public handleSuccessResponse(res: any): void {
    this._requestStopped()
    this.done = true
    this.resetPasswordForm.reset()
    this._successModalService.showSuccess(res.data.message)
  }

  /* handles failed subscription request */
  public handleErrorResponse(err: string): void {
    this._requestStopped()
  }

  /* returns validation errors */
  public getError(attribute: string, validationRule: string) {
     return this.resetPasswordForm.get(attribute)?.touched && this.resetPasswordForm.get(attribute)?.errors?.[validationRule]
  }

  /* requesting started */
  private _requestStarted(): void {
    this.isRequesting = true
  }

  /* requesting finihsed */
  private _requestStopped(): void {
    this.isRequesting = false
  }

  private _markAllInputs() {
    Object.keys(this.resetPasswordForm.controls).map((input) => {
      this.resetPasswordForm.get(input)?.markAsTouched()
    })
  }
}
