import { transition, style, animate } from "@angular/animations";

export const simpleFade = [
    transition(':enter', [
      style({ position: 'relative', opacity: 0, height: "*" }),
      animate('250ms 250ms', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({ opacity: 1, height: 0 }),
      animate('250ms', style({ opacity: 0 }))
    ])
]