import {animate, style, transition, trigger} from "@angular/animations";


export const fadeInOut = [
  trigger('fadeInOut', [
    transition(':enter', [
      style({ position: 'relative', opacity: 0, height: "*" }),
      animate('200ms 200ms', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({ opacity: 1, height: 0 }),
      animate('200ms', style({ opacity: 0 }))
    ])
  ]),
]

export const fadeInOutSimple = [
  trigger('fadeInOutSimple', [
    transition(':enter', [
      style({ opacity: 0}),
      animate('200ms 200ms', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({ opacity: 1}),
      animate('200ms', style({ opacity: 0 }))
    ])
  ]),
]

export const fadeOut = [
  trigger('fadeOut', [
    transition(':leave', [
      style({ opacity: 1}),
      animate('200ms', style({ opacity: 0 }))
    ])
  ]),
]
