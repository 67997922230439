import { createAction, props } from '@ngrx/store';
import {ModifyTicketState} from "../states/modify-ticket.state";

export const modifyTicketSet = createAction(
    '[Checkout] ModifyTicket data set',
    props<ModifyTicketState>()
)

export const modifyTicketRemove = createAction(
  '[Checkout] ModifyTicket - clear data'
)
