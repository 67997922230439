import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpStatusCode,
} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {TokenService} from "../services/auth-services/token.service";
import {environment} from "../../../environments/environment";
import {RefreshTokenResponse} from "../interfaces/api-services/auth/refresh-token/refresh-token-response.interface";
import {Store} from "@ngrx/store";
import {logoutSuccess} from "../../store/actions/auth.actions";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private _tokenService: TokenService, private http: HttpClient, private _store: Store) {
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = this._addBearerToken(httpRequest)

    return next.handle(request).pipe(
      catchError((err: any, _caught: any) => {
        if(err instanceof HttpErrorResponse)  {
          if(err.status === HttpStatusCode.Forbidden){
            this._handleUnauthorizedError(request, next)
          }
        }
        return throwError(err);
      })
    )
  }

  private _handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler) {
    this.http.post<RefreshTokenResponse>(`${environment.apiBaseUrl}auth/refresh`, {}).subscribe({
      next: (res: RefreshTokenResponse) => {

        this._tokenService._setBearerToken(res.data.token)
        this._tokenService._setRefreshToken(res.data.refresh_token)

        return next.handle(this._addBearerToken(request)).subscribe()
      },
      error: (err: any) => {
        this._tokenService._removeTokens()
        this._store.dispatch(logoutSuccess())
      }
    })
  }

  private _addBearerToken(req: HttpRequest<any>): HttpRequest<any> {
    const token = this._tokenService._retrieveBearerToken()
    if (!token) return req

    return req.clone({
      setHeaders: {
        "Authorization": `Bearer ${token}`,
      }
    })
  }
}
