import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SendTicketModalService {

  constructor() {
  }

  state$: BehaviorSubject<{
    visible: boolean,
    ticketId?: string,
    ticketType?: "parking" | "simple"
  }> = new BehaviorSubject<{
    visible: boolean,
    ticketId?: string,
    ticketType?: "parking" | "simple"
  }>({
    visible: false
  });

  openModal(ticketId: string, type: "parking" | "simple") {
    this.state$.next({
      visible: true,
      ticketId: ticketId,
      ticketType: type
    })
  }

  close() {
    this.state$.next({visible: false, ticketId: undefined, ticketType: undefined})
  }
}
