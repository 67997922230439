<mvm-core>

  <section class="search-controls">
    <mvm-container [type]="ContainerTypes.Searchbar">
      <mvm-home-searchbar></mvm-home-searchbar>
      <div class="categories-container flex justify-around">
        <div class="categories flex">
          <!--<mvm-colored-border-button
                  class="concert" [color]="BorderedButtonColors.yellow"
                  text="Koncert"
                  [type]="ProgramTypes.Concert"
          ></mvm-colored-border-button>
          <mvm-colored-border-button
                  class="corporate" [color]="BorderedButtonColors.green"
                  text="Corporate"
                  [type]="ProgramTypes.Corporate"
          ></mvm-colored-border-button>
          <mvm-colored-border-button
                  class="sport" [color]="BorderedButtonColors.blue"
                  text="Sport"
                  [type]="ProgramTypes.Sport"
          ></mvm-colored-border-button>-->
        </div>
      </div>
    </mvm-container>
  </section>

  <div class="spacer"></div>

  <section class="container-events-white">
    <p *ngIf="(events$ | async) !== null && (events$ | async)?.length! < 1" class="text-super-h1 text-center" style="margin-top: 60px">{{'emptyPrograms' | translate}}</p>
    <!--<p *ngIf="(events$ | async) === null" class="text-super-h1 text-center" style="margin-top: 60px">{{'loading' | translate}}</p>-->
    <div class="events-frame-programs">
      <ng-container *ngIf="skeleton">
        <div *ngFor="let a of [1,2,3,4,5,6,7,8,9,10]" class="skeleton-card skeleton-full"></div>
      </ng-container>
      <mvm-card-program *ngFor="let e of (events$ | async)" [program]="e"></mvm-card-program>
      <!-- ADVERT -->
      <!--<div *ngIf="(events$ | async)?.length! > 0" id="ad" style="width: 100%; height: 100%; min-height: 250px;border-radius: 6px">-->
        <!-- /21705402174/mvmdome_esemenynaptar -->
        <!--<div class="advert-center" id='div-gpt-ad-1688748294551-0' style='min-width: 250px; min-height: 250px;'></div>-->
      <!-- ADVERT -->
      <!--<mvm-card-program *ngFor="let e of getSplitPart(events$ | async, 1)" [program]="e"></mvm-card-program>-->
    </div>

    <!--<div id="programs-advert">
      <div class="advert-center" id='div-gpt-ad-1688748294551-0' style='min-width: 250px; min-height: 250px;'></div>
    </div>-->

    <section>
      <mvm-container [type]="ContainerTypes.HowToApproach">
        <div>
          <mvm-how-to-approach></mvm-how-to-approach>
        </div>
      </mvm-container>
    </section>

  </section>


</mvm-core>


