import {createReducer, on} from "@ngrx/store";
import {initialModifyTicketState} from "../states/modify-ticket.state";
import {modifyTicketRemove, modifyTicketSet} from "../actions/modify-ticket.actions";

export const modifyTicketReducer = createReducer(
    initialModifyTicketState,
    on(modifyTicketSet, (state, {ticket}) => ({ticket: ticket})),
    on(modifyTicketRemove, () => ({ ticket: null }))
)

