import { transition, style, animate } from "@angular/animations";

export const mobileNavbarFade = [
    transition(':enter', [
      style({ opacity: 0, }),
      animate('250ms 250ms', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate('250ms', style({ opacity: 0 }))
    ])
]