import { Injectable } from '@angular/core';
import {Body as ContactBody, ContactSimpleBody} from 'src/app/shared/interfaces/api-services/contact/body';
import { HandleErrorAndSuccess } from 'src/app/shared/interfaces/common/handle-error-and-success';
import { ApiRequestService } from '../apiRequestService/api-request.service';

@Injectable({
  providedIn: 'root'
})
export class PartnerApiService {

  constructor(
    private _apiRequestService: ApiRequestService,
  ) { }

  public contact(partnerContactData: ContactBody, self: HandleErrorAndSuccess ) {

    this._apiRequestService.postRequest('partner-contact',
      partnerContactData ,
      (res) => { self.handleSuccessResponse() },
      (err) => { self.handleErrorResponse(err) },
      true
    )
  }
  public contact2(partnerContactData: ContactSimpleBody, self: HandleErrorAndSuccess ) {
    this._apiRequestService.postRequest('contact',
      partnerContactData,
      (res) => { self.handleSuccessResponse() },
      (err) => { self.handleErrorResponse(err) },
      true
    )
  }

}
