import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RadioPayComponent} from "../radio-pay/radio-pay.component";
import {growInOut} from "../../../animations/growInOut";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'mvm-chooser-pay',
  standalone: true,
  imports: [CommonModule, RadioPayComponent, TranslateModule],
  templateUrl: './chooser-pay.component.html',
  styleUrls: ['./chooser-pay.component.scss'],
  animations: growInOut
})
export class ChooserPayComponent {
  @Input() checkedValue: string = "";
  @Output() paymentProviderSelected: EventEmitter<string> = new EventEmitter<string>()


  paymentProviderChanged() {
    this.paymentProviderSelected.emit(this.checkedValue)
  }
}
