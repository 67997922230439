import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavbarComponent} from "../../components/navbar/navbar.component";
import {ContainerComponent, ContainerTypes} from "../../../shared/components/container/container.component";
import {
  BorderedButtonColors,
  ColoredBorderButtonComponent
} from "../../../shared/components/buttons/colored-border-button/colored-border-button.component";
import {HomeSearchbarComponent} from "../../components/home/home-searchbar/home-searchbar.component";
import {CardProgramComponent} from "../../../shared/components/cards/card-program/card-program.component";
import {HowToApproachComponent} from "../../../shared/components/how-to-approach/how-to-approach.component";
import {FooterComponent} from "../../components/footer/footer.component";
import {CoreComponent} from "../../layouts/core-layout/core.component";
import {Store} from "@ngrx/store";
import {EventsApiService} from "../../../shared/services/apiServices/eventsApiService/events-api.service";
import {selectEventsEvents} from "../../../store/selectors/events.selector";
import {ActivatedRoute} from "@angular/router";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {Subject, takeUntil, tap} from "rxjs";
import {ProgramTypes} from "../../../shared/enums/program-types.enum";
import {Event} from "../../../shared/interfaces/events/events.types";
import {addGoogleAds} from "../../../shared/addGoogleAds";

@Component({
  selector: 'mvm-programs',
  standalone: true,
  imports: [CommonModule, NavbarComponent, ContainerComponent, ColoredBorderButtonComponent, HomeSearchbarComponent, CardProgramComponent, HowToApproachComponent, FooterComponent, CoreComponent, TranslateModule],
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit, AfterViewInit, OnDestroy {
  ProgramTypes = ProgramTypes
  ContainerTypes = ContainerTypes
  BorderedButtonColors = BorderedButtonColors

  events$ = this.store.select(selectEventsEvents)

  private pageNumber?: number
  private type?: ProgramTypes
  private queryString?: string

  private destroy$ = new Subject<void>()
  skeleton: boolean = true;

  constructor(
    private store: Store,
    private eventsService: EventsApiService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.events$.pipe(tap(res => {
      if (res?.length ?? 0 > 0) {
        this.skeleton = false
      }
    })).pipe(takeUntil(this.destroy$)).subscribe()
    this.eventsService.loadDataQuery()
    this.activatedRoute.queryParams.subscribe(params => {
      this.pageNumber = params['pageNumber']
      //const type = params['type']?.split(',') ?? []
      this.type = params['type'] ?? ''
      this.queryString = params['q']
      this.eventsService.loadDataQuery(this.pageNumber, undefined, this.type, this.queryString)
    })

    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(
      () => this.eventsService.loadDataQuery(
        this.pageNumber,
        undefined,
        this.type,
        this.queryString
      )
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getSplitPart(events: Event[] | null, number: 0 | 1): Event[] | null {
    if (events) {
      if (number === 0) {
        if (events.length < 3) {
          return events
        } else {
          return events.slice(0, 2)
        }
      } else {
        if (events.length < 3) {
          return []
        } else {
          return events.slice(2)
        }
      }
    } else {
      return null
    }
  }

  ngAfterViewInit(): void {
    addGoogleAds("div-gpt-ad-1688748294551-0");
  }
}
