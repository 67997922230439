import {Component, EventEmitter, Input, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from "@angular/forms";
import {AngularSvgIconModule} from "angular-svg-icon";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-radio-pay',
  standalone: true,
    imports: [CommonModule, FormsModule, AngularSvgIconModule, TranslateModule],
  templateUrl: './radio-pay.component.html',
  styleUrls: ['./radio-pay.component.scss']
})
export class RadioPayComponent {

  @Input() type: "bankcard" | "apple" | "google" | "paypal" | "banktransfer" = "bankcard"
  @Input() checkedValue: string = '';
  @Output() checkedValueChange: EventEmitter<string> = new EventEmitter<string>();

}
