<div class="modal-container" [ngClass]="visible ? 'modal-visible' : 'modal-hidden'" (click)="close()">

  <div class="modal-content-container">

    <div class="modal-info" (click)="$event.stopPropagation();">
      <div class="modal-content">
        <div class="text-subtitle text-center">{{'register.title' | translate}}</div>
        <div class="text-price text-center">{{'registerContinue' | translate}}</div>
      </div>
      <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">

        <div class="checkbox-wrapper flex cursor-pointer" id="social-terms" (click)="accepted = !accepted">
          <mvm-checkbox [style]="'dark'" [rounded]="true"
                        formControlName="termsAndConds"></mvm-checkbox>
          <p [innerHTML]="'register.social_accept' | translate"></p>
        </div>
        <mvm-error classes="mb-5" [show]="getError('termsAndConds', 'required')"
                   [text]="'register.validation.termsAndConds.required' | translate"></mvm-error>

        <button type="submit"
                class="btn-yellow">{{ !isRequesting ? ('next' | translate) : ('in_progress' | translate) }}</button>
      </form>
    </div>

    <div class="modal-x" (click)="close()">
      <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="34" height="34" rx="17" fill="#F5B126"/>
        <path
          d="M11.8383 22.6623C11.5987 22.423 11.4863 22.1763 11.5013 21.9222C11.5163 21.668 11.6361 21.4288 11.8608 21.2045L16.1295 16.9433L11.9282 12.7493C11.7035 12.5251 11.5837 12.2858 11.5687 12.0317C11.5687 11.7924 11.6811 11.5607 11.9057 11.3364C12.1304 11.1121 12.3625 11 12.6022 11C12.8568 11.015 13.0965 11.1346 13.3211 11.3588L17.5224 15.5528L21.6563 11.4261C21.896 11.1869 22.1356 11.0673 22.3753 11.0673C22.6299 11.0523 22.877 11.1645 23.1167 11.4037C23.3563 11.6429 23.4686 11.8896 23.4537 12.1438C23.4537 12.383 23.3338 12.6223 23.0942 12.8615L18.9603 16.9881L23.1616 21.1821C23.3863 21.4063 23.4986 21.6381 23.4986 21.8773C23.5136 22.1315 23.4087 22.3707 23.1841 22.595C22.9594 22.8193 22.7197 22.9239 22.4651 22.909C22.2255 22.909 21.9933 22.7968 21.7687 22.5726L17.5674 18.3786L13.2987 22.6398C13.074 22.8641 12.8344 22.9837 12.5797 22.9987C12.3251 23.0136 12.078 22.9015 11.8383 22.6623Z"
          fill="black"/>
      </svg>
    </div>

  </div>

</div>
