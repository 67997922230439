<div class="ticket-selector-card">
  <div class="card-border">
    <img class="border-image" *ngIf="ticket !== undefined" [src]="borderImageSrc" alt="Ticket border">
  </div>
  <div class="card-body" *ngIf="ticket !== undefined">
    <img @fadeInOut *ngIf="(state !== 'seat-choosing' || mapType !== 'skybox') && state !== 'parking'"
         class="cover-image" [src]="coverImageSrc"
         alt="Cover image">

    <div @fadeInOut *ngIf="state !== 'parking'" class="flex justify-between ">
      <p class="text-title text-dark">{{getTitle()}}</p>
      <p class="text-dark text-price">{{changeDivider(ticket.price | number: '1.0-2')}} {{'ft/db' | translate}}</p>
    </div>

    <div @fadeInOut *ngIf="state !== 'parking'" class="flex justify-between mt-15px mb-15px">
      <p class="text-title text-dark">{{ticket.quantity}} db</p>
      <p class="text-title text-dark">{{changeDivider(ticket.quantity * ticket.price | number: '1.0-2')}} Ft</p>
    </div>

    <button @fadeInOut *ngIf="state==='default'" class="btn-yellow mb-10px"
            (click)="chooseSeatsState(false)">{{'ticketModify.manual' | translate}}</button>
    <button @fadeInOut *ngIf="state==='default'" class="btn-yellow"
            (click)="chooseSeatsState(true)">{{'ticketModify.auto' | translate}}</button>

    <div *ngIf="state==='seat-choosing'" style="width: 100%;overflow:hidden;">
      <mvm-seat-map [eventSlug]="eventSlug ?? ''" [eventType]="ticket.type"></mvm-seat-map>
    </div>

    <p *ngIf="state==='seat-choosing' && ticket.quantity > 5  && mapType !== 'skybox' && ticket.type !== 'skybox'"
       (click)="mapType = 'skybox'"
       class="blue-info mt-20px mb-20px">{{'ticketModify.skybox' | translate}}</p>

    <div @slideDownFadeInOut *ngIf="state === 'parking'" class="flex flex-col gap-25px items-center w-full">
      <svg-icon name="parking-ticket"></svg-icon>
      <p class="text-dark text-parking-title">{{'ticketModify.parking' | translate}}</p>
      <app-chooser-parking class="w-full"
                           (parkingChange)="addParkingTicketToCart($event.type, $event.quantity)"></app-chooser-parking>
    </div>

    <div *ngIf="state === 'seat-choosing' || state === 'parking'"
         class="flex flex-col items-center mt-15px mb-15px">
      <button class="btn-text-yellow mb-10px"
              (click)="toggleParking()">{{state === 'parking' ? ('ticketModify.back' | translate) : ('ticketModify.park' | translate)}}</button>
      <button class="btn-yellow mb-10px" (click)="modificationFinishedFn()">{{'ticketModify.modify' | translate}}</button>
      <button class="btn-text-yellow" *ngIf="state !== 'parking'"
              (click)="chooseSeatsState(true)">{{'ticketModify.auto2' | translate}}</button>
    </div>


    <mvm-dropdown-ticketinfo class="mt-10px"
                             *ngIf="state === 'seat-choosing' && ticket.type !== 'skybox' && ticket.quantity > 5"
                             ></mvm-dropdown-ticketinfo>

  </div>
</div>
