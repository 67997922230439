export interface ModifyTicketState {
  ticket: {
    event_slug: string,
    ticketType:  "gold" | "platinum" | "skybox"
  } | null
}

export const initialModifyTicketState: ModifyTicketState = {
  ticket: null
}
