import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapApiService } from "../../services/apiServices/mapApiService/map-api.service";
import { CartApiService } from "../../services/apiServices/cartApiService/cart-api.service";
import { CartTicketData, CheckoutCart } from "../../interfaces/checkout-cart/checkout-cart.models";
import { selectCartCart } from "../../../store/selectors/cart.selector";
import { Store } from "@ngrx/store";
import { Subject, takeUntil } from "rxjs";
import { fadeInOut } from "../../animations/fadeInOut";
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Sector } from '../../interfaces/seat-map/sector';
import { Map, SkyboxBlock } from '../../interfaces/seat-map/map';
import { HandleErrorAndSuccess } from '../../interfaces/common/handle-error-and-success';
import { TranslateModule } from "@ngx-translate/core";
import { SuccessModalService } from '../../services/SuccessService/success-modal.service';
import { Position } from '../../interfaces/seat-map/position';

@Component({
  selector: 'mvm-seat-map',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, TranslateModule],
  templateUrl: './seat-map.component.html',
  styleUrls: ['./seat-map.component.scss'],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeatMapComponent implements OnInit, OnDestroy, HandleErrorAndSuccess {

  @Input() eventSlug: string = "bagossy-brothers"
  @Input() eventType: "gold" | "platinum" | "skybox" = "gold"

  public cart$ = this.store.select(selectCartCart)
  public data?: Map
  public dirty: boolean = false

  public skyboxBlock?: SkyboxBlock
  public selectedSector: string = ""
  public selectedBlock: string = ""
  private interval: any
  public destroy = new Subject<void>()
  private cart?: CheckoutCart | null;
  public isRequesting: boolean = false
  public selectingId: string = ""
  public actionType: 'add' | 'remove' = "add"
  public showSeatMapControlsDemo: boolean = false

  constructor(
    private store: Store,
    private mapService: MapApiService,
    private cartService: CartApiService,
    private _successModalService: SuccessModalService,
    private _ngZone: NgZone,
    private _cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.getMapData()
    this.setCartSubscription()
    this.interval = setInterval(() => {
      //this.getMapData()
    }, 10000);

    this._init()
    this.showSeatMapControlsDemo = !localStorage.getItem('seat_map_controls_demo_layer') ? true : false
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  private _init() {
    this._ngZone.runOutsideAngular(() => {
      document.addEventListener('touchmove', (e) => this.onTouchMoveEvent(e), { passive: false, capture: false })
      document.addEventListener('wheel', (e) => this.onWheelEventListener(e), { passive: false, capture: false })
      //document.addEventListener('mousedown', (e) => this.onDocumentMouseDownListener(e), { passive: false, capture: false })
      //document.addEventListener('mousemove', (e) => this.onDocumentMouseMoveListener(e), { passive: false, capture: false })
      //document.addEventListener('mouseup', (e) => this.onDocumentMouseUpListener(e), { passive: false, capture: false })
    })
  }

  public onWheelEventListener(e: WheelEvent) {
    if (e.ctrlKey) {
      e.preventDefault()
      e.stopPropagation();
      this.onWheel(e)
      return false;
    }

    return true
  }


  backToSectorView() {
    this.selectedSector = ""
    this.selectedBlock = ""
    this.skyboxBlock = undefined
  }

  selectSector(sectorId: string, block?: string) {
    if (this.eventType !== "skybox") {
      this.selectedSector = sectorId
    } else if (block) {
      //this.getSkyboxBlock(block)
      this.addToCartSkybox(sectorId)
    }
  }

  getSkyboxBlock(blockName: string) {
    this.resetZoom()
    this.mapService.getSkyboxBlock(this.eventSlug, "skybox", blockName, (block) => {
      this.selectedBlock = blockName
      this.skyboxBlock = block
      this._cdr.detectChanges()
    })
  }

  getMapData() {
    /* if not skybox */
    if (this.selectedBlock === "") {
      this.mapService.getTicketMap(this.eventSlug, this.eventType, (res) => {
        this.data = res
        this._cdr.detectChanges()
      })
    }
    /* skybox */
    else {
      /* if block selected */
      if (this.selectedBlock) {
        this.getSkyboxBlock(this.selectedBlock)
      }
    }

  }

  selectSeat(selectable: boolean, seatId: string) {

    if (!selectable && !this.isSeatReservedByMe(seatId)) return
    if (this.isRequesting) return;
    this.isRequesting = true
    this.selectingId = seatId

    if (this.isSeatReservedByMe(seatId)) {
      this.deleteFromCart(seatId)
    } else if (selectable) {
      if (this.eventType !== "skybox") {
        this.addToCartTicketSimple(seatId)
      } else {
        this.addToCartSkybox(seatId)
      }
    }
  }

  addToCartSkybox(seadId: string) {
    const ticket: CartTicketData = {
      event_slug: this.eventSlug,
      skybox: {
        identifier: seadId,
        auto: false
      }
    }
    this.actionType = 'add'
    this.cartService.addToCart(ticket, this)
  }

  addToCartTicketSimple(seatId: string) {
    const ticket: CartTicketData = {
      event_slug: this.eventSlug,
      tickets: {
        auto: false,
        type: this.eventType === "gold" ? "gold" : "platinum",
        seat_id: seatId
      }
    }
    this.actionType = 'add'
    this.cartService.addToCart(ticket, this)
  }

  private deleteFromCart(seatId: string) {
    this.actionType = 'remove'
    if (this.eventType === "skybox") {
      this.cartService.deleteSkyboxTicketFromCart(this.eventSlug, seatId, this)
    } else {
      this.cartService.deleteTicketFromCart(this.eventSlug, seatId, this)
    }
  }

  public handleErrorResponse() {
    this.isRequesting = false
    this.getMapData()
  }

  public handleSuccessResponse() {
    this.isRequesting = false
    this.getMapData()
    this._successModalService.showSuccessTranslate(this.actionType == 'add' ? 'seatMapAddedSuccessfully' : 'seatMapRemovedSuccessfully')
  }

  isSeatReservedByMe(seatId: string): boolean {
    const event = this.cart?.events.find(e => e.slug === this.eventSlug)
    const ticket = event?.tickets.find(t => t.seat_ids.includes(seatId)) ?? event?.tickets.find(t => t.skybbox_ids?.includes(seatId))
    return !!ticket;
  }

  private setCartSubscription() {
    this.cart$.pipe(takeUntil(this.destroy)).subscribe(res => {
      this.cart = res
    })
  }

  get getSelectedSector() {
    if (!this.selectedSector) return {} as Sector

    const sector = this.data?.data?.sectors.find((e) => {
      return e.i == this.selectedSector
    })
    return sector
  }

  /* ZOOM & MOVING */
  public initialZoom: number = 1.0
  public zoom: number = 1.0;
  public sectorLayerZoomThreshold: number = 9.9;
  public zoomAmount: number = 1.1
  public mobileZoomAmount: number = 1.1
  public maxZoom: number = 40
  public center: { x: number, y: number } = { x: 0, y: 0 }
  public matrix: [number, number, number, number, number, number] = [this.initialZoom, 0, 0, this.initialZoom, this.center.x, this.center.y]
  public mouse: { buttonPressing: boolean, x: number, y: number } = { buttonPressing: false, x: 0, y: 0 }
  public pos = { x: 0, y: 0 } as Position
  public shouldApplyTransition: boolean = true
  public lastScalingTime: number = 0
  public disableMoving: boolean = false

  /* ZOOM IN */
  zoomIn(zoomAmount: number = this.zoomAmount) {
    if (this.zoom * zoomAmount > this.maxZoom) return

    this.zoom *= zoomAmount

    this.roundZoom()

    this.center = {
      x: this.center.x * zoomAmount,
      y: this.center.y * zoomAmount
    }

    this.adjustMatrix()
  }

  /* ZOOM OUT */
  zoomOut() {
    this.simpleZoomOut()

    /*if (this.zoom / this.zoomAmount < this.initialZoom) return

    const nthZoomRound = this.getZoomRound()
    const xDifference = this.center.x - 0
    const yDifference = this.center.y - 0

    let newX = xDifference / 130 * 100;
    let newY = yDifference / 130 * 100;

    if (isNaN(newX) || nthZoomRound == 1) newX = 0
    if (isNaN(newY) || nthZoomRound == 1) newY = 0

    this.center = {
      x: newX,
      y: newY,
    }
    this.zoom /= this.zoomAmount

    this.roundZoom()
    this.adjustMatrix()*/
  }

  /* ROUND ZOOM */
  private getZoomRound() {
    return Math.round(this.getBaseLog(this.zoomAmount, this.zoom))
  }

  /* GET BASE LOG */
  private getBaseLog(x: number, y: number) {
    return Math.log(y) / Math.log(x);
  }

  get mapHasMoved() {
    return this.center.x != 0 || this.center.y != 0
  }

  /* ROUND ZOOM */
  private roundZoom() {
    this.zoom = Math.round((this.zoom + Number.EPSILON) * 100) / 100
  }

  /* RESET ZOOM */
  resetZoom() {
    this.zoom = this.initialZoom
    this.center = {
      x: 0,
      y: 0
    }
    this.shouldApplyTransition = true
    this.mouse = {
      x: 0,
      y: 0,
      buttonPressing: false
    }
    this.pos = {
      x: 0,
      y: 0
    }
    this.adjustMatrix()
  }

  /* ADJUST MATRIX */
  private adjustMatrix() {
    this.matrix = [
      this.zoom,
      0,
      0,
      this.zoom,
      this.center.x,
      this.center.y,
    ]
    this.disableMoving = false

    this._cdr.detectChanges()
  }

  /* ZOOM */

  /* DESKTOP MOVING */

  /* MOUSE DOWN */
  @HostListener('document:mousedown', ['$event'])
  onMouseDownEvent(event: MouseEvent) {
    const element = document.getElementById('map-holder')
    const movingOnMap = element?.contains(event.target as Node)
    if (!movingOnMap || this.disableMoving) return;

    this.mouse = {
      buttonPressing: true,
      x: event.clientX,
      y: event.clientY,
    }
  }

  /* MOUSE UP */
  @HostListener('document:mouseup', ['$event'])
  onMouseUpEvent(event: MouseEvent) {
    if (this.mouse.buttonPressing) {
      this.mouse.buttonPressing = false
    }
  }

  /* MOUSE MOVE */
  @HostListener('document:mousemove', ['$event'])
  onMouseMoveEvent(event: MouseEvent) {

    const element = document.getElementById('map-holder')
    const movingOnMap = element?.contains(event.target as Node)
    if (!movingOnMap || this.disableMoving) return;
    this.handleMouseMovement(event)
  }

  /* HANDLE MOUSE MOVEMENT */
  private handleMouseMovement(event: any, type: 'desktop' | 'mobile' = 'desktop') {
    if (this.mouse.buttonPressing) {
      this.shouldApplyTransition = false

      const offsetX = type == 'desktop' ?
        ((event.clientX ?? event.pageX ?? event.touches[0].clientX) - this.mouse.x) :
        ((event.clientX ?? event.touches[0].clientX ?? event.pageX) - this.mouse.x)

      //const offsetX = (event.clientX ?? event.pageX) - this.mouse.x

      //balra megyünk
      if (offsetX > 0 && this.canMoveMapLeft()) {
        this.setX(event, offsetX, type)
      }
      //jobbra megyünk
      else if (offsetX < 0 && this.canMoveMapRight()) {
        this.setX(event, offsetX, type)
      }

      const offsetY = type == 'desktop' ?
        ((event.clientY ?? event.pageX ?? event.touches[0].clientY) - this.mouse.y) :
        ((event.clientY ?? event.touches[0].clientY ?? event.pageX) - this.mouse.y)

      //const offsetY = (event.clientY ?? event.pageY) - this.mouse.y

      //felfelé megyünk
      if (offsetY > 0 && this.canMoveMapUp()) {
        this.setY(event, offsetY, type)
      }
      //lefelé megyünk
      else if (offsetY < 0 && this.canMoveDown()) {
        this.setY(event, offsetY, type)
      }
    }
  }

  /* SET X COORDINATE */
  private setX(event: any, offsetX: number, type: 'desktop' | 'mobile') {
    const x = this.center.x
    this.center = {
      ...this.center,
      x: this.center.x + offsetX
    }

    this.pos.x += offsetX

    this.adjustMatrix()

    this.mouse.x = type == 'desktop' ?
      (event.clientX ?? event.pageX ?? event.touches[0].clientX) :
      (event.clientX ?? event.touches[0].clientX ?? event.pageX)
  }

  /* SET Y COORDINATE */
  private setY(event: any, offsetY: number, type: 'desktop' | 'mobile') {
    this.center = {
      ...this.center,
      y: this.center.y + offsetY
    }

    this.pos.y += offsetY

    this.adjustMatrix()

    this.mouse.y = type == 'desktop' ?
      (event.clientY ?? event.pageY ?? event.touches[0].clientY) :
      (event.clientY ?? event.touches[0].clientY ?? event.pageY)
  }

  /* CAN MOVE */
  private canMoveMapLeft() {
    const xCoordinate = this.center.x / this.zoom
    const element = document.getElementById('map-holder')
    return xCoordinate < (element?.getBoundingClientRect()?.width! / 2);
  }

  /* CAN MOVE */
  private canMoveMapRight() {
    const xCoordinate = this.center.x / this.zoom
    const element = document.getElementById('map-holder')
    return xCoordinate > (-1 * element?.getBoundingClientRect()?.width! / 2);
  }

  /* CAN MOVE */
  private canMoveMapUp() {
    const yCoordinate = this.center.y / this.zoom
    const element = document.getElementById('map-holder')
    return yCoordinate < (element?.getBoundingClientRect()?.height! / 2);
  }

  /* CAN MOVE */
  private canMoveDown() {
    const yCoordinate = this.center.y / this.zoom
    const element = document.getElementById('map-holder')
    return yCoordinate > (-1 * element?.getBoundingClientRect()?.height! / 2);
  }

  /* DESKTOP MOVING */

  /* MOBILE MOVING */

  /* TOUCH START */
  @HostListener('document:touchstart', ['$event'])
  onTouchStartEvent($event: any) {

    const element = document.getElementById('map-holder')
    const movingOnMap = element?.contains($event.target as Node)
    if (!movingOnMap || this.disableMoving) return;
    const xCoordinate = $event?.clientX ?? $event.touches[0].clientX
    const yCoordinate = $event?.clientY ?? $event.touches[0].clientY
    this.mouse = {
      buttonPressing: true,
      x: xCoordinate,
      y: yCoordinate,
    }
  }

  /* TOUCH END */
  @HostListener('document:touchend', ['$event'])
  onTouchEndEvent($event: any) {
    if (this.mouse.buttonPressing) {
      this.mouse.buttonPressing = false
      this.previousDistance = 0
    }
  }

  /* TOUCH MOVE */

  //@HostListener('document:touchmove', ['$event'])
  onTouchMoveEvent($event: any) {
    const element = document.getElementById('map-holder')
    const movingOnMap = element?.contains($event.target as Node)
    if (!movingOnMap || this.disableMoving) return;
    $event.preventDefault();

    /* pinch zoom */
    if (($event.scale != undefined && $event.scale != 1) || $event.touches.length == 2) {
      if (this.hasScalingExpiredLong()) {
        if (this.isZoomingIn($event)) {
          this.disableMoving = true
          this.zoomIn(1.05)
        } else if (this.isZoomingOut($event)) {
          this.simpleZoomOut()
        }
      }
    }
    /* movement */
    else {
      this.handleMouseMovement($event, 'mobile')
    }
  }

  public previousDistance: number = 0;

  private isZoomingIn(event: any) {
    /* IOS */
    if (event.scale !== undefined && event.scale > 1) {
      return true;
    } else if (event.scale !== undefined && event.scale < 1) {
      return false;
    }

    /* ANDROID */
    const finger1 = event.touches[0];
    const finger2 = event.touches[1];
    const currentDistance = Math.sqrt(
      Math.pow(finger2.clientX - finger1.clientX, 2) +
      Math.pow(finger2.clientY - finger1.clientY, 2)
    );

    if (currentDistance > this.previousDistance && this.previousDistance != 0) {
      this.previousDistance = currentDistance
      return true
    }

    return false;
  }

  private isZoomingOut(event: any) {
    /* IOS */
    if (event.scale !== undefined && event.scale < 1) {
      return true;
    } else if (event.scale !== undefined && event.scale > 1) {
      return false;
    }

    /* ANDROID */
    const finger1 = event.touches[0];
    const finger2 = event.touches[1];
    const currentDistance = Math.sqrt(
      Math.pow(finger2.clientX - finger1.clientX, 2) +
      Math.pow(finger2.clientY - finger1.clientY, 2)
    );

    if (currentDistance < this.previousDistance && this.previousDistance != 0) {
      this.previousDistance = currentDistance
      return true
    }
    this.previousDistance = currentDistance

    return false;
  }

  /* MOBILE MOVING */

  /* DESKTOP PINCH ZOOM */
  onWheel(event: any) {
    const element = document.getElementById('map-holder')

    const zoomingOnMap = element?.contains(event.target as Node)

    if (zoomingOnMap && element) {
      const x = event.layerX - (element?.clientWidth / 2)
      const y = event.offsetY - (element?.clientHeight / 2)

      if (event.deltaY < 0) {
        this.myScaleAt({ x, y }, 1.1);
        this.applyTo();
      } else {
        this.myScaleAt({ x, y }, 1 / 1.1);
        this.applyTo();
      }
    }

    event.preventDefault();
  }

  myScaleAt(at: { x: number, y: number }, amount: number) { // at in screen coords
    if (this.zoom == 1 && amount < 1) return

    if (this.dirty) { this.update() }

    this.zoom = this.zoom * amount < 1 ? 1 : this.zoom * amount

    this.pos.x = at.x - (at.x - this.pos.x) * amount
    this.pos.y = at.y - (at.y - this.pos.y) * amount
    this.dirty = true
  }

  applyTo() {
    if (this.dirty) { this.update() }
  }

  update() {
    this.matrix = [
      this.zoom,
      0,
      0,
      this.zoom,
      this.pos.x,
      this.pos.y,
    ]

    this.center = {
      x: this.pos.x,
      y: this.pos.y
    }

    this.dirty = false
    this.lastScalingTime = Date.now()
    this._cdr.detectChanges()
  }
  /* DESKTOP PINCH ZOOM */

  /* MOBILE PINCH ZOOM */
  hasScalingExpiredLong() {
    return Date.now() - this.lastScalingTime > 400
  }

  /*@HostListener('gesturestart', ['$event'])
  @HostListener('gesturechange', ['$event'])
  @HostListener('gestureend', ['$event'])*/

  onGesture(event: any) {
    if (this.hasScalingExpiredLong()) {
      if (event.scale > 1) {
        this.disableMoving = true
        this.zoomIn(1.01)
      } else if (event.scale < 1) {
        this.simpleZoomOut()
      }
    }
  }

  simpleZoomOut() {
    if (this.zoom / this.zoomAmount < this.initialZoom) {
      this.zoom = this.initialZoom
      this.adjustMatrix()
      return
    }

    const xDifference = this.center.x - 0
    const yDifference = this.center.y - 0

    let newX = xDifference / 110 * 100;
    let newY = yDifference / 110 * 100;

    this.center = {
      x: newX,
      y: newY,
    }
    this.zoom /= this.zoomAmount

    //this.roundZoom()
    this.adjustMatrix()
  }
  /* MOBILE PINCH ZOOM */

  /* MAP STYLE */
  get mapStyle() {
    const x = this.center.x > 0 ? ' - ' + Math.abs(this.center.x) : ' + ' + Math.abs(this.center.x)
    const y = this.center.y > 0 ? ' - ' + Math.abs(this.center.y) : ' + ' + Math.abs(this.center.y)
    const b = { 'transform': 'matrix(' + this.matrix[0] + ',' + this.matrix[1] + ',' + this.matrix[2] + ',' + this.matrix[3] + ',' + this.matrix[4] + ',' + this.matrix[5] + ')' }

    return b;
  }

  get showSeatMapControlsDemoFn() {
    return this.showSeatMapControlsDemo && this.data
  }

  public hideSeatMapControls() {
    localStorage.setItem('seat_map_controls_demo_layer', '1')
    this.showSeatMapControlsDemo = false
  }

  /* zoom to clicked sector */
  zoomToSector(event: any) {
    let { x, y, width, height } = event.target.getBoundingClientRect();

    const mapHolder = document.getElementById('map-holder')

    const boundary = mapHolder!.getBoundingClientRect();
    const layerX = (x - boundary.x) 
    const layerY = (y -  boundary.y) 
    
    if (mapHolder) {
      const x = layerX - (mapHolder?.clientWidth / 2)
      const y = layerY - (mapHolder?.clientHeight / 2)

      this.scaleToPosition({ x, y , width, height}, 10);
      this.applyTo();
    }
  }

  /* scale to given position */
  scaleToPosition(coord: { x: number, y: number, width: number, height: number }, zoom: number) {
    if (this.dirty) { this.update() }

    this.zoom = zoom

    this.pos.y = ((this.matrix[5] - coord.y) / this.matrix[0] * zoom) - (coord.height / this.matrix[0]  / 2 * zoom)
    this.pos.x = ((this.matrix[4] - coord.x) / this.matrix[0] * zoom) - (coord.width / this.matrix[0]  / 2 * zoom)

    this.dirty = true
    this.applyTo();
  }
}
