<div id="top-holder">
  <mvm-container [type]="ContainerTypes.ProgramDetails">
    <!-- DESKTOP -->
    <div>
      <section class="hero flex justify-between gap-10px" id="top-content">
        <div class="title-and-tickets">
          <div *ngIf="programDetails === null" class="skeleton-card skeleton-title-desktop"></div>
          <h1 class="performancer text-white text-super-h1">{{ programDetails?.title }}</h1>

          <button (click)="programDetails?.can_be_purchased ? scrollToTickets() : null"
            class="btn-yellow no-margin only-desktop from-desktop">{{ !programDetails?.can_be_purchased ? ('home.program.ticketsFuture' | translate)  : ('home.program.ticketTypes' | translate)}}</button>

          <ul class="services">
            <li>
              <svg-icon name="event-tickets"></svg-icon>{{ 'home.program.tickets' | translate }}
            </li>
            <li>
              <svg-icon name="catering"></svg-icon>{{ 'home.program.catering' | translate }}
            </li>
            <li>
              <svg-icon name="diamond"></svg-icon>{{ 'home.program.vip-entry' | translate }}
            </li>
            <li>
              <svg-icon name="vip-p"></svg-icon>{{ 'home.program.vip-parking' | translate }}
            </li>
            <li>
              <svg-icon name="person"></svg-icon>{{ 'home.program.accomodation' | translate }}
            </li>
            <li>
              <svg-icon name="waiter-hand"></svg-icon>{{ 'home.program.service' | translate }}
            </li>
          </ul>


        </div>
        <div class="image-and-date flex flex-col justify-between relative">


          <div *ngIf="programDetails === null" class="skeleton-card skeleton-img"></div>
          <div class="relative">
            <div *ngIf="programDetails?.date?.month_day" class="date-card">
              <div class="wrapper flex flex-col items-center justify-center">
                <span class="text-weight-800 text-event-badge-medium  date-date">{{ programDetails?.date?.year }}</span>
                <span
                  class="text-weight-800 text-event-badge-large date-day">{{ programDetails?.date?.month_day }}</span>
  
              </div>
            </div>
            <picture class="absolute img-concert">

              <source [srcset]="programDetails?.image?.['600']?.jpg" media="(min-width: 400px)" type="image/jpg" />
              <source [srcset]="programDetails?.image?.['600']?.webp" media="(min-width: 400px)" type="image/webp" />
              <source [srcset]="programDetails?.image?.['350']?.jpg" media="(min-width: 360px)" type="image/jpg" />
              <source [srcset]="programDetails?.image?.['350']?.webp" media="(min-width: 360px)" type="image/webp" />
              <source [srcset]="programDetails?.image?.['300']?.webp" type="image/webp" />

              <img class="absolute img-concert" style="width: 100%" [src]="programDetails?.image?.['350']?.jpg"
                [alt]="programDetails?.title">
            </picture>
          </div>
        </div>
      </section>
    </div>
    <!-- DESKTOP -->
  </mvm-container>
</div>

<div class="background-white">
  <mvm-container>
    <div class="time-from" [ngClass]="{'padding-left-0': !programDetails?.date?.year}">
      <div class="flex">
        <p class="flex gap-10px items-center"><span class="text-default">{{'home.program.gateOpen' | translate}}</span>
          <span class="text-title">{{ programDetails?.date?.gate_opening }}</span></p>
        <p class="flex gap-10px items-center"><span class="text-default">{{'home.program.start' | translate}}</span>
          <span class="text-title">{{ programDetails?.date?.event_start }}</span></p>
      </div>
    </div>
    <section class="description">
      <div *ngIf="programDetails === null" class="skeleton-card skeleton-description"></div>
      <div id="extra" *ngIf=" programDetails?.extra">
        <p class="title">Még valami!</p>
        <p class="bold">
          {{ programDetails?.extra  }}
        </p>
      </div>
      <button (click)="scrollToTickets()"
      class="btn-yellow no-margin mobile only-mobile" id="scroll-to-tickets-mobile">{{ !programDetails?.can_be_purchased ? ('home.program.ticketsFuture' | translate)  : ('home.program.ticketTypes' | translate)}}</button>

      <h3 *ngIf="programDetails?.lead" class="text-highlighted">{{ programDetails?.lead }}</h3>
      <p *ngFor="let p of digestedDescription">{{ p }}</p>

    </section>
  </mvm-container>
</div>












<mvm-container [type]="ContainerTypes.ProgramDetails" *ngIf="false">

  <div class="mobile only-mobile from-tablet">
    <div *ngIf="programDetails === null" class="skeleton-card skeleton-title"></div>
    <h1 class="performancer text-white text-super-h1">{{ programDetails?.title }}</h1>
    <div class="cover-container">

      <div *ngIf="programDetails === null" class="skeleton-card skeleton-img-mobile"></div>
      <picture class="program-cover" *ngIf="programDetails">
        <source [srcset]="programDetails?.image?.['600']?.jpg" media="(min-width: 400px)" type="image/jpg" />
        <source [srcset]="programDetails?.image?.['600']?.webp" media="(min-width: 400px)" type="image/webp" />
        <source [srcset]="programDetails?.image?.['350']?.jpg" media="(min-width: 360px)" type="image/jpg" />
        <source [srcset]="programDetails?.image?.['350']?.webp" media="(min-width: 360px)" type="image/webp" />
        <source [srcset]="programDetails?.image?.['300']?.webp" type="image/webp" />

        <img style="width: 100%" class="program-cover" [src]="programDetails?.image?.['350']?.jpg"
          [alt]="programDetails?.title">
      </picture>


      <span *ngIf="programDetails"
        class="relative tag-yellow tag-position">{{ !programDetails?.is_seasonal ? programDetails?.label : ('cardProgram.season_ticket' | translate) }}</span>
      <div class="times flex" *ngIf="!programDetails?.is_seasonal">
        <div *ngIf="programDetails?.date?.month_day" class="date-card">
          <div class="wrapper flex flex-col items-center justify-center">
            <span class="date-date">{{ programDetails?.date?.month_day }}</span>

            <span class="date-day">{{ programDetails?.date?.year }}</span>
          </div>
        </div>
        <div class="time-from" *ngIf="!programDetails?.is_seasonal">
          <div class="time-container">
            <div class="flex w-full gap-20px justify-between">
              <p *ngIf="programDetails?.date?.gate_opening" class="text-default text-black-white">
                {{'home.program.gateOpen' | translate}}</p>
              <p *ngIf="programDetails?.date?.gate_opening" class="text-title text-black-white">
                {{ programDetails?.date?.gate_opening }}</p>
            </div>
            <div class="flex w-full gap-20px justify-between">
              <p *ngIf="programDetails?.date?.event_start" class="text-default text-black-white">
                {{'home.program.start' | translate}}</p>
              <p *ngIf="programDetails?.date?.event_start" class="text-title text-black-white">
                {{ programDetails?.date?.event_start }}</p>
            </div>
          </div>
        </div>
      </div>
      <div id="extra" class="mobile" *ngIf="programDetails?.extra">
        <p class="title">Még valami!</p>
        <p class="bold">
          {{ programDetails?.extra ?? 'Ingyen sör, de ha valami hosszabbról van szó, akkor az is elfér ilyen szépen, egymás alatt két sorban.' }}
        </p>
      </div>
      <button (click)="scrollToTickets()"
        class="btn-yellow">{{ !programDetails?.can_be_purchased ? ('home.program.ticketsFuture' | translate)  : ('home.program.ticketTypes' | translate)}}</button>
    </div>
    <div *ngIf="programDetails === null" class="skeleton-card skeleton-description"></div>
    <h3 *ngIf="programDetails?.lead" class="text-highlighted">{{ programDetails?.lead }}</h3>
    <p *ngFor="let p of digestedDescription">{{ p }}</p>
    <div style="padding-bottom: 20px"></div>
  </div>

  <div class="only-desktop from-tablet">
    <section class="hero flex justify-between gap-10px">
      <div class="title-and-tickets">
        <div *ngIf="programDetails === null" class="skeleton-card skeleton-title-desktop"></div>
        <h1 class="performancer text-white text-super-h1">{{ programDetails?.title }}</h1>

        <button (click)="scrollToTickets()"
          class="btn-yellow no-margin">{{ !programDetails?.can_be_purchased ? ('home.program.ticketsFuture' | translate)  : ('home.program.ticketTypes' | translate)}}</button>

        <ul class="services">
          <li>{{ 'home.program.tickets' | translate }}</li>
          <li>{{ 'home.program.catering' | translate }}</li>
          <li>{{ 'home.program.vip-entry' | translate }}</li>
          <li>{{ 'home.program.vip-parking' | translate }}</li>
          <li>{{ 'home.program.accomodation' | translate }}</li>
          <li>{{ 'home.program.service' | translate }}</li>
        </ul>

        <div class="time-from">
          <table>
            <tr>
              <th *ngIf="programDetails?.date?.gate_opening" class="text-default">
                {{'home.program.gateOpen' | translate}}</th>
              <td *ngIf="programDetails?.date?.gate_opening" class="text-title">{{ programDetails?.date?.gate_opening }}
              </td>
              <th class="text-default" *ngIf="programDetails?.date?.event_start">{{'home.program.start' | translate}}
              </th>
              <td class="text-title" *ngIf="programDetails?.date?.event_start">{{ programDetails?.date?.event_start }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="image-and-date flex flex-col justify-between relative">


        <div *ngIf="programDetails === null" class="skeleton-card skeleton-img"></div>
        <picture class="absolute img-concert">

          <source [srcset]="programDetails?.image?.['600']?.jpg" media="(min-width: 400px)" type="image/jpg" />
          <source [srcset]="programDetails?.image?.['600']?.webp" media="(min-width: 400px)" type="image/webp" />
          <source [srcset]="programDetails?.image?.['350']?.jpg" media="(min-width: 360px)" type="image/jpg" />
          <source [srcset]="programDetails?.image?.['350']?.webp" media="(min-width: 360px)" type="image/webp" />
          <source [srcset]="programDetails?.image?.['300']?.webp" type="image/webp" />

          <img class="absolute img-concert" style="width: 100%" [src]="programDetails?.image?.['350']?.jpg"
            [alt]="programDetails?.title">
        </picture>
        <div>
        </div>
        <div *ngIf="programDetails?.date?.month_day" class="date-card">
          <div class="wrapper flex flex-col items-center justify-center">
            <span class="text-weight-800 text-event-badge-medium  date-date">{{ programDetails?.date?.year  }}</span>
            <span class="text-weight-800 text-event-badge-large date-day">{{ programDetails?.date?.month_day  }}</span>

          </div>
        </div>
      </div>
    </section>
    <section class="description">
      <div *ngIf="programDetails === null" class="skeleton-card skeleton-description"></div>
      <div id="extra" *ngIf=" programDetails?.extra">
        <p class="title">Még valami!</p>
        <p class="bold">
          {{ programDetails?.extra }}
        </p>
      </div>
      <h3 *ngIf="programDetails?.lead" class="text-highlighted">{{ programDetails?.lead }}</h3>
      <p *ngFor="let p of digestedDescription">{{ p }}</p>

    </section>
  </div>
</mvm-container>