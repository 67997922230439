import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {growInOut} from "../../../animations/growInOut";
import {AngularSvgIconModule} from "angular-svg-icon";
import {TranslateModule} from "@ngx-translate/core";
import {DashboardEvent} from "../../../interfaces/dashboard/dashboard.types";
import {SendTicketModalService} from "../../../services/sendTicketService/send-ticket-modal.service";
import {BuyParkingModalService} from "../../../services/buyParkingTicketModalService/buy-parking-modal.service";
import { SingleEventComponent } from './_partials/single-event/single-event.component';

@Component({
  selector: 'mvm-my-ticket',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, TranslateModule, SingleEventComponent],
  templateUrl: './my-ticket.component.html',
  styleUrls: ['./my-ticket.component.scss'],
  animations: growInOut
})

export class MyTicketComponent {

  @Input() isRequesting: boolean = true

  @Input() events: DashboardEvent[] = []


  constructor(private sendTicketService: SendTicketModalService, private buyParkingModalService: BuyParkingModalService) {
  }

  borderImageSrc(type: "gold" | "platinum" | "skybox" = "skybox"): string {
    switch (type) {
      case 'skybox':
        return '/assets/images/png/ticket/ticket-border-skybox.png'
      case 'platinum':
        return '/assets/images/png/ticket/ticket-border-vip-platinum.png'
      case 'gold':
        return '/assets/images/png/ticket/ticket-border-vip-gold.png'
    }
  }

  sendTicket(ticket_id ?: string) {
    if (ticket_id) {
      this.sendTicketService.openModal(ticket_id, "simple")
    }
  }

  downloadAllTickets(download_all?: string) {
    if (download_all) {
      window.open(download_all, "_blank");
    }
  }

  sendParkingTicket(id ?: string) {
    if (id) {
      this.sendTicketService.openModal(id, "parking")
    }
  }

  buyParkingTicket(slug: string) {
    this.buyParkingModalService.openModal(slug)
  }

  getEventTicketNumber(event: DashboardEvent): number {
    let n = event.parking_tickets.length ?? 0
    event.tickets.forEach(t => {
      n += t.number_of_tickets
    })
    return n
  }
}
