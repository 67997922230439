<mvm-core class="home" [inputHasNavbar]="false">
    <div id="parking-cover-holder">
        <mvm-container>
            <div id="parking-cover">
                <svg-icon id="parking-logo" name="logo"></svg-icon>
                <h1 id="parking-title">{{ "parking-landing.parking.title" | translate}}</h1>
                <a id="buy-parking-ticket" [routerLink]="['/parking'] | localize" [fragment]="!isMobile() ? 'tickets' : 'tickets-mobile'"
                    class="btn-yellow">{{ "parking-landing.parking.buy-parking-ticket" | translate }}</a>
            </div>
        </mvm-container>
        <!--<img id="parking-image" src="/assets/images/png/parking/green.png">-->
    </div>
    <div class="bg-white">
        <mvm-container>
            <p class="subtitle margin padding" id="first-sentence">{{ "parking-landing.parking.text1" | translate }}</p>
            <div id="upper-content-holder">

                
                <div id="cards-holder-for-desktop">
                    <mvm-parking-card id="tickets"></mvm-parking-card>

                    <mvm-upcoming-events></mvm-upcoming-events>
                </div>
                <div>

                    <h2 id="parking-title-2">{{ "parking-landing.parking.question1" | translate }}</h2>
                    <p class="subtitle">{{ "parking-landing.parking.text2" | translate }}</p>

                    <div id="cards-holder-for-mobile" >
                        <mvm-parking-card id="tickets-mobile"></mvm-parking-card>

                        <mvm-upcoming-events></mvm-upcoming-events>
                    </div>

                    <p class="subtitle" id="easy-payment">{{ "parking-landing.parking.text3" | translate }}</p>
                    <img id="simplepay" src="/assets/images/png/simplepay.png" alt="SimplePay" />
                </div>
            </div>
        </mvm-container>
        <div id="parking-map-holder">
            <mvm-container>
                <div id="top-holder">
                    <h2>{{ "parking-landing.parking.title2" | translate }}</h2>
                    <p class="subtitle">{{ "parking-landing.parking.text4" | translate }}</p>
                </div>
            </mvm-container>
            <div id="maps-holder">
                <mvm-container class="desktop">
                    <mvm-maps></mvm-maps>
                </mvm-container>
                <mvm-maps class="mobile"></mvm-maps>
            </div>
        </div>
        <mvm-container>
            <div id="disabled-holder">
                <p class="subtitle">{{ "parking-landing.parking.text5" | translate }}</p>

                <div id="wheelchair">
                    <svg-icon name="wheelchair"></svg-icon>
                    <p class="subtitle bold">{{ "parking-landing.parking.text6" | translate }}</p>
                </div>
            </div>
        </mvm-container>
    </div>
</mvm-core>