import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContainerTypes } from 'src/app/shared/components/container/container.component';
import { HandleErrorAndSuccess } from 'src/app/shared/interfaces/common/handle-error-and-success';
import { SuccessModalService } from 'src/app/shared/services/SuccessService/success-modal.service';
import { VipContactApiService } from 'src/app/shared/services/apiServices/vipContactApiService/vip-contact-api.service';

@Component({
  selector: 'mvm-vip',
  templateUrl: './vip.component.html',
  styleUrls: ['./vip.component.scss']
})
export class VipComponent implements HandleErrorAndSuccess {
  ContainerTypes = ContainerTypes

  public contactForm!: FormGroup
  public isRequesting: boolean = false
  public done: boolean = false

  constructor(
    private _fb: FormBuilder,
    private vipContactApiService: VipContactApiService,
    private successService: SuccessModalService,
    ) {
    this.contactForm = this._fb.group({
      emailAddress: ['', [
        Validators.required, Validators.email
      ]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      termsAndConds: [false, Validators.requiredTrue],
      skybox: [false],
      platinum: [false],
      gold: [false],
    })

  }

  public onSubmit(): void {
    /* mark all inputs */
    this._markAllInputs()

    /* if form is invalid */
    if (!this.contactForm.valid || this._validateServices()) { return; }

    /* set isrequesting */
    this._requestStarted()

    /* sent data */
    this.vipContactApiService.contact(this.contactForm.value, this)
  }

  /* handles success subscription request */
  public handleSuccessResponse(): void {
    this._requestStopped()
    this.done = true
    this.contactForm.reset()
    this.successService.showSuccessTranslate("dynamic.text2")
  }

  /* handles failed subscription request */
  public handleErrorResponse(err: string): void {
    this._requestStopped()
  }

  /* returns validation errors */
  public getError(attribute: string, validationRule: string) {
    if(attribute == 'service') return this._validateServices()
    return this.contactForm.get(attribute)?.touched && this.contactForm.get(attribute)?.errors?.[validationRule]
  }

  private _getValue(attribute:string) {
    return this.contactForm.get(attribute)?.value
  }

  /* validated services */
  private _validateServices(): boolean {
    return this.contactForm.get('skybox')?.touched && !(this._getValue('skybox') || this._getValue('platinum')) ? true : false
  }

  /* requesting started */
  private _requestStarted(): void {
    this.isRequesting = true
  }

  /* requesting finihsed */
  accepted: boolean = false;
  acceptedGold: boolean = false;
  acceptedPlat: boolean = false;
  acceptedSky: boolean = false;
  private _requestStopped(): void {
    this.isRequesting = false
  }

  private _markAllInputs() {
    Object.keys(this.contactForm.controls).map((input) => {
      this.contactForm.get(input)?.markAsTouched()
    })
  }
}
