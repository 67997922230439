import { createAction, props } from '@ngrx/store';
import {CartState} from "../states/cart.state";

export const cartLoaded = createAction(
    '[Checkout] Cart data loaded',
    props<CartState>()
)

export const cartOrderSuccess = createAction(
  '[Checkout] Order successful - clear cart'
)
