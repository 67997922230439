import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeSearchbarComponent} from './components/home/home-searchbar/home-searchbar.component';
import {HomeComponent} from './pages/home/home.component';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './pages/auth/login/login.component';
import {RegisterComponent} from './pages/auth/register/register.component';
import {ForgotPasswordComponent} from './pages/auth/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './pages/auth/reset-password/reset-password.component';
import {SocialLoginButtonsComponent} from './components/auth/social-login-buttons/social-login-buttons.component';
import {CheckboxComponent} from '../shared/components/input/checkbox/checkbox.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ButtonSaveComponent} from "../shared/components/buttons/button-save/button-save.component";
import {MobileNavMenuComponent} from "../shared/components/mobile-nav-menu/mobile-nav-menu.component";
import {CardSimpleTicketsComponent} from "../shared/components/cards/card-simple-tickets/card-simple-tickets.component";
import {NavbarComponent} from './components/navbar/navbar.component';
import {
  ColoredBorderButtonComponent
} from '../shared/components/buttons/colored-border-button/colored-border-button.component';
import {FooterComponent} from './components/footer/footer.component';
import {CardProgramComponent} from '../shared/components/cards/card-program/card-program.component';
import {ProgramDetailsComponent} from './pages/program-details/program-details.component';
import {FoodOrderProgramsComponent} from './pages/food-order-programs/food-order-programs.component';
import {FoodOrderComponent} from './pages/food-order/food-order.component';
import {CardFoodComponent} from '../shared/components/cards/card-food/card-food.component';
import {ContainerComponent} from '../shared/components/container/container.component';
import {HomeProgramDetailsComponent} from './components/home/home-program-details/home-program-details.component';
import {TicketSelectorCardComponent} from './components/home/ticket-selector-card/ticket-selector-card.component';
import {
  DropdownTicketinfoComponent
} from '../shared/components/dropdowns/dropdown-ticketinfo/dropdown-ticketinfo.component';
import {HowToApproachComponent} from '../shared/components/how-to-approach/how-to-approach.component';
import {ChooserQuantityComponent} from "../shared/components/input/chooser-quantity/chooser-quantity.component";
import {ChooserParkingComponent} from "../shared/components/cards/parking/chooser-parking/chooser-parking.component";
import {MapsModule} from '../maps/maps.module';
import {GreyFrameComponent} from "../shared/components/grey-frame/grey-frame.component";
import {MyTicketComponent} from "../shared/components/cards/my-ticket/my-ticket.component";
import {AngularSvgIconModule} from "angular-svg-icon";
import {CardFoodAdComponent} from "../shared/components/cards/card-food-ad/card-food-ad.component";
import {PartnersComponent} from "../shared/components/partners/partners.component";
import {MoreProgramsCardComponent} from "../shared/components/cards/more-programs-card/more-programs-card.component";
import {HomeVipComponent} from './components/home/home-vip/home-vip.component';
import {CartComponent} from './pages/cart/cart.component';
import {CartitemCardComponent} from "../shared/components/cards/cartitem-card/cartitem-card.component";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {NewsletterComponent} from './components/home/newsletter/newsletter.component';
import {HomeDetailsComponent} from "./components/home/home-details/home-details.component";
import {VipComponent} from './pages/vip/vip.component';
import {ProgramsComponent} from "./pages/programs/programs.component";
import {CoreComponent} from "./layouts/core-layout/core.component";
import {TicketModifyPageComponent} from "./pages/ticket-modify-page/ticket-modify-page.component";
import {CartLayoutComponent} from "./layouts/cart-layout/cart-layout.component";
import {CheckoutCartComponent} from "./pages/checkout/checkout-cart/checkout-cart.component";
import {EditProfileComponent} from './pages/edit-profile/edit-profile.component';
import {DropdownChooserComponent} from "../shared/components/dropdowns/dropdown-chooser/dropdown-chooser.component";
import {
  CheckoutBillingAndPaymentComponent
} from './pages/checkout/checkout-billing-and-payment/checkout-billing-and-payment.component';
import {CheckoutLayoutComponent} from "./layouts/checkout-layout/checkout-layout.component";
import {ChooserPayComponent} from "../shared/components/input/chooser-pay/chooser-pay.component";
import {CheckoutDoneComponent} from "./pages/checkout/checkout-done/checkout-done.component";
import {AuthLayoutComponent} from "./layouts/auth-layout/auth-layout.component";
import {TranslateModule} from "@ngx-translate/core";
import {SharedModule} from "../shared/shared.module";
import {ErrorComponent} from '../shared/components/input/error/error.component';
import {LocalizeRouterModule} from "@gilsdav/ngx-translate-router";
import {AuthGuard} from "../shared/guards/auth.guard";
import {ActivateComponent} from './pages/activate/activate.component';
import {SeatMapComponent} from "../shared/components/seat-map/seat-map.component";
import {GoogleSigninButtonModule} from "@abacritt/angularx-social-login";
import {EmptyCartCardComponent} from "../shared/components/empty-cart-card/empty-cart-card.component";
import {
  ResendActivationTokenComponent
} from '../shared/components/resend-activation-token/resend-activation-token.component';
import {ImpressumComponent} from "./pages/impressum/impressum.component";
import { ParkingComponent } from './pages/parking/parking.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

export const coreRoutes: Routes = [
  {
    path: '', 
    component: HomeComponent, 
    data: {animation: 'home', title: 'pageTitle.home', hasNavbar: false, discriminantPathKey: "home",}
  },
  {
    path: 'login',
    canActivate: [AuthGuard],
    component: LoginComponent,
    data: {animation: 'login', title: 'pageTitle.login'}
  },
  {
    path: 'register',
    canActivate: [AuthGuard],
    component: RegisterComponent,
    data: {
      animation: 'register',
      title: 'pageTitle.register'
    }
  },
  {
    path: 'forgot-password',
    canActivate: [AuthGuard],
    component: ForgotPasswordComponent,
    data: {
      animation: 'forgotPassword',
      title: 'pageTitle.forgot'
    }
  },
  {
    path: 'reset-password',
    canActivate: [AuthGuard],
    component: ResetPasswordComponent,
    data: {
      animation: 'resetPassword',
      title: 'pageTitle.reset'
    }
  },
  {path: 'activate', component: ActivateComponent, data: {animation: 'activate', title: 'pageTitle.activate'}},
  {path: 'event/:slug', component: ProgramDetailsComponent, data: {animation: 'event', title: 'pageTitle.event'}},
  {path: 'events', component: ProgramsComponent, data: {title: 'pageTitle.events'}},
  {path: 'impressum', component: ImpressumComponent, data: {title: 'pageTitle.impressum'}},
  {path: 'food', component: FoodOrderProgramsComponent, data: {title: 'pageTitle.food'}},
  {path: 'food-order', component: FoodOrderComponent, data: {title: 'pageTitle.order'}},
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {animation: 'dashboard', title: 'pageTitle.dashboard'}
  },
  {path: 'vip', component: VipComponent, data: {animation: 'vip', title: 'pageTitle.vip'}},
  {path: 'cart', component: CartComponent, data: {animation: 'cart', title: 'pageTitle.cart'}},
  {
    path: 'ticket-modify',
    component: TicketModifyPageComponent,
    data: {
      animation: 'ticketModify',
      title: 'pageTitle.modify'
    }
  },
  {path: 'edit-profile', component: EditProfileComponent, data: {animation: 'editProfile', title: 'pageTitle.edit'}},
  {
    path: 'checkout-cart',
    canActivate: [AuthGuard],
    component: CheckoutCartComponent,
    data: {
      animation: 'checkoutCart',
      title: 'pageTitle.checkoutCart'
    }
  },
  {
    path: 'checkout-billing-payment',
    canActivate: [AuthGuard],
    component: CheckoutBillingAndPaymentComponent,
    data: {
      animation: 'checkoutBillingPayment',
      title: 'pageTitle.checkoutBilling'
    }
  },
  {
    path: 'checkout-done/:orderId',
    canActivate: [AuthGuard],
    component: CheckoutDoneComponent,
    data: {
      animation: 'checkoutDone',
      title: 'pageTitle.checkoutDone'
    }
  },
  {
    path: 'parking',
    canActivate: [AuthGuard],
    component: ParkingComponent,
    data: {
      animation: 'parking',
      title: 'pageTitle.parking'
    }
  }
];

@NgModule({
  declarations: [
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SocialLoginButtonsComponent,
    ProgramDetailsComponent,
    FoodOrderComponent,
    FoodOrderProgramsComponent,
    HomeProgramDetailsComponent,
    TicketSelectorCardComponent,
    CartComponent,
    TicketSelectorCardComponent,
    HomeVipComponent,
    NewsletterComponent,
    VipComponent,
    EditProfileComponent,
    CheckoutBillingAndPaymentComponent,
    ActivateComponent,
  ],
  exports: [
    FoodOrderComponent,
    FoodOrderProgramsComponent,
    HomeDetailsComponent,
  ],
  providers: [
    provideNgxMask(),
  ],
  imports: [
    /*TranslateModule.forChild({
      extend: true
    }),*/
    RouterModule.forChild(coreRoutes),
    LocalizeRouterModule.forChild(coreRoutes),
    CheckboxComponent,
    CommonModule,
    ReactiveFormsModule,
    ButtonSaveComponent,
    ErrorComponent,
    MobileNavMenuComponent,
    CardSimpleTicketsComponent,
    ColoredBorderButtonComponent,
    CardProgramComponent,
    CardFoodComponent,
    ContainerComponent,
    DropdownTicketinfoComponent,
    HowToApproachComponent,
    ChooserQuantityComponent,
    ChooserParkingComponent,
    MapsModule,
    GreyFrameComponent,
    MyTicketComponent,
    AngularSvgIconModule,
    CardFoodAdComponent,
    PartnersComponent,
    MoreProgramsCardComponent,
    FooterComponent,
    NavbarComponent,
    HomeDetailsComponent,
    HomeSearchbarComponent,
    MoreProgramsCardComponent,
    CartitemCardComponent,
    CoreComponent,
    CartLayoutComponent,
    CartitemCardComponent,
    DropdownChooserComponent,
    CheckoutLayoutComponent,
    ChooserPayComponent,
    TranslateModule,
    ChooserPayComponent,
    AuthLayoutComponent,
    SharedModule,
    SeatMapComponent,
    GoogleSigninButtonModule,
    EmptyCartCardComponent,
    ResendActivationTokenComponent,
    NgxMaskDirective, 
    NgxMaskPipe,
  ]
})
export class CoreModule {

}
