import {Injectable} from '@angular/core';
import {ApiRequestService} from "../apiRequestService/api-request.service";
import {Store} from "@ngrx/store";
import {cartLoaded} from "../../../../store/actions/cart.actions";
import {CartResponse, CartTicketData, CheckoutCart} from "../../../interfaces/checkout-cart/checkout-cart.models";
import { TicketTypes } from 'src/app/shared/enums/ticket-types.enum';
import { HandleErrorAndSuccess } from 'src/app/shared/interfaces/common/handle-error-and-success';
import { ErrorStatusCode } from 'src/app/shared/enums/erros-status-code.enum';


@Injectable({
  providedIn: 'root'
})
export class CartApiService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store
  ) {
  }

  loadCart() {
    if(!localStorage.getItem('cart_id') ) return ;

    this._apiRequestService.getRequest("cart", (res) => {
      this.cartLoadedSuccess(res)
    }, (e) => {
      /* if status code is 999 then delete cart id */
      if(e.error.status_code === ErrorStatusCode.WRONG_OR_EXPIRED_CART) {
        localStorage.removeItem('cart_id')
      }
    }, false, undefined, true)
  }

  addToCart(ticketData: CartTicketData, self: HandleErrorAndSuccess) {
    const a: any = ticketData
    a.userToken = localStorage.getItem('bearer_token')
    this._apiRequestService.postRequest('cart/add', a, (res) => {
      this.cartLoadedSuccess(res)
      self.handleSuccessResponse()
    }, (e) => {
      /* if status code is 999 then delete cart id */
      if(e.error.status_code === ErrorStatusCode.WRONG_OR_EXPIRED_CART) {
          localStorage.removeItem('cart_id')
      }
      self.handleErrorResponse(e)
    }, true, true)
  }

  /*editCartItem(ticketId: string, eventSlug: string, numberOfTicket?: number, seatIds?: string[]) {
    let params: HttpParams = new HttpParams()
    params = params.append('event_slug', eventSlug)
    numberOfTicket ? params = params.append('number_of_tickets', numberOfTicket) : ''
    seatIds ? params = params.append('seat_ids', seatIds.toString()) : ''

    this._apiRequestService.patchRequest(`cart/${ticketId}`, {}, (res) => {
      this.cartLoadedSuccess(res)
    }, undefined, true, params)
  }*/

  deleteSkyboxTicketFromCart(eventSlug: string, seatId: string,  self: HandleErrorAndSuccess) {
    this._apiRequestService.deleteRequest(`cart/${eventSlug}/skybox/${seatId}`, (res) => {
      self.handleSuccessResponse()
      this.cartLoadedSuccess(res)
    }, (e) => {
      self.handleErrorResponse("")
      /* if status code is 999 then delete cart id */
      if(e.error.status_code === ErrorStatusCode.WRONG_OR_EXPIRED_CART) {
        localStorage.removeItem('cart_id')
      }
    }, true, true)
  }

  deleteTicketFromCart(eventSlug: string, seatId: string,  self: HandleErrorAndSuccess) {
    this._apiRequestService.deleteRequest(`cart/${eventSlug}/seat/${seatId}`, (res) => {
      self.handleSuccessResponse()
      this.cartLoadedSuccess(res)
    }, (e) => {
      self.handleErrorResponse("")
        /* if status code is 999 then delete cart id */
      if(e.error.status_code === ErrorStatusCode.WRONG_OR_EXPIRED_CART) {
        localStorage.removeItem('cart_id')
      }
    }, true, true)
  }

  deleteTicketTypeFromCart(eventSlug: string, ticketType: "skybox" | "platinum" | "gold" | string) {
    this._apiRequestService.deleteRequest(`cart/${eventSlug}/${ticketType}`, (res) => {
      this.cartLoadedSuccess(res)
    }, (e) => {
      if(e.error.status_code === ErrorStatusCode.WRONG_OR_EXPIRED_CART) {
        localStorage.removeItem('cart_id')
      }
    }, true)
  }

  private cartLoadedSuccess(res: CartResponse) {
    const cart = res.data
    localStorage.setItem('cart_id', cart.id)
    this._store.dispatch(cartLoaded({cart: cart}))
  }

}
