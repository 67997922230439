import {Component, OnInit} from '@angular/core';
import { ContainerTypes } from 'src/app/shared/components/container/container.component';
import {selectCartCart} from "../../../store/selectors/cart.selector";
import {Store} from "@ngrx/store";
import {CartApiService} from "../../../shared/services/apiServices/cartApiService/cart-api.service";
import { CartItemType } from 'src/app/shared/enums/cartitem-type';

@Component({
  selector: 'mvm-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit{
  ContainerTypes = ContainerTypes

  cart$ = this.store.select(selectCartCart)
  constructor(private store: Store, private cartService: CartApiService) {
    
  }

  ngOnInit(): void {
    this.cartService.loadCart()
  }

  cartItemTypes() {
    return CartItemType
  }
}
