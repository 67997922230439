import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {MobileNavMenuComponent} from './shared/components/mobile-nav-menu/mobile-nav-menu.component';
import {AngularSvgIconModule} from "angular-svg-icon";
import {AngularSvgIconPreloaderModule} from "angular-svg-icon-preloader";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {Store, StoreModule} from '@ngrx/store';
import {homeReducer} from "./store/reducers/home.reducer";
import {authReducer} from "./store/reducers/auth.reducer";
import {initializeAuthFactory} from "./shared/services/app-initializer/auth-initializer.service";
import {detailReducer} from "./store/reducers/detail.reducer";
import {ModalErrorComponent} from "./shared/components/modal-error/modal-error.component";
import {dashboardReducer} from "./store/reducers/dashboard.reducer";
import {TokenService} from "./shared/services/auth-services/token.service";
import {TokenInterceptor} from "./shared/interceptors/token.interceptor";
import {eventsReducer} from "./store/reducers/events.reducer";
import {LocalizeRouterModule} from "@gilsdav/ngx-translate-router";
import {cartReducer} from "./store/reducers/cart.reducer";
import {LanguageHeaderInterceptor} from "./shared/interceptors/language-header.interceptor";
import {CartIdInterceptor} from "./shared/interceptors/cart-id.interceptor";
import {CartApiService} from "./shared/services/apiServices/cartApiService/cart-api.service";
import {modifyTicketReducer} from "./store/reducers/modify-ticket.reducer";
import {orderReducer} from "./store/reducers/order.reducer";
import {ModalSuccessComponent} from './shared/components/modal-success/modal-success.component';
import {initializeCartFactory} from "./shared/services/app-initializer/cart-initializer.service";
import {ModalSendTicketComponent} from "./shared/components/modal-send-ticket/modal-send-ticket.component";
import {
  ModalBuyParkingTicketComponent
} from "./shared/components/modal-buy-parking-ticket/modal-buy-parking-ticket.component";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule
} from "@abacritt/angularx-social-login";
import {CookieBannerComponent} from "./shared/components/cookie-banner/cookie-banner.component";
import { environment } from 'src/environments/environment';
import { SocialRegistrationModalComponent } from './shared/components/social-registration-modal/social-registration-modal.component';
import { parkingReducer } from './store/reducers/parking.reducer';
import { Observable, filter } from 'rxjs';
import { Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      defaultLanguage: 'hu'
    }),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    MobileNavMenuComponent,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    AngularSvgIconPreloaderModule.forRoot({
      configUrl: './assets/json/svgs.json'
    }),
    StoreModule.forRoot({}, {}),
    StoreModule.forFeature('auth', authReducer),
    StoreModule.forFeature('home', homeReducer),
    StoreModule.forFeature('detail', detailReducer),
    StoreModule.forFeature('dashboard', dashboardReducer),
    StoreModule.forFeature('events', eventsReducer),
    StoreModule.forFeature('cart', cartReducer),
    StoreModule.forFeature('modifyTicket', modifyTicketReducer),
    StoreModule.forFeature('order', orderReducer),
    StoreModule.forFeature('parking', parkingReducer),
    ModalErrorComponent,
    ModalSuccessComponent,
    LocalizeRouterModule,
    ModalSendTicketComponent,
    ModalBuyParkingTicketComponent,
    SocialLoginModule,
    CookieBannerComponent,
    SocialRegistrationModalComponent,
    NgxMaskDirective, 
    NgxMaskPipe
  ],
  providers: [
    provideNgxMask(),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleApiKey, //todo add client id,
              {
                oneTapEnabled: false, // default is true
              }
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAppId) //todo add client id
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    {
    provide: APP_INITIALIZER,
    useFactory: initializeAuthFactory,
    deps: [TokenService, HttpClient, Store, CartApiService],
    multi: true
  },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeCartFactory,
      deps: [TokenService, HttpClient, Store, CartApiService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageHeaderInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: CartIdInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    viewportScroller.setOffset([0, 50]);
    router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: any) => {
      if (e.anchor) {
        // anchor navigation
        /* setTimeout is the core line to solve the solution */
        setTimeout(() => {
          viewportScroller.scrollToAnchor(e.anchor);
        },800)
      } else if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition(e.position);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}
