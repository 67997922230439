import {catchError, Observable, Observer, of, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {TokenService} from "../auth-services/token.service";
import {Store} from "@ngrx/store";
import {CartResponse} from "../../interfaces/checkout-cart/checkout-cart.models";
import {cartLoaded} from "../../../store/actions/cart.actions";


export function initializeCartFactory(tokenService: TokenService, client: HttpClient, _store: Store): () => Observable<any> {
  const meObserver: Observer<CartResponse> = {
    next: (res: CartResponse) => {
      const cart = res.data
      localStorage.setItem('cart_id', cart.id)
      _store.dispatch(cartLoaded({cart: cart}))
      meObserver.complete()
    },
    error: (e: any) => {
      localStorage.removeItem('cart_id')
      meObserver.next({} as CartResponse)
    },
    complete: () => {
    }
  }

  const cartId = localStorage.getItem('cart_id')

// If there is no stored cartId, exit init sequence
  if (!cartId) return () => of(true)
  return () => client.get<CartResponse>(`${environment.apiBaseUrl}cart`)
    .pipe(
      tap(meObserver),
    ).pipe(catchError((err, caught) => {
      meObserver.complete()
      return of(true)
    }))
}
