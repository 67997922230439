<div class="card-border">
    <img class="border-image" [src]="borderImageSrc(ticket.type)" alt="Ticket border">
  </div>
  <div class="card-body">

    <div class="flex justify-between">
      <p class="tag-yellow" *ngIf="event.infos.date">{{event.infos.date}}</p>
      <p class="tag-countdown">{{event.infos.day_left}}</p>
    </div>
    <p class="text-ticket-title text-dark">{{event.name}}</p>
    <div class="flex gap-10px">
      <p class="tag-db">{{ticket.label}}</p>
    </div>
  </div>
  <div class="flex justify-between ticket-details cursor-pointer" (click)="isOpen = !isOpen"
       [ngClass]="isOpen ? '' : 'border-radius-bottom'">
    <p class="text-info-bold">{{'details' | translate}}</p>
    <svg-icon class="transition-3" [ngClass]="isOpen ? 'rotate-180' : ''" name="triangle-black"></svg-icon>
  </div>

  <div @growInOut *ngIf="isOpen">
    <div class="ticket-data">
      <p class="uppercase text-dark text-800-10 mb-3px">{{'myTicket.text1' | translate}}</p>
      <div class="flex justify-between w-full">
        <p class="text-400-10 text-dark">{{'myTicket.text2' | translate}}</p>
        <p class="text-400-10 text-dark">{{ event.infos.venue }}</p>
      </div>
      <div class="flex justify-between w-full" *ngIf="event.infos.gate_opening">
        <p class="text-400-10 text-dark">{{'myTicket.text3' | translate}}</p>
        <p class="text-400-10 text-dark">{{ event.infos.gate_opening }}</p>
      </div>
      <div class="flex justify-between w-full" *ngIf="event.infos.start">
        <p class="text-400-10 text-dark">{{'myTicket.text4' | translate}}</p>
        <p class="text-400-10 text-dark">{{ event.infos.start }}</p>
      </div>
    </div>

    <div class="ticket-tickets">
      <div class="w-full flex justify-between mb-4px">
        <p class="text-dark uppercase text-800-10">{{'myTicket.text5' | translate}}</p>
        <p class="text-dark text-400-10">{{'myTicket.text6' | translate}}</p>
      </div>
      <div class="ticket" *ngFor="let item of ticket.items">
        <div class="flex gap-6px givePlace">
          <a [href]="item.url" target="_blank" class="ticket-link"  [attr.aria-label]="item.name">
            <svg-icon name="pdf"></svg-icon>
            <p class="pdfName blue-link-700">{{item.name}}</p>
        </a>
        </div>
        <a class="tag-yellow cursor-pointer" [href]="item.url" target="_blank">
          <img src="/assets/images/png/download.png" width="10" height="10"/>
        </a>
        <a class="tag-yellow cursor-pointer" (click)="sendTicket(item.id)">
          <img src="/assets/images/png/send.png" width="10" height="10"/>
        </a>
      </div>
      <p class="blue-link-600 mt-12px cursor-pointer"
         (click)="downloadAllTickets(ticket.download_all)">{{'myTicket.text7' | translate}}</p>
    </div>

    <div class="ticket-parkingTicket">
      <div class="w-full flex justify-between">
        <p class="text-white uppercase text-800-10">{{'myTicket.text8' | translate}}</p>
      </div>

      <button class="btn-dark w-full mt-4px btn-plus"
              (click)="buyParkingTicket(event.slug)">{{'myTicket.text10' | translate}}</button>
    </div>

  </div>
