import { Injectable } from '@angular/core';
import { Body as NewsletterBodyInterface } from 'src/app/shared/interfaces/api-services/newsletter/body';
import { HandleErrorAndSuccess } from 'src/app/shared/interfaces/common/handle-error-and-success';
import { ApiRequestService } from '../apiRequestService/api-request.service';

@Injectable({
  providedIn: 'root'
})
export class NewsletterApiService {

  constructor(
    private _apiRequestService: ApiRequestService,
  ) { }

  public subscribeToNewsletter(newsletterData: NewsletterBodyInterface, self: HandleErrorAndSuccess ) {

    this._apiRequestService.postRequest('newsletter', 
      newsletterData , 
      (res) => { self.handleSuccessResponse() },  
      (err) => { self.handleErrorResponse(err) },
      true
    )
  } 
}
