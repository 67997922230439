<mvm-container>
  <div class="flex flex-col items-center gap-90px container-61-31" id="content-holder">

    <svg-icon name="mvm" [svgStyle]="{'width.px':'190'}" [stretch]="true"></svg-icon>

    <mvm-card-food class="w-full"></mvm-card-food>

    <button class="btn-text-yellow" [routerLink]="['/food-order'] | localize">{{'back' | translate}}</button>
  </div>
</mvm-container>
