import {Component, OnDestroy} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CheckoutLayoutComponent} from "../../../layouts/checkout-layout/checkout-layout.component";
import {TransactionResponse} from "../../../../shared/model/TransactionResponse";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {LocalizeRouterModule} from "@gilsdav/ngx-translate-router";
import { CheckoutApiService } from 'src/app/shared/services/apiServices/checkoutApiService/checkout-api.service';
import { Store } from '@ngrx/store';
import { selectOrderStatus } from 'src/app/store/selectors/order.selector';
import { Subject, takeUntil } from 'rxjs';
import { OrderStatus } from 'src/app/shared/enums/order-status.enum';
import {orderCleared} from "../../../../store/actions/order.actions";
import { HelperService } from 'src/app/utils/helper.service';
import { LoaderComponent } from 'src/app/core/components/misc/loader/loader.component';

@Component({
  selector: 'mvm-checkout-done',
  standalone: true,
  imports: [CommonModule, CheckoutLayoutComponent, RouterLink, TranslateModule, LocalizeRouterModule, LoaderComponent],
  templateUrl: './checkout-done.component.html',
  styleUrls: ['./checkout-done.component.scss']
})
export class CheckoutDoneComponent implements OnDestroy {
  private orderId: string
  private interval: any
  public order$ = this.store.select(selectOrderStatus)
  public destroy = new Subject<void>()
  public status: OrderStatus = OrderStatus.PENDING
  public requesting: boolean = true
  
  constructor(
    private checkoutApiService: CheckoutApiService,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private translateService: TranslateService,
    private _helperService: HelperService,
  ) {
    this.orderId = this.activatedRoute.snapshot.params['orderId']
  }

  ngOnInit() {
    this._setOrderSubscription()
    this._getOrderData()

    this.interval = setInterval(() => {
      this._getOrderData()
    }, 10000);
  }

  ngOnDestroy() {
    this.store.dispatch(orderCleared())
    this.destroy.next();
    this.destroy.complete();
    this._stopInterval()
  }

  _stopInterval() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  private _setOrderSubscription() {
    this.order$.pipe(takeUntil(this.destroy)).subscribe(res => {

      switch(res?.data.response_code) {
        case 'SUCCESS' : this.status = OrderStatus.SUCCESS; break;
        case 'FINISHED' : this.status = OrderStatus.SUCCESS; break;
        case 'CANCEL' : this.status = OrderStatus.ERROR; break;
        case 'TIMEOUT' : this.status = OrderStatus.ERROR; break;
        case 'REVERSED' : this.status = OrderStatus.ERROR; break;
        case 'FAIL' : this.status = OrderStatus.ERROR; break;
        default: this.status = OrderStatus.PENDING;break;
      }

      if(this.status == OrderStatus.SUCCESS) {
        localStorage.removeItem('cart_id')
      }

      if(res?.data.finished || res?.data.finished === null) {
        this._stopInterval()
        this.closeWindow()
        this.requesting = false
      }
    })
  }

  private closeWindow() {
    /* if not webview then dont do anything*/
    if(!this._helperService.isWebView()) {
      return ;
    }

    window.setTimeout(() => {
      window.self.close(); 
    }, 3000);
  }

  get isOrderSuccess() {
    return [OrderStatus.SUCCESS].includes(this.status)
  }

  get isOrderFailed() {
    return [OrderStatus.ERROR].includes(this.status)
  }

  private _getOrderData() {
    this.checkoutApiService.getOrderStatus(this.orderId)
  }

  get title() {
    if(this.status == OrderStatus.SUCCESS) {
      return this.translateService.instant("checkoutDone.success")
    } else if(this.status == OrderStatus.ERROR) {
      return this.translateService.instant("checkoutDone.error")
    } else if(this.status = OrderStatus.RESERVED)  {
      return this.translateService.instant("checkoutDone.thanks")
    }
    else {
      return this.translateService.instant("checkoutDone.pending")
    }
  }

  get needText() {
    return this.status != OrderStatus.RESERVED
  }


}
