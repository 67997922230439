import { NgClass } from '@angular/common';
import {Component, Input, OnInit, forwardRef} from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

@Component({
  standalone: true,
  selector: 'mvm-checkbox',
  templateUrl: './checkbox.component.html',
  imports: [
    NgClass,
    ReactiveFormsModule
  ],
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {

  @Input() name: string = ""
  @Input() rounded: boolean = true
  @Input() color: string = "white"
  @Input() style: string = 'light'

  public checked: boolean = false
  public disabled: boolean = false

  public onControlClicked($event: MouseEvent) {
    this.checked = !this.checked
    this._onChange(this.checked)
    $event.stopPropagation()
  }

  ngOnInit() {
  }

  writeValue(isChecked: boolean): void {
    this.checked = isChecked
  }

  private _onChange = (checked: boolean | null) => {};
  public registerOnChange(fn: (checked: boolean | null) => void): void {
    this._onChange = fn;
  }

  public _onTouched = () => {};
  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

}
