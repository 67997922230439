import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { growInOut } from 'src/app/shared/animations/growInOut';
import { Ticket } from 'src/app/shared/interfaces/dashboard/dashboard.types';
import { DashboardEvent } from 'src/app/shared/interfaces/dashboard/dashboard.types';
import { BuyParkingModalService } from 'src/app/shared/services/buyParkingTicketModalService/buy-parking-modal.service';
import { SendTicketModalService } from 'src/app/shared/services/sendTicketService/send-ticket-modal.service';

@Component({
  selector: 'mvm-single-ticket-type',
  templateUrl: './single-ticket-type.component.html',
  styleUrls: ['./single-ticket-type.component.scss'],
  standalone: true,
  animations: growInOut,
  imports: [CommonModule, AngularSvgIconModule, TranslateModule],
})
export class SingleTicketTypeComponent {
  @Input() event: DashboardEvent = {} as DashboardEvent
  @Input() ticket: Ticket = {} as Ticket
  public isOpen: boolean = false

  constructor(
    private sendTicketService: SendTicketModalService,
    private buyParkingModalService: BuyParkingModalService
) {
  }

  borderImageSrc(type: "gold" | "platinum" | "skybox" = "skybox"): string {
    switch (type) {
      case 'skybox':
        return '/assets/images/png/ticket/ticket-border-skybox.png'
      case 'platinum':
        return '/assets/images/png/ticket/ticket-border-vip-platinum.png'
      case 'gold':
        return '/assets/images/png/ticket/ticket-border-vip-gold.png'
    }
  }

  sendTicket(ticket_id ?: string) {
    if (ticket_id) {
      this.sendTicketService.openModal(ticket_id, "simple")
    }
  }

  downloadAllTickets(download_all?: string) {
    if (download_all) {
      window.open(download_all, "_blank");
    }
  }

  buyParkingTicket(slug: string) {
    this.buyParkingModalService.openModal(slug)
  }
}
