import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TicketTypes} from 'src/app/shared/enums/ticket-types.enum';
import {fadeInOut} from "../../../../shared/animations/fadeInOut";
import {slideDownFadeInOut, slideFadeInOut} from "../../../../shared/animations/slideFadeInOut";
import {CartApiService} from "../../../../shared/services/apiServices/cartApiService/cart-api.service";
import {CartTicketData} from "../../../../shared/interfaces/checkout-cart/checkout-cart.models";
import {Ticket} from "../../../../shared/services/apiServices/detailsPageService/details-page.service";
import {HandleErrorAndSuccess} from 'src/app/shared/interfaces/common/handle-error-and-success';
import {Router} from "@angular/router";
import {LocalizeRouterService} from "@gilsdav/ngx-translate-router";
import {ScrollingCardService} from "../../../services/scrolling/scrolling-card.service";
import { HostListener } from '@angular/core';
import {SuccessModalService} from "../../../../shared/services/SuccessService/success-modal.service";

@Component({
  selector: 'mvm-ticket-selector-card',
  templateUrl: './ticket-selector-card.component.html',
  styleUrls: ['./ticket-selector-card.component.scss'],
  animations: [fadeInOut, slideFadeInOut, slideDownFadeInOut]
})
export class TicketSelectorCardComponent implements OnInit, HandleErrorAndSuccess {

  TicketTypes = TicketTypes
  @Input() ticket!: Ticket
  @Input() event_slug!: string
  @Input() type: TicketTypes = TicketTypes.Skybox
  @Input() price: number = 49999
  @Input() price_text: string = ""
  @Input() quantity: number = 1;
  @Input() scrollable?: boolean = false
  @Output() showDesktopMap: EventEmitter<null> = new EventEmitter();
  scrolling: boolean = false

  isOnMobile:boolean = false

  parkingQuantitySelected?: number = 0
  parkingSelected = false

  autoSeatChooser: boolean = true;
  state: 'default' | 'selected' | 'seat-choosing' | 'seat-selected' | 'parking' = 'default';
  mapType: 'simple' | 'skybox' = 'simple';

  selectedParkingTicket?: CartTicketData

  constructor(
    private cartService: CartApiService,
    private router: Router,
    private localize: LocalizeRouterService,
    private successService: SuccessModalService,
    private homeService: ScrollingCardService) {
      this.setIsOnMobile()
  }


  ngOnInit(): void {
    this.quantity = this.quantity > 0 ? this.quantity : 1
    if (this.scrollable) {
      this.homeService.scrollingTickets.subscribe(scrolling => {
        setTimeout(() => {
          this.scrolling = scrolling
        }, 200);
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize($event:any) {
    this.setIsOnMobile()
  }

  private setIsOnMobile() {
    this.isOnMobile = window.innerWidth < 880 ? true : false
  }

  get borderImageSrc(): string {
    switch (this.type) {
      case TicketTypes.Skybox:
        return '/assets/images/png/ticket/ticket-border-skybox.png'
      case TicketTypes.VipPlatinum:
        return '/assets/images/png/ticket/ticket-border-vip-platinum.png'
      case TicketTypes.VipGold:
        return '/assets/images/png/ticket/ticket-border-vip-gold.png'
      case TicketTypes.Vip:
        return '/assets/images/png/ticket/ticket-border-parking.png'
      default :
        return '/assets/images/png/ticket/ticket-border-vip-gold.png'
    }
  }

  get coverImageSrc(): string {
    switch (this.type) {
      case TicketTypes.Skybox:
        return '/assets/images/png/ticket/ticket-cover-skybox.jpg'
      case TicketTypes.VipPlatinum:
        return '/assets/images/png/ticket/ticket-cover-vip-platinum.jpg'
      case TicketTypes.VipGold:
        return '/assets/images/png/ticket/ticket-cover-vip-gold.jpg'
      case TicketTypes.Vip:
        return '/assets/images/png/ticket/ticket-cover-parking.jpg'
      default:
        return '/assets/images/png/ticket/ticket-cover-vip-gold.jpg'
    }
  }

  getTitle(): string {
    return this.ticket.name
  }

  chooseSeatsState(auto: boolean) {
    if(this.scrolling) {
      return
    }
    this.autoSeatChooser = auto
    if (this.autoSeatChooser) {
      this.addToCartAutoChooser()
    } else {
      /* if only on mobile */
      if(this.isOnMobile) {
        this.state = "seat-choosing"
      } else {
        /* show desktop modal */
        this.showDesktopMap.emit()
      }

    }
  }

  getAutoSeat() {
    if(this.scrolling) {
      return
    }
    this.addToCartAutoChooser()
  }

  addToCartTicketSimple(seatId: string) {
    if(this.scrolling) {
      return
    }
    const ticket: CartTicketData = {
      event_slug: this.event_slug,
      tickets: {
        auto: false,
        type: this.getTicketType,
        seat_id: seatId
      }
    }
    this.cartService.addToCart(ticket, this)
  }

  addToCartTicketSkybox(identifier: string) {
    if(this.scrolling) {
      return
    }
    const ticket: CartTicketData = {
      event_slug: this.event_slug,
      skybox: {auto: false, identifier: identifier}
    }
    this.cartService.addToCart(ticket, this)
  }

  addToCartAutoChooser() {
    if(this.scrolling) {
      return
    }
    if (this.type === TicketTypes.Skybox) {
      const ticket: CartTicketData = {
        event_slug: this.event_slug,
        skybox: {auto: true}
      }
      this.cartService.addToCart(ticket, this)
    } else {
      const ticket: CartTicketData = {
        event_slug: this.event_slug,
        tickets: {
          auto: true,
          type: this.getTicketType,
          number_of_tickets: this.quantity
        }
      }
      this.cartService.addToCart(ticket, this)
    }

    //todo navigate somewhere?
  }

  get getTicketType(): "platinum" | "gold" | "parking" {
    switch (this.type) {
      case TicketTypes.VipGold:
        return "gold"
      case TicketTypes.VipPlatinum:
        return "platinum"
      case TicketTypes.Vip:
        return "parking"
      case TicketTypes.Skybox:
        throw Error("Cannot be ticket type skybox in this procedure")
    }
  }

  changeSelectedParkingTicket(parkingTicketType: "parking", parkingTicketQuantity: number) {
    if(this.scrolling) {
      return
    }
    this.selectedParkingTicket = {
      event_slug: this.event_slug,
      parking_tickets: {
        number_of_parking_tickets: parkingTicketQuantity,
        parking_ticket_type: parkingTicketType
      }
    }
  }

  addParkingTicketToCart() {
    if(this.scrolling) {
      return
    }
    if (this.state === "parking") {
      if (this.selectedParkingTicket) {
        this.parkingQuantitySelected = this.selectedParkingTicket.parking_tickets?.number_of_parking_tickets
        this.cartService.addToCart(this.selectedParkingTicket, this)
      }
    }
  }

  public handleErrorResponse(err: string): void {
    if (this.state !== "parking") {
      this.state = "selected"
    }
  }

  public handleSuccessResponse(res?: any): void {

    if (this.state !== "parking") {
      this.parkingSelected = false
      this.successService.showSuccess(`${this.quantity} db jegy hozzádva a kosárhoz!`)
    } else {
      //this.router.navigate([this.localize.translateRoute('/cart')])
      this.parkingSelected = true
      this.successService.showSuccess(`${this.parkingQuantitySelected} db parkolójegy hozzádva a kosárhoz!`)
    }
    this.state = 'seat-selected'
  }


  toggleParking() {
    if(this.scrolling) {
      return
    }
    this.state = this.state == 'parking' ? 'seat-choosing' : 'parking'
  }

  changeDivider(number: string | null) {
    if (number) {
      return number.replaceAll(',', '.')
    } else return ""
  }

  get isTicketTypeAvaible(): boolean {
    return this.ticket.is_available
  }
}
