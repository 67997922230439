<ng-container *ngIf="!isResendingValidationTokenDone">
    <div [class] = "style">
        <p id="resend-activation-token-help">{{'activate1' | translate}}</p>
        <form [formGroup]="resendValidationEmailForm" (ngSubmit)="onSubmit()">
            <input type="email" class="input-text dark" placeholder="{{'resendValidationEmail.email' | translate}}"
                formControlName="emailAddress">
            <mvm-error classes="mb-5" [show]="getError('emailAddress', 'required')"
                [text]="'resendValidationEmail.validation.emailAddress.required' | translate"></mvm-error>
            <mvm-error classes="mb-5" [show]="getError('emailAddress', 'email')"
                [text]="'resendValidationEmail.validation.emailAddress.email' | translate"></mvm-error>

            <button [disabled]="isRequesting" class="btn-yellow">{{'activate2' | translate}}</button>
        </form>
    </div>
</ng-container>
<ng-container *ngIf="isResendingValidationTokenDone">
    <div [class] = "style">
        <p>{{'activate3' | translate}}</p>
    </div>
</ng-container>