import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Subject, takeUntil } from 'rxjs';
import { HandleErrorAndSuccess } from 'src/app/shared/interfaces/common/handle-error-and-success';
import { AuthApiService } from 'src/app/shared/services/apiServices/authApiService/auth-api.service';
import { SuccessModalService } from 'src/app/shared/services/SuccessService/success-modal.service';
import { passwordAgainSame } from 'src/app/shared/validators/password-again-same.validator';
import { HelperService } from 'src/app/utils/helper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mvm-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements HandleErrorAndSuccess, OnInit, OnDestroy {
  public registrationForm!: FormGroup
  public isRequesting: boolean = false
  public done: boolean = false
  private destroy$ = new Subject<void>();

  constructor(
    private _fb: FormBuilder,
    private _authApiService: AuthApiService,
    private _successModalService: SuccessModalService,
    private _helperService: HelperService,
  ) {
    const userType = _helperService.isParkingSite() ? 'parking' : 'default'
    this.registrationForm = this._fb.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      emailAddress: ['', [
        Validators.required, Validators.email
      ]],
      password: ['', Validators.required],
      passwordConfirmation: ['', [Validators.required, passwordAgainSame()]],
      termsAndConds: [false, Validators.requiredTrue],
      type: [userType],
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngOnInit() {
    this._registerValueChangeHandlers()
  }

  private _registerValueChangeHandlers() {
    this.registrationForm.get('password')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.registrationForm.controls['passwordConfirmation'].updateValueAndValidity()
    })
  }

  public onSubmit(): void {
    /* mark all inputs */
    this._markAllInputs()

    /* if form is invalid */
    if (!this.registrationForm.valid) { return; }

    /* set isrequesting */
    this._requestStarted()

    /* sent data */
    this._authApiService.register(this.registrationForm.value, this)
  }

  /* handles success subscription request */
  public handleSuccessResponse(): void {
    this._requestStopped()
    this.done = true
    this.registrationForm.reset()
    this._successModalService.showSuccessTranslate('dynamic.text6')
  }

  /* handles failed subscription request */
  public handleErrorResponse(err: string): void {
    this._requestStopped()
  }

  /* returns validation errors */
  public getError(attribute: string, validationRule: string) {
    return this.registrationForm.get(attribute)?.touched && this.registrationForm.get(attribute)?.errors?.[validationRule]
  }

  /* requesting started */
  private _requestStarted(): void {
    this.isRequesting = true
  }

  /* requesting finihsed */
  accepted: boolean = false;
  private _requestStopped(): void {
    this.isRequesting = false
  }

  private _markAllInputs() {
    Object.keys(this.registrationForm.controls).map((input) => {
      this.registrationForm.get(input)?.markAsTouched()
    })
  }
}
