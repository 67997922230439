import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { growInOut } from 'src/app/shared/animations/growInOut';
import { DashboardEvent } from 'src/app/shared/interfaces/dashboard/dashboard.types';
import { BuyParkingModalService } from 'src/app/shared/services/buyParkingTicketModalService/buy-parking-modal.service';
import { SendTicketModalService } from 'src/app/shared/services/sendTicketService/send-ticket-modal.service';

@Component({
  selector: 'mvm-parking-ticket',
  templateUrl: './parking-ticket.component.html',
  styleUrls: ['./parking-ticket.component.scss'],
  standalone: true,
  animations: growInOut,
  imports: [CommonModule, AngularSvgIconModule, TranslateModule],
})
export class ParkingTicketComponent {
  @Input() event: DashboardEvent = {} as DashboardEvent
  public isOpen: boolean = false

  constructor(
    private sendTicketService: SendTicketModalService, 
    private buyParkingModalService: BuyParkingModalService) {
  }

  sendParkingTicket(id ?: string) {
    if (id) {
      this.sendTicketService.openModal(id, "parking")
    }
  }

  downloadAllTickets(download_all?: string) {
    if (download_all) {
      window.open(download_all, "_blank");
    }
  }


  buyParkingTicket(slug: string) {
    this.buyParkingModalService.openModal(slug)
  }
}
