import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule, NgClass} from "@angular/common";

@Component({
  standalone: true,
  selector: 'mvm-dropdown-chooser',
  templateUrl: './dropdown-chooser.component.html',
  imports: [
    NgClass,
    CommonModule,
  ],
  styleUrls: ['./dropdown-chooser.component.scss']
})
export class DropdownChooserComponent {

  @Input("name") name: string = "Default"
  @Input("options") options: string[] = []
  @Output() countrySelected: EventEmitter<string> = new EventEmitter<string>()

  open = false
  toggle() {
    this.open = !this.open
  }

  optionSelected(option: string) {
    this.open = false
    this.countrySelected.emit(option)
  }
}
