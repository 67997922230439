import { Injectable } from '@angular/core';
import {
  CartOrderData,
  OrderResponse,
  OrderStatusResponse
} from "../../../interfaces/checkout-cart/checkout-cart.models";
import { ApiRequestService } from "../apiRequestService/api-request.service";
import { cartOrderSuccess } from "../../../../store/actions/cart.actions";
import { Store } from "@ngrx/store";
import { orderLoaded } from "../../../../store/actions/order.actions";
import { HandleErrorAndSuccess } from 'src/app/shared/interfaces/common/handle-error-and-success';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/utils/helper.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutApiService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store,
    private _router: Router,
    private localizeRouterService: LocalizeRouterService,
    private _helperService: HelperService,
  ) { }


  order(orderData: CartOrderData, self: HandleErrorAndSuccess) {
    let a: any = orderData
    a.userToken = localStorage.getItem('bearer_token')
    a.redirectUrl = this._helperService.getRedirectUrlForWebView() ?? (environment.domain + this.localizeRouterService.translateRoute(['/checkout-done']))
 
    this._apiRequestService.postRequest('order', a, (res) => {
      this.orderSuccess(res)
      self.handleSuccessResponse(res)
    }, undefined, true)

  }

  getOrderStatus(orderId: string) {
    this._apiRequestService.getRequest(`order/${orderId}`, (res) => {
      this.orderStatusSucces(res)
    }, undefined, true)
  }

  private orderSuccess(res: OrderResponse) {
    if (res.success) {
      this._store.dispatch(cartOrderSuccess())
      if (res.data.redirect) {
        // navigate to payment page, todo after payment needs to navigate back to checkout-done page
        window.location.href = res.data.redirect
      } else {
        this._router.navigate([this.localizeRouterService.translateRoute("/checkout-done/" + res.data.order_id)]);
      }
    }
  }

  private orderStatusSucces(res: OrderStatusResponse) {
    /* clear cart */
    this._store.dispatch(cartOrderSuccess())
    localStorage.removeItem(('cart_id'))
    this._store.dispatch(orderLoaded({ order: res }))
  }
}
