import {createReducer, on} from "@ngrx/store";
import {initialDashboardState} from "../states/dashboard.state";
import {dashboardLoaded} from "../actions/dashboard.actions";

export const dashboardReducer = createReducer(
    initialDashboardState,
    on(dashboardLoaded, (state, {events, vip_message, invoices, show_vip_functions}) => ({
        invoices: invoices, 
        events:  events, 
        vip_message: vip_message,
        show_vip_functions: show_vip_functions,
    }))
)

