import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ErrorModalService {
  constructor(private translate: TranslateService) {
  }

  state$ = new BehaviorSubject<{ visible: boolean, message: string, title: string, iconx: boolean }>({
    visible: false,
    message: "", title: "", iconx: false
  });

  showError(errorMessage: string, title: string = "Hiba történt", iconx: boolean = false) {
    this.state$.next({
      visible: true,
      message: errorMessage,
      title: title,
      iconx: iconx
    })
  }

  showErrorTranslate(messageKey: string, titleKey: string = "error", iconx: boolean = false) {
    this.translate.get(messageKey).subscribe(message => {
      this.translate.get(titleKey).subscribe(title => {
        this.state$.next({
          visible: true,
          message: message,
          title: title,
          iconx: iconx
        })
      })
    })
  }

  close() {
    this.state$.next({visible: false, message: "", title: "", iconx: false})
  }
}
