import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TicketTypes} from '../../../enums/ticket-types.enum';
import {TicketModify} from "../../../model/ticket-modify";
import {ChooserParkingComponent} from "../parking/chooser-parking/chooser-parking.component";
import {DropdownTicketinfoComponent} from "../../dropdowns/dropdown-ticketinfo/dropdown-ticketinfo.component";
import {ChooserQuantityComponent} from "../../input/chooser-quantity/chooser-quantity.component";
import {fadeInOut} from "../../../animations/fadeInOut";
import {slideDownFadeInOut, slideFadeInOut} from "../../../animations/slideFadeInOut";
import {AngularSvgIconModule} from "angular-svg-icon";
import {TranslateModule} from "@ngx-translate/core";
import {Store} from "@ngrx/store";
import {selectModifyTicket} from "../../../../store/selectors/modify-ticket.selector";
import {Subject, takeUntil} from "rxjs";
import {CartApiService} from "../../../services/apiServices/cartApiService/cart-api.service";
import {Router} from "@angular/router";
import {selectCartCart} from "../../../../store/selectors/cart.selector";
import {CartTicketData, CheckoutCart} from "../../../interfaces/checkout-cart/checkout-cart.models";
import {SeatMapComponent} from "../../seat-map/seat-map.component";
import { HandleErrorAndSuccess } from 'src/app/shared/interfaces/common/handle-error-and-success';

@Component({
  selector: 'mvm-ticket-modify',
  standalone: true,
  imports: [CommonModule, ChooserParkingComponent, DropdownTicketinfoComponent, ChooserQuantityComponent, AngularSvgIconModule, TranslateModule, SeatMapComponent],
  templateUrl: './ticket-modify.component.html',
  styleUrls: ['./ticket-modify.component.scss'],
  animations: [fadeInOut, slideFadeInOut, slideDownFadeInOut]
})
export class TicketModifyComponent implements OnInit, OnDestroy, HandleErrorAndSuccess {

  TicketTypes = TicketTypes
  ticket?: TicketModify

  eventSlug?: string
  type?: "gold" | "platinum" | "skybox"
  cart: CheckoutCart | null = null
  @Output() modificationFinished: EventEmitter<null> = new EventEmitter<null>()

  /* = {
   parkingQuantity: 3,
   price: 29_999,
   quantity: 5,
   type: 'GOLD',
   seats: undefined,
   event_slug: "",
   id: ""
 }*/

  private destroy = new Subject()

  mapType: 'simple' | 'skybox' = 'simple';
  state: 'default' | 'seat-choosing' | 'parking' = 'default';

  constructor(private router: Router, private _store: Store, private cartService: CartApiService) {
  }

  ngOnInit(): void {
    this._store.select(selectCartCart).pipe(takeUntil(this.destroy)).subscribe(cart => {
      this.cart = cart
      this.setTicketData()
    })

    this._store.select(selectModifyTicket).pipe(takeUntil(this.destroy)).subscribe(ticket => {
      if(!ticket) {
        this.modificationFinishedFn()
        return 
      }
      this.eventSlug = ticket?.event_slug
      this.type = ticket?.ticketType
      this.setTicketData()
    })
  }

  modificationFinishedFn() {
    this.modificationFinished.emit()
  }

  setTicketData() {
    if(this.cart !== null && this.eventSlug && this.type) {
      const event = this.cart.events.find(v => v.slug === this.eventSlug)
      const ticket = event?.tickets.at(0)
      const parkingTicket = event?.parking_tickets.at(0)
      if(ticket && event) {
        this.ticket = {
          event_slug: this.eventSlug,
          parkingQuantity: parkingTicket?.number_of_tickets ?? 0,
          price: ticket.raw_price,
          quantity: ticket.number_of_tickets,
          seats: ticket.seat_ids.length,
          type: this.type
        }
      }
    }
  }

  get borderImageSrc(): string {
    switch (this.ticket?.type) {
      case 'skybox':
        return '/assets/images/png/ticket/ticket-border-skybox.png'
      case 'platinum':
        return '/assets/images/png/ticket/ticket-border-vip-platinum.png'
      case 'gold':
        return '/assets/images/png/ticket/ticket-border-vip-gold.png'
      default:
        return '/assets/images/png/ticket/ticket-border-vip-gold.png'
    }
  }
  get coverImageSrc(): string {
    switch (this.ticket?.type) {
      case 'skybox':
        return '/assets/images/png/ticket/ticket-cover-skybox.jpg'
      case 'platinum':
        return '/assets/images/png/ticket/ticket-cover-vip-platinum.jpg'
      case 'gold':
        return '/assets/images/png/ticket/ticket-cover-vip-gold.jpg'
      default:
        return '/assets/images/png/ticket/ticket-cover-vip-gold.jpg'

    }
  }
  getTitle(): string {
    switch (this.ticket?.type) {
      case 'skybox':
        return 'Skybox'
      case 'platinum':
        return 'Platinum Lounge jegy'
      case 'gold':
        return 'Gold Lounge jegy'
      default:
        return 'Gold Lounge jegy'
    }
  }

  addToCartTicketSimple(seatId: string) {
    if(this.eventSlug){
      const ticket: CartTicketData = {
        event_slug: this.eventSlug,
        tickets: {
          auto: false,
          type: this.type === "gold" ? "gold" : "platinum",
          seat_id: seatId
        }
      }
      this.cartService.addToCart(ticket, this)
    }
    //todo svg need to watch cart for seats user booked.
  }

  addToCartTicketSkybox(identifier: string) {
    if(this.eventSlug){
      const ticket: CartTicketData = {
        event_slug: this.eventSlug,
        skybox: {auto: false, identifier: identifier}
      }
      this.cartService.addToCart(ticket, this)
    }
    //todo navigate somewhere?
  }

  public handleErrorResponse(err: string): void {
    
  }

  public handleSuccessResponse(res?: any): void {
    
  }

  setAutoSeat() {
    if(this.eventSlug){
      if (this.mapType === "skybox") {
        const ticket: CartTicketData = {
          event_slug: this.eventSlug,
          skybox: {auto: true}
        }
        this.cartService.addToCart(ticket, this)
      } else if (this.mapType === "simple") {
        const ticket: CartTicketData = {
          event_slug: this.eventSlug,
          tickets: {
            auto: true,
            type: this.type === "gold" ? "gold" : "platinum",
            number_of_tickets: this.ticket?.quantity
          }
        }
        this.cartService.addToCart(ticket, this)
      }
    }

    this.state = 'default'
    // TODO navigate back to cart?
  }

  addParkingTicketToCart(parkingTicketType: "parking", parkingTicketQuantity: number) {
    if(this.eventSlug){
      const ticket: CartTicketData = {
        event_slug: this.eventSlug,
        parking_tickets: {
          number_of_parking_tickets: parkingTicketQuantity,
          parking_ticket_type: parkingTicketType
        }
      }
      this.cartService.addToCart(ticket, this)
    }
    // todo navigate somewhere else?
  }

  chooseSeatsState(auto: boolean) {
    if (auto) {
      this.setAutoSeat()
    } else {
      this.state = "seat-choosing"
    }
  }

  toggleParking() {
    this.state = this.state == 'parking' ? 'seat-choosing' : 'parking'
  }


  changeDivider(number: string | null) {
    if (number) {
      return number.replaceAll(',', '.')
    } else return ""
  }

  ngOnDestroy(): void {
    this.destroy.next('')
    this.destroy.complete()
  }

}
