import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'mvm-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class ErrorComponent {
  @Input() show: boolean = false
  @Input() text: string = ""
  @Input() classes: string = ""
}
