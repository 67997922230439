import {Injectable} from '@angular/core';
import {ApiRequestService} from "../apiRequestService/api-request.service";
import {Store} from "@ngrx/store";
import {SkyboxBlock} from "../../../interfaces/seat-map/map";

@Injectable({
  providedIn: 'root'
})

export class MapApiService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store
  ) {
  }

  getTicketMap(eventSlug: string, ticketType: "gold" | "platinum" | "skybox", cb: (res: any) => void = (res) => {
  }) {
    this._apiRequestService.getRequest(`tickets/${eventSlug}/${ticketType}`, cb, undefined, true)
  }

  getSkyboxBlock(eventSlug: string, ticketType: "gold" | "platinum" | "skybox", blokk: string, cb: (res: SkyboxBlock) => void) {
    this._apiRequestService.getRequest(`tickets/${eventSlug}/${ticketType}/${blokk}`, cb, undefined, true)
  }

}
