<div class="wrapper">
  <div class="flex">
    <div class="flex flex-col items-center" id="partners-holder">
      <div class="current-partners">
        <h2 id="our-partners" class="text-h1">{{'partners.text1' | translate}}</h2>

        <a href="https://mvm.hu" target="_blank" id="mvm-partner">
          <img [src]="'/assets/images/png/partners/partners-mvm.png'" alt="MVM"
            style="max-height: 100%; max-width: 100%;">
        </a>

        <div class="partners-container flex">
          <div *ngFor="let partner of partners" class="partner">
            <a [href]="partner['url']" target="_blank">
              <img [src]="'/assets/images/png/partners/partners-' + partner['img'] + '.png'" [alt]="partner"
                style="max-height: 100%; max-width: 100%;">
            </a>
          </div>
        </div>
      </div>
    </div>
    <div id="contact" class="partner-contact-form">
      <h2 class="text-h1">{{'partners.text3' | translate}}</h2>

      <!--<h3 class="text-title">{{'partners.text5' | translate}}</h3>-->
      <form [formGroup]="becomePartnerForm" (ngSubmit)="onSubmit()">
        <input type="text" class="input-text dark" placeholder="{{'partners.text6' | translate}}"
          formControlName="lastName">
        <mvm-error classes="mb-5" [show]="getError('lastName', 'required')"
          [text]="'partners.validation.text6.required' | translate"></mvm-error>

        <input type="text" class="input-text dark" placeholder="{{'partners.text7' | translate}}"
          formControlName="firstName">
        <mvm-error classes="mb-5" [show]="getError('firstName', 'required')"
          [text]="'partners.validation.text7.required' | translate"></mvm-error>

        <input id="email" type="email" class="input-text dark" placeholder="{{'partners.text8' | translate}}"
          formControlName="emailAddress">
        <mvm-error classes="mb-5" [show]="getError('emailAddress', 'required')"
          [text]="'partners.validation.text8.required' | translate"></mvm-error>
        <mvm-error classes="mb-5" [show]="getError('emailAddress', 'email')"
          [text]="'partners.validation.text8.email' | translate"></mvm-error>

        <div class="checkbox-wrapper flex cursor-pointer" (click)="accepted = !accepted">
          <mvm-checkbox [rounded]="true" formControlName="termsAndConds"></mvm-checkbox>
          <p [innerHTML]="'partners.text9' | translate" class="text-white"></p>
        </div>
        <mvm-error classes="mb-5" [show]="getError('termsAndConds', 'required')"
          [text]="'partners.validation.text9.required' | translate"></mvm-error>

        <mvm-button-save [disabled]="done" [saving]="isRequesting" defaultText="{{'request-offer' | translate}}"
          savedText="{{'request-offer-sent' | translate}}" [saved]="done"></mvm-button-save>
      </form>
      <a [routerLink]="['/vip' | localize]" class="btn-text-yellow">{{'partners.text10' | translate}}</a>
    </div>
  </div>
</div>
