<div id="newsletter-holder">
  <div class="relative">
    <img src="/assets/images/jpg/newsletter-bg.jpg" alt="Hírlevél feliratkozás">
    <div class="newsletter-title">
      <h2>{{'home.newsletter.title' | translate}}</h2>
    </div>
  </div>
  <div id="newsletter-form-holder">

    <p>{{'home.newsletter.desc' | translate}}</p>
    <a class="aszf" [attr.aria-label]="'aszf' | translate" target="_blank" href="https://mvm-dome.hu/pdf/MVM_Dome%20honlap_adatkezelesi_tajekoztato_HUN_ENG.pdf">{{'aszf' | translate}}</a>
    <form [formGroup]="newsletterForm" (ngSubmit)="!isRequesting ? onSubmit() : null">
      <div id="inputs">
        <div>
          <input placeholder="{{'home.newsletter.placeholderName' | translate}}" formControlName="firstName"/>
          <mvm-error [show]="getError('firstName', 'required')"
                     [text]="'home.newsletter.validation.firstName.required' | translate"></mvm-error>
        </div>
        <div>
          <input placeholder="{{'home.newsletter.placeholderEmail' | translate}}" formControlName="emailAddress">
          <mvm-error [show]="getError('emailAddress', 'required')"
                     [text]="'home.newsletter.validation.emailAddress.required' | translate"></mvm-error>
          <mvm-error [show]="getError('emailAddress', 'email')"
                     [text]="'home.newsletter.validation.emailAddress.email' | translate"></mvm-error>
        </div>
      </div>

      <div class="checkbox-wrapper flex cursor-pointer" (click)="accepted = !accepted">
        <mvm-checkbox [rounded]="true" color="black" formControlName="termsAndConds"></mvm-checkbox>
        <p [innerHTML]="'home.newsletter.acceptText' | translate"></p>
      </div>
      <mvm-error classes="text-left" [show]="getError('termsAndConds', 'required')"
                 [text]="'home.newsletter.validation.termsAndConds.required' | translate"></mvm-error>

      <mvm-button-save class="btn-save-newsletter"
                       [disabled]="done"
                       [saving]="isRequesting"
                       defaultText="{{'home.newsletter.subscribe' | translate}}"
                       savedText="{{'home.newsletter.subscribed' | translate}}"
                       [saved]="done"
      ></mvm-button-save>
    </form>
  </div>
</div>
