import { Injectable } from "@angular/core";
import { local } from "d3";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class HelperService {
    /* decides whether we are currently on the parking site */
    public isParkingSite(): boolean {
        const domain = window.location.hostname
        
        return domain == environment.parkingDomain
    }

    /* decides whether it is in webview */
    public isWebView(): boolean {
        return localStorage.getItem('webview') == '1';
    }

    /* returns if there is redirect url set in local storage */
    public getRedirectUrlForWebView(): string | null {
        const redirectUrlForWebView = localStorage.getItem('redirect-url-for-webview')

        if(!redirectUrlForWebView) return null

        return redirectUrlForWebView
    }
}