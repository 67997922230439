import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoreComponent} from "../../../layouts/core-layout/core.component";
import {AngularSvgIconModule} from "angular-svg-icon";
import {
  CartitemCardBackgroundColors,
  CartitemCardComponent
} from "../../../../shared/components/cards/cartitem-card/cartitem-card.component";
import {HowToApproachComponent} from "../../../../shared/components/how-to-approach/how-to-approach.component";
import {CheckoutLayoutComponent} from "../../../layouts/checkout-layout/checkout-layout.component";
import {TranslateModule} from "@ngx-translate/core";
import {RouterLink} from "@angular/router";
import {LocalizeRouterModule} from "@gilsdav/ngx-translate-router";
import {Store} from "@ngrx/store";
import {selectCartCart} from "../../../../store/selectors/cart.selector";
import {CartApiService} from "../../../../shared/services/apiServices/cartApiService/cart-api.service";
import {CartEvent} from "../../../../shared/interfaces/checkout-cart/checkout-cart.models";
import { CartItemType } from 'src/app/shared/enums/cartitem-type';

@Component({
  selector: 'mvm-checkout-cart',
  standalone: true,
  imports: [CommonModule, CoreComponent, AngularSvgIconModule, CartitemCardComponent, HowToApproachComponent, CheckoutLayoutComponent, TranslateModule, RouterLink, LocalizeRouterModule],
  templateUrl: './checkout-cart.component.html',
  styleUrls: ['./checkout-cart.component.scss']
})
export class CheckoutCartComponent implements OnInit {
  CartItemBgColors = CartitemCardBackgroundColors

  cart$ = this.store.select(selectCartCart)

  constructor(private store: Store, private cartService: CartApiService) {
  }

  ngOnInit(): void {
    this.cartService.loadCart()
  }


  odd(events: CartEvent[] | undefined): boolean {
    //let l = events?.length ?? 0
    let l = 0
    events?.forEach(e => {
      if (e.parking_tickets ) {
        l += e.parking_tickets.length
      }

      if(e.tickets) {
        l += e.tickets.length
      }
    })

    return l % 2 !== 0;
  }

  cartItemTypes() {
    return CartItemType
  }

}
