import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreComponent } from '../../layouts/core-layout/core.component';
import { ContainerComponent } from 'src/app/shared/components/container/container.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ParkingCardComponent } from '../../components/parking/parking-card/parking-card.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from "@ngx-translate/core";
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { UpcomingEventsComponent } from './_partials/upcoming-events/upcoming-events.component';
import { MapsComponent } from './_partials/maps/maps.component';

@Component({
  selector: 'mvm-parking',
  standalone: true,
  imports: [
      CommonModule, 
      CoreComponent, 
      ContainerComponent, 
      AngularSvgIconModule, 
      ParkingCardComponent, 
      TranslateModule,
      RouterModule,
      LocalizeRouterModule, 
      UpcomingEventsComponent,
      MapsComponent,
  ],
  templateUrl: './parking.component.html',
  styleUrls: ['./parking.component.scss']
})
export class ParkingComponent {
  
  public isMobile() {
    return window.outerWidth <= 600;
  }

}
