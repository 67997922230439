import {Component, HostListener, OnDestroy} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {AngularSvgIconModule} from "angular-svg-icon";
import {languageChooserAnimation} from "../../animations/navbar/languageChooserAnimation";
import {LocalizeRouterService} from "@gilsdav/ngx-translate-router";
import { Router } from '@angular/router';

@Component({
  selector: 'mvm-language-chooser',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  templateUrl: './language-chooser.component.html',
  styleUrls: ['./language-chooser.component.scss'],
  animations: languageChooserAnimation
})
export class LanguageChooserComponent implements OnDestroy{
  selectedLanguage: string = 'hu';
  subscription?: Subscription
  open: boolean = false;

  constructor(
    private translate: TranslateService, 
    private localize: LocalizeRouterService,
    private router: Router,
    ) {
    this.selectedLanguage = this.translate.currentLang ?? this.translate.defaultLang
     this.subscription = this.translate.onLangChange.subscribe(event => {
      this.selectedLanguage = event.lang
    })
  }

  @HostListener('document:click')
  close() {
    this.open = false
  }

  switchLang(lang: "hu" | "en") {
    localStorage.setItem('lang', lang)
    this.localize.changeLanguage(lang)
    this.translate.setDefaultLang(lang)
    this.translate.use(lang)
    this.translate.reloadLang(lang)
    //this.open = false

    window.location.href = '/' + lang
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

}
