import { style, query, group, animate, sequence } from "@angular/animations";

export const fadeAnimatedContent = [
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%'
    })
  ]),
  query(':enter', [
    style({ opacity: 0 })
  ]),
  query(':leave', [
    style({ opacity: 1 })
  ]),
  query(':leave', [
    query('.animated-content',  [
      animate('150ms ease', style({ opacity: 0 }))
    ]),
  ]),
  query(':enter', [
    query('.copyright', [
      style({ display: 'none' })
    ], { optional: true }),
    animate('150ms ease', style({ opacity: 1 }))
  ]),
]