import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ScrollingCardService {

  scrolling = new Subject<boolean>()
  scrollingTickets = new Subject<boolean>()
  constructor() {
    this.scrolling.next(false)
    this.scrollingTickets.next(false)
  }
}
