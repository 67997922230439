import {createFeatureSelector, createSelector} from "@ngrx/store";
import {EventsState} from "../states/events.state";

const eventsFeatureKey = 'events';

export const selectEvents = createFeatureSelector<EventsState>(eventsFeatureKey)

export const selectEventsEvents = createSelector(
  selectEvents,
    (state: EventsState) => state.events
)

