import {createReducer, on} from "@ngrx/store";
import * as ParkingActions from "../actions/parking.actions";
import { initialParkingState } from "../states/parking.state";

export const parkingReducer = createReducer(
    initialParkingState,
    on(ParkingActions.eventsLoaded, (state, { events }) => ({ ...state, events: events })),
    on(ParkingActions.clearParkingTypes, (state) => ({ ...state, parkingTypes: [] })),
    on(ParkingActions.setParkingTypes, (state, {parkingTypes}) => ({ ...state, parkingTypes:parkingTypes })),
)

