import { Component } from '@angular/core';
import {NavbarComponent} from "../../components/navbar/navbar.component";
import { HelperService } from 'src/app/utils/helper.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mvm-cart-layout',
  standalone: true,
  templateUrl: './cart-layout.component.html',
  imports: [
    NavbarComponent,
    CommonModule,
  ],
  styleUrls: ['./cart-layout.component.scss']
})
export class CartLayoutComponent {
  public hasNavbar: boolean = true

  constructor(
    private _helperService: HelperService,
    ) {
    const webview = this._helperService.isWebView()
    if(webview) {
      this.hasNavbar = false
    }
  }

}
