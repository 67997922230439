import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from "@angular/forms";
import {ChooserQuantityComponent} from "../../../input/chooser-quantity/chooser-quantity.component";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-chooser-parking',
  standalone: true,
  imports: [CommonModule, FormsModule, ChooserQuantityComponent, TranslateModule],
  templateUrl: './chooser-parking.component.html',
  styleUrls: ['./chooser-parking.component.scss']
})
export class ChooserParkingComponent {
  selectedValue: string = "";

  parkingTypes: { type: "vip" | "p1" | "p2", name: string, desc: string, quantity: number }[] = [
    {type: "vip", name: "P0 (VIP)", desc: "1", quantity: 0},
    /*{type: "p1", name: "P1", desc: "4", quantity: 0},
    {type: "p2", name: "P2", desc: "6", quantity: 0}*/
  ]

  @Output() parkingChange: EventEmitter<{ type: "parking", quantity: number }> = new EventEmitter()
  @Input() ticket: string = "test"

  emit($event: number, name: string) {
    this.parkingTypes.forEach(pt => {
      if(pt.name !== name){
        pt.quantity = 0
      }
    })
    const selected = this.parkingTypes.find(v => v.name === name)
    if (selected) {
      this.selectedValue = name + this.ticket
      selected.quantity = $event
      this.parkingChange.emit({type: "parking", quantity: selected.quantity})
    }
  }

  onchange(name: string) {
    this.selectedValue = name+this.ticket
    this.parkingTypes.forEach(pt => {
      if(pt.name !== name){
        pt.quantity = 0
      }
    })
  }
}
