import {Component, EventEmitter, Input, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-chooser-quantity',
  standalone: true,
    imports: [CommonModule, TranslateModule],
  templateUrl: './chooser-quantity.component.html',
  styleUrls: ['./chooser-quantity.component.scss']
})
export class ChooserQuantityComponent {
  @Input() db: boolean = false;
  @Input() disabled: boolean = false;
  @Input() quantity: number = 0;
  @Output() quantityChange: EventEmitter<number> = new EventEmitter<number>()
  
  change(number: number) {
    if(this.disabled) return ;

    if(this.quantity + number >= 0){
      this.quantity += number
      this.quantityChange.emit(this.quantity)
    }
  }
}
