import {Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'mvm-bordered-checkbox-button',
  templateUrl: './bordered-checkbox-button.component.html',
  styleUrls: ['./bordered-checkbox-button.component.scss'],
  /*providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BorderedCheckboxButtonComponent),
      multi: true,
    }
  ]*/
})
export class BorderedCheckboxButtonComponent /*implements ControlValueAccessor*/ {
  @Input() name: string = ""
  @Input() rounded: boolean = false

  @Input() value: boolean = false
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  //public checked: boolean = false
  @Input() disabled: boolean = false

  /*public onControlClicked() {
    this.checked = !this.checked
    this._onChange(this.checked)
  }

  writeValue(isChecked: boolean): void {
    this.checked = isChecked
  }

  private _onChange = (checked: boolean | null) => {};
  public registerOnChange(fn: (checked: boolean | null) => void): void {
    this._onChange = fn;
  }

  public _onTouched = () => {};
  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }*/

  clicked() {
    if(!this.disabled) {
      this.value = !this.value
      this.valueChange.emit(this.value)
    }
  }
}
