<div  class="modal-container" [ngClass]="visible ? 'modal-visible' : 'modal-hidden'" >

    <div class="modal-content-container" (click)="close()">

      <div class="modal-success" [ngClass]="{'modal-warning': warning}">
        <div class="text-subtitle bol text-center" [innerHTML]="message"></div>
      </div>
    </div>

  </div>
