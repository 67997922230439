<p class="text-center select-seat" *ngIf="selectedSector">
  {{ eventType == "skybox" ? ('skyboxMapChoose' | translate) : ('seatMapChoose' | translate) }}</p>
<button class="btn-textish back-to-sector-view" *ngIf="selectedSector || selectedBlock" (click)="backToSectorView()">
  <svg-icon name="arrow-left"></svg-icon>{{ 'seatMapSector' | translate}}
</button>

<div id="map-holder">
  <div class="show-seatmap-controls-demo" *ngIf="showSeatMapControlsDemoFn" (click)="hideSeatMapControls()">
    <p [innerHTML]="'seatMapControlDemoLayer' | translate"></p>
    <button class="btn-yellow">{{'ok' | translate}}</button>
  </div>

  <div id="zoom-tools">
    <button [disabled]="zoom == initialZoom && !mapHasMoved" (click)="resetZoom()">R</button>
    <button (click)="zoomIn()">+</button>
    <button [disabled]="zoom == initialZoom" (click)="zoomOut()">-</button>
  </div>
  <div class="svg-outer-holder" [ngStyle]="mapStyle" [ngClass]="{'transtition' : shouldApplyTransition}">
    <div class="relative text-center" [ngClass]="{'svg-holder' : selectedSector, 'sector-view' : !selectedSector}">
      <p class="loadingText text-default" *ngIf="!data">{{'loading' | translate}}</p>

      <!-- SEATMAP -->
      <svg id="svg-seatmap" xmlns="http://www.w3.org/2000/svg" shape-rendering="crispEdges"
        [ngStyle]="{'background-image' : 'url(' + data?.data?.svg + ')'}"
        [attr.viewBox]="selectedSector ? (getSelectedSector?.view_box ?? '0 0 800 400') : (data?.data?.svgviewbox ?? data?.data?.view_box ?? '0 0 800 400')">


        <ng-container *ngFor="let sector of data?.data?.sectors">

          <!-- SECTORS -->
          <ng-container *ngIf="!selectedSector && !selectedBlock">
            <ng-container [ngSwitch]="sector.type" *ngIf="!selectedSector">
              <rect class="d3select" *ngSwitchCase="'rect'" (click)="selectSector(sector.i, sector.block)"
                [attr.transform]="sector.t" [attr.x]="sector.x" [attr.y]="sector.y" [attr.width]="sector.w"
                [attr.height]="sector.h" [attr.fill]="sector.c" style="cursor: pointer"></rect>

              <polygon *ngSwitchCase="'polygon'" class="sector d3select"
                [ngClass]="{'sector-selected' : selectedSector === sector.i}"
                (click)="selectSector(sector.i, sector.block)" 
                [attr.transform]="sector.t" 
                [attr.points]="sector.p">
              </polygon>

              <path 
                class="d3select" 
                *ngSwitchCase="'path'" 
                
                (click)="selectSeat(sector.avaliable, sector.i)"

                [attr.transform]="sector.t" 
                [attr.fill]="sector.c" 
                [attr.d]="sector.d" 
                [ngClass]="{'mine' : isSeatReservedByMe(sector.i), 'available' : sector.avaliable, 'selecting': selectingId === sector.i && isRequesting}"
                [attr.fill]="isSeatReservedByMe(sector.i) ? '#F9B701' : sector.avaliable ? '#41EDAF' :  '#FF3055'" 
                style="cursor: pointer"></path>

              <circle (click)="selectSeat(sector.avaliable, sector.i)" class=" skybox-block"
                [ngClass]="{'mine' : isSeatReservedByMe(sector.i), 'available' : sector.avaliable, 'selecting': selectingId === sector.i && isRequesting}"
                [attr.cx]="sector.cx" [attr.cy]="sector.cy" [attr.r]="sector.r" stroke="black" stroke-width="0"
                [attr.fill]="isSeatReservedByMe(sector.i) ? '#F9B701' : sector.avaliable ? '#41EDAF' :  '#FF3055'" />
                <ng-container *ngFor="let text of sector.text[0]" [ngSwitch]="text.type">
                  <path *ngSwitchCase="'path'" [attr.d]="text.d" (click)="selectSector(sector.i, sector.block)"  />
                </ng-container>
            </ng-container>
          </ng-container>
          <!-- SECTORS -->

          <!-- Skybox Block -->
          <!--<ng-container *ngIf="selectedBlock && skyboxBlock && selectedBlock === sector.block">
              <svg [attr.viewBox]="skyboxBlock.data.view_box" >
                <ng-container *ngFor="let sectorSkybox of skyboxBlock.data.sectors">
                  <circle
                    (click)="selectSeat(sectorSkybox.avaliable, sectorSkybox.i)"
                    class="cursor-pointer skybox-block"
                    [ngClass]="{'mine' : isSeatReservedByMe(sectorSkybox.i), 'available' : sectorSkybox.avaliable, 'selecting': selectingId === sectorSkybox.i && isRequesting}"
                    [attr.cx]="sectorSkybox.cx"
                    [attr.cy]="sectorSkybox.cy"
                    [attr.r]="sectorSkybox.r"
                    stroke="black"
                    stroke-width="0"
                    [attr.fill]="isSeatReservedByMe(sectorSkybox.i) ? '#F9B701' : sectorSkybox.avaliable ? '#41EDAF' :  '#FF3055'" />
                  <text *ngFor="let text of sectorSkybox.text" [attr.x]="text.x" [attr.y]="text.y" class="small">{{text.txt}}</text>
                </ng-container>
              </svg>
            </ng-container>-->
          <!-- Skybox Block -->

        </ng-container>
        <!-- SEATS -->
        <ng-container *ngFor="let seat of data?.data?.seats">
          <!--<g (click)="selectSeat(seat.s, seat.i)" class="seats" >-->
          <ng-container [ngSwitch]="seat.type">
            <rect (click)="selectSeat(seat.s, seat.i)" *ngSwitchCase="'rect'" class="seat" style="cursor: pointer"
              [attr.height]="seat.h" [attr.width]="seat.w" [attr.x]="seat.x" [attr.y]="seat.y" [attr.rx]="seat.rx"
              [attr.transform]="seat.t" [id]="seat.i" shape-rendering="optimizeQuality" [ngClass]="{
                    'mine' : isSeatReservedByMe(seat.i),
                    'available' : seat.s && !isSeatReservedByMe(seat.i),
                    'unavailable': !seat.s && !isSeatReservedByMe(seat.i),
                    'is-requesting' : isRequesting
                    }" />

            <path (click)="selectSeat(seat.s, seat.i)" *ngSwitchCase="'path'" shape-rendering="optimizeQuality"
              class="seat" style="cursor: pointer" [attr.d]="seat.d" [attr.transform]="seat.t" [ngClass]="{
                    'mine' : isSeatReservedByMe(seat.i),
                    'available' : seat.s && !isSeatReservedByMe(seat.i),
                    'unavailable': !seat.s && !isSeatReservedByMe(seat.i),
                    'is-requesting' : isRequesting
                    }">
            </path>
          </ng-container>
          <ng-container *ngFor="let text of seat.txt" [ngSwitch]="text.type">
            <path *ngSwitchCase="'path'" [attr.d]="text.d" (click)="selectSeat(seat.s, seat.i)" />
          </ng-container>
          <!--</g>-->
        </ng-container>
        <!-- SEATS -->

        <!-- SECTOR LAYER -->
        <!--<path fill="#41EDAF" *ngIf="zoom < sectorLayerZoomThreshold && data?.data?.sector_layer" [attr.d]="data?.data?.sector_layer"></path>-->
        <!-- SECTOR LAYER -->

        <path *ngIf="zoom < sectorLayerZoomThreshold && data?.data?.path_for_zoom" (click)="zoomToSector($event)" [attr.d]="data?.data?.path_for_zoom" style="fill:rgb(6, 150, 6);z-index:999999999; cursor:pointer;" />

        <!--<polygon *ngIf="zoom < 9.9" (click)="zoomToSector($event)" points="204,373 204,380 240,380 240,373" style="fill:blue;stroke:purple;stroke-width:0; z-index:999999999; cursor:pointer;" />-->

      </svg>
      <!-- SEATMAP -->
    </div>
  </div>
</div>