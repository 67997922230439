<mvm-core class="home">
  <div id="homepage-cover-image"
    [style]="hasNavbar && showNavbarBg ? 'background-image:url(' + (coverImage$ | async) + ')' : null">
    <mvm-navbar *ngIf="hasNavbar"></mvm-navbar>
    <section class="search-controls">
      <mvm-container [type]="ContainerTypes.Searchbar">
        <mvm-home-searchbar></mvm-home-searchbar>
        <!--div class="categories-container flex justify-around">
          <div class="categories flex">
            <mvm-colored-border-button class="concert" [color]="BorderedButtonColors.yellow" text="Koncert" [type]="ProgramTypes.Concert"></mvm-colored-border-button>
            <mvm-colored-border-button class="corporate" [color]="BorderedButtonColors.green" text="Corporate" [type]="ProgramTypes.Corporate"></mvm-colored-border-button>
            <mvm-colored-border-button class="sport" [color]="BorderedButtonColors.blue" text="Sport" [type]="ProgramTypes.Sport"></mvm-colored-border-button>
          </div>
        </div>-->
      </mvm-container>
    </section>
  </div>
  <section class="programs">
    <mvm-container [type]="ContainerTypes.Full">
      <div class="cards-container" id="home-slider">
        <div class="padding-fix"></div>
        <ng-container *ngIf="skeleton">
          <div *ngFor="let a of [1,2,3,4,5,6,7,8,9,10]" class="skeleton-card"></div>
        </ng-container>
        <mvm-card-program [scrollable]="true" *ngFor="let event of events$ | async" [program]="event">
        </mvm-card-program>
        <mvm-more-programs-card></mvm-more-programs-card>
        <div class="padding-fix"></div>
      </div>
    </mvm-container>
  </section>
  <section id="home-vip">
    <mvm-home-vip></mvm-home-vip>
  </section>
  <section id="home-details">
    <mvm-container [type]="ContainerTypes.HomeVipDetails">
      <mvm-home-details></mvm-home-details>
    </mvm-container>
  </section>
  <!--<section class="interactive-map">
    <mvm-container [type]="ContainerTypes.InteractiveMapControls">
      <mvm-interactive-map></mvm-interactive-map>
    </mvm-container>
  </section>-->
  <section>
    <mvm-container [type]="ContainerTypes.InteractiveMapControls">
      <img src="/assets/images/webp/stadium.webp" alt="MVM Dome" class="max-w-full" id="mvm-dome">
    </mvm-container>
  </section>

  <section class="partners">
    <mvm-container [type]="ContainerTypes.Partners">
      <mvm-partners></mvm-partners>
    </mvm-container>
  </section>
  <!--<section id="advert">
    <mvm-container>
      <div class="advert-center" id='div-gpt-ad-1680630318263-0' style='min-width: 250px; min-height: 200px;'></div>
    </mvm-container>
  </section>-->
  <section id="newsletter">
    <mvm-container [type]="ContainerTypes.ProgramDetails">
      <mvm-newsletter></mvm-newsletter>
    </mvm-container>
  </section>
</mvm-core>