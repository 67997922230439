import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterLink} from "@angular/router";
import {AngularSvgIconModule} from "angular-svg-icon";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'mvm-card-simple-tickets',
  standalone: true,
    imports: [CommonModule, RouterLink, AngularSvgIconModule, TranslateModule],
  templateUrl: './card-simple-tickets.component.html',
  styleUrls: ['./card-simple-tickets.component.scss']
})
export class CardSimpleTicketsComponent {

}
