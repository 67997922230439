import {Component, OnInit} from '@angular/core';
import {AngularSvgIconModule} from "angular-svg-icon";
import {TranslateModule} from "@ngx-translate/core";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {LocalizeRouterService} from "@gilsdav/ngx-translate-router";
import {ProgramTypes} from "../../../../shared/enums/program-types.enum";

@Component({
    selector: 'mvm-home-searchbar',
    standalone: true,
    templateUrl: './home-searchbar.component.html',
  imports: [
    AngularSvgIconModule,
    TranslateModule,
    FormsModule
  ],
    styleUrls: ['./home-searchbar.component.scss']
})
export class HomeSearchbarComponent implements OnInit{

  queryString: string = ""
  type?: ProgramTypes

  constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private localizeRouterService: LocalizeRouterService
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.queryString = params['q'] ?? ""
      this.type = params['type']
    })
  }

  keyPressed($event: KeyboardEvent) {
    if($event.code === 'Enter') {
      this.search()
    }
  }
  private search() {
    let queryParams = {}
    if(this.queryString) queryParams = { q: this.queryString }
    if(this.type) queryParams = { type: this.type, ...queryParams}

    this.router.navigate(
        this.localizeRouterService.translateRoute(['/events']) as unknown[],
        {
          queryParams: queryParams,
          queryParamsHandling: ''
        });
  }
}
