<div class="navbar">
  <mvm-container [type]="ContainerTypes.Navbar">
    <div class="flex justify-between items-center w-full">
      <div class="flex gap-15px items-center">
        <a [routerLink]="(['/'] | localize)">
          <svg-icon name="mvm" class="mvm" [svgClass]="'logo'" [applyClass]="true">
          </svg-icon>
        </a>
        <mvm-language-chooser class="mobile-hidden"></mvm-language-chooser>
      </div>
      <mvm-language-chooser class="desktop-hidden" [ngClass]="open ? '' : 'invisible-l'"></mvm-language-chooser>
      <div class="mobile-icons flex justify-between items-center">
        <nav class="navigation only-desktop">
          <ul>
            <li><a class="text-title text-white" [attr.aria-label]="'navbar.calendar' | translate"
                [routerLink]="(['/events'] | localize)">{{('navbar.calendar' | translate)}}</a></li>
            <li *ngIf="(isLoggedIn$ | async)">
              <a class="text-title text-white"
                [routerLink]="(['/edit-profile'] | localize)"
                [attr.aria-label]=" ('navbar.profile' | translate)">{{ ('navbar.profile' | translate) }}</a>
            </li>
            <li ><a class="text-title text-white"
              [routerLink]="(['/vip'] | localize)"
              [attr.aria-label]="('navbar.vip' | translate)">{{ ('navbar.vip' | translate) }}</a>
            </li>
            <li><a class="text-title text-white yellow-border"
                [routerLink]="(isLoggedIn$ | async) ? (['/dashboard'] | localize) : (['/'] | localize)"
                [attr.aria-label]="(isLoggedIn$ | async) ? ('navbar.tickets' | translate) : ('navbar.partner' | translate)"
                [fragment]="(isLoggedIn$ | async) ? undefined :'contact'">
                {{(isLoggedIn$ | async) ? ('navbar.tickets' | translate) : ('navbar.partner' | translate)}}</a></li>
          </ul>
        </nav>

        <div class="image-container flex">
          <!--<svg-icon  class="social" [routerLink]="(isLoggedIn$ | async) ? (['/dashboard'] | localize) : (['/login'] | localize)"
                    name="social-icon"></svg-icon>-->
          <a class="relative" [routerLink]="['/cart'] | localize" [attr.aria-label]="'cart.title' | translate">
            <svg-icon name="cart"></svg-icon>
            <span *ngIf="getCartCount(cart$|async)" class="cart-badge">{{getCartCount(cart$|async)}}</span>
          </a>
        </div>

        <div class="hamburger-container only-mobile">
          <input class="hamburger-checkbox" type="checkbox" [checked]="open" [ngModel]="open"
            (ngModelChange)="onHamburgerClicked($event)" />
          <div class="hamburger-lines">
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
          </div>
        </div>
        <a *ngIf="!(isLoggedIn$ | async)" [routerLink]="['/login'] | localize"
          [attr.aria-label]="'navbar.login' | translate"
          class="btn-yellow only-desktop">{{'navbar.login' | translate}}</a>
        <a *ngIf="isLoggedIn$ | async" (click)="logout()" [attr.aria-label]="'navbar.logout' | translate"
          class="btn-yellow only-desktop">{{'navbar.logout' | translate}}</a>
      </div>
    </div>
  </mvm-container>
</div>