<div class="event-title" (click)="isOpen = !isOpen">
    <div >
      <p class="text-ticket-title">{{event.name}} <span *ngIf="!isOpen" class="yellow-badge">{{getEventTicketNumber(event)}}</span></p>
      
    </div>
    <svg-icon id="event-opener-icon" class="transition-3" [ngClass]="isOpen ? 'rotate-180' : ''" name="triangle-black"></svg-icon>
  </div>

  <ng-container *ngIf="isOpen">
    <!-- BELÉPŐJEGY -->
    <div @growInOut class="ticket-card" *ngFor="let ticket of event.tickets">
      <mvm-single-ticket-type [event]="event" [ticket]="ticket"></mvm-single-ticket-type>
    </div>
    <!-- BELÉPŐJEGY -->

    <!-- PARKOLÓJEGY -->
    <mvm-parking-ticket *ngIf="hasParkingTickets" [event]="event"></mvm-parking-ticket>
    <!-- PARKOLÓJEGY -->

  </ng-container>