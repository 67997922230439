import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocialRegistrationModalService {

  constructor() { }

  state$ = new BehaviorSubject<{ visible: boolean, action: Function}>({
    visible: false,
    action: () => {},
  });

  showModal(action: Function = () => {}) {
    this.state$.next({
      visible: true,
      action: action, 
    })
  }

  close() {
    this.state$.next({visible: false, action: () => {}})
  }
}
