import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {
  RefreshTokenResponse
} from "../../../shared/interfaces/api-services/auth/refresh-token/refresh-token-response.interface";
import {TokenService} from "../../../shared/services/auth-services/token.service";
import {Store} from "@ngrx/store";
import {loginSuccess} from "../../../store/actions/auth.actions";
import { AuthApiService } from 'src/app/shared/services/apiServices/authApiService/auth-api.service';
import { SuccessModalService } from 'src/app/shared/services/SuccessService/success-modal.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStatusCode } from 'src/app/shared/enums/erros-status-code.enum';

@Component({
  selector: 'mvm-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit, OnDestroy{
  public resendValidationEmailForm!: FormGroup
  private destroy$ = new Subject<void>()
  private userToken?: string
  private error?: string
  public isRequesting: boolean = false
  public isResendingValidationToken: boolean = false
  public isResendingValidationTokenDone: boolean = false
  public email: string = ""

  constructor(
      private _http: HttpClient,
      private route: ActivatedRoute,
      private _router: Router,
      private _tokenService: TokenService,
      private store: Store,
      private _authApiService: AuthApiService,
      private _successModalService: SuccessModalService,
      private _localize: LocalizeRouterService,
      private _fb: FormBuilder,

  ) {
    this.resendValidationEmailForm = this._fb.group({
      emailAddress: ['', [
        Validators.required, Validators.email
      ]],
    })
  }

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.userToken = params['token']
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // todo: refactor, its only a prototype
  onSubmit() {
    this.isRequesting = true
    if(this.isResendingValidationToken) {
      this._markAllInputs()

      if (!this.resendValidationEmailForm.valid) { return; }

      this._authApiService.resendValidationEmail(this.resendValidationEmailForm.get('emailAddress')?.value, this)

    } else {
      this._authApiService.validate(this.userToken!, this)
    }
  }

  /* handles success validate request */
  public handleSuccessResponse(res: any): void {
    this.isRequesting = false

    if(this.isResendingValidationToken) {
      this.resendValidationEmailForm.reset()
      this.isResendingValidationTokenDone = true
      this._successModalService.showSuccess('Az aktiváló e-mail újraküldése sikeres!')
    } else {
      this._authApiService.handleLogin(res, "", false)
      this._successModalService.showSuccessTranslate('dynamic.text5')
      /* in case of success we need to show a modal which is under creation TODO */
      setTimeout(() => {
        let translatedPath: any = this._localize.translateRoute('/');
        this._successModalService.close()
        this._router.navigate([translatedPath])
      }, 2500)
    }
  }

  /* handles failed validate request */
  public handleErrorResponse(err: any): void {
    this.isRequesting = false
    this.error = err.error.error_msg
    if(!this.isResendingValidationToken) {
      if(err.error.status_code == ErrorStatusCode.EXPIRED_EMAIL_VALIDATION_TOKEN) {
        /* le kell cserélni a megerősítő gombot újraküldőre */
        this.isResendingValidationToken = true
      }
    }
  }


  /* returns validation errors */
  public getError(attribute: string, validationRule: string) {
    return this.resendValidationEmailForm.get(attribute)?.touched && this.resendValidationEmailForm.get(attribute)?.errors?.[validationRule]
  }

  private _markAllInputs() {
    Object.keys(this.resendValidationEmailForm.controls).map((input) => {
      this.resendValidationEmailForm.get(input)?.markAsTouched()
    })
  }
}
