<div class="cart-item {{backgroundColor}}">
  <div class="cart-item-top">
    <div class="title-wrapper flex justify-between items-center no-wrap">
      <h2 class="program-title text-h1 alt text-white">{{title}}</h2>
      <svg-icon name="trash-white" class="cursor-pointer" (click)="deleteTicketTypes()"></svg-icon>
    </div>
    <p class="ticket-type text-default text-white">{{ name }}</p>
  </div>
  <div class="cart-item-bottom">
    <div class="flex justify-between w-full">
      <p class="text-title text-white">{{cartTicket?.number_of_tickets}} {{'quantity' | translate}}</p>
      <p class="text-title text-white">{{cartTicket?.sum_price}}</p>
    </div>
    <div class="flex justify-between w-full">
      <p [routerLink]="['/ticket-modify'] | localize" (click)="setModifyTicket()" [ngStyle]="edit ? {'visibility': 'visible'} : {'visibility': 'hidden'}" class="cursor-pointer underline text-default text-white">{{'modify' | translate}}</p>
      <p class="text-default text-white ">{{cartTicket?.price}}</p>
    </div>
  </div>
</div>

