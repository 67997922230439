import { Injectable } from '@angular/core';
import { LoadDataInterface } from 'src/app/shared/interfaces/service/load-data-interface';
import { ApiRequestService } from '../apiRequestService/api-request.service';
import {Advert} from "../../../interfaces/home/advert.model";
import {Program} from "../../../model/program.model";
import {Store} from "@ngrx/store";
import * as HomeActions from "../../../../store/actions/home.actions";

interface HomeResponse {
  data: {
    events: Program[],
    cover_image: string, 
  }
}

@Injectable({
  providedIn: 'root'
})
export class HomepageApiService implements LoadDataInterface {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store
  ) { }

  public loadData() {
    this._apiRequestService.getRequest('homepage', (res) => {this.handleSuccess(res)})
  }

  private handleSuccess(res: HomeResponse) {
    this._store.dispatch(HomeActions.homeLoaded({ events: res.data.events, cover_image : res.data.cover_image }))
  }
}
