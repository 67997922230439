import { CommonModule } from '@angular/common';
import { Component, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { SocialRegistrationModalService } from '../../services/social-registration-modal/social-registration-modal.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CheckboxComponent } from '../input/checkbox/checkbox.component';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorComponent } from '../input/error/error.component';
import { ButtonSaveComponent } from '../buttons/button-save/button-save.component';

@Component({
  selector: 'mvm-social-registration-modal',
  templateUrl: './social-registration-modal.component.html',
  styleUrls: ['./social-registration-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CheckboxComponent, ErrorComponent, ButtonSaveComponent, TranslateModule],
})
export class SocialRegistrationModalComponent {
  visible: boolean = true
  private listener?: () => void
  private subscription?: Subscription;
  action: Function = () => {}
  public registrationForm!: FormGroup
  public isRequesting: boolean = false
  public done: boolean = false

  constructor(
    private renderer: Renderer2,
     private service: SocialRegistrationModalService,
     private _fb: FormBuilder,
     ) {
    this.subscription = this.service.state$.subscribe(state => {
      this.visible = state.visible
      this.action = state.action

      if (state.visible) {
        this.listener = this.renderer.listen("document", "keyup.escape", _e => {
          this.close()
        })
      } else {
        this.close()
      }
    })

    this.registrationForm = this._fb.group({
      termsAndConds: [false, Validators.requiredTrue]
    })
  }

  open(message: string) {
    this.visible = true
  }

  close() {
    this.visible = false
    this.listener?.()
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }


  public onSubmit(): void {
    /* mark all inputs */
    this._markAllInputs()

    /* if form is invalid */
    if (!this.registrationForm.valid) { return; }

    /* set isrequesting */
    this._requestStarted()

    /* sent data */
    this.action()
  }

  private _markAllInputs() {
    Object.keys(this.registrationForm.controls).map((input) => {
      this.registrationForm.get(input)?.markAsTouched()
    })
  }


  /* requesting started */
  private _requestStarted(): void {
    this.isRequesting = true
  }

  /* requesting finihsed */
  private _requestStopped(): void {
    this.isRequesting = false
  }


  /* returns validation errors */
  accepted: boolean = false;
  public getError(attribute: string, validationRule: string) {
    return this.registrationForm.get(attribute)?.touched && this.registrationForm.get(attribute)?.errors?.[validationRule]
  }
}
