import {animate, style, transition, trigger} from "@angular/animations";


export const growInOut = [
  trigger('growInOut', [
    transition(':enter', [
      style({ opacity: 0, position: 'relative', maxHeight: 0, overflow: 'hidden'}),
      animate('300ms', style({ maxHeight: 500, overflow: 'hidden', opacity: 1})),
    ]),
    transition(':leave', [
      style({ maxHeight: 500, overflow: 'hidden', opacity: 1 }),
      animate('300ms', style({ maxHeight: 0, overflow: 'hidden', opacity: 0 }))
    ])
  ]),
]
