import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from "@abacritt/angularx-social-login";
import { AuthApiService } from 'src/app/shared/services/apiServices/authApiService/auth-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginProvider } from 'src/app/shared/enums/login-provider.enum';
import { Store } from '@ngrx/store';
import { TokenService } from 'src/app/shared/services/auth-services/token.service';
import { logoutSuccess } from 'src/app/store/actions/auth.actions';
import { SocialRegistrationModalService } from 'src/app/shared/services/social-registration-modal/social-registration-modal.service';
import { Subject, last, takeUntil } from 'rxjs';
import { HelperService } from 'src/app/utils/helper.service';

@Component({
  selector: 'mvm-social-login-buttons',
  templateUrl: './social-login-buttons.component.html',
  styleUrls: ['./social-login-buttons.component.scss']
})
export class SocialLoginButtonsComponent implements OnInit, OnDestroy {
  private redirectUrl: string | null = null
  @Input() public type: "login" | "register" = "login"
  private destroy$ = new Subject<void>();
  private user: SocialUser = {} as SocialUser

  constructor(
    private socialAuthService: SocialAuthService,
    private authService: AuthApiService,
    private route: ActivatedRoute,
    private router: Router,
    private _store: Store,
    private _tokenService: TokenService,
    private _socialAuthService: SocialAuthService,
    private _socialRegistrationModal: SocialRegistrationModalService,
    private _helperService: HelperService,
  ) {
    this.redirectUrl = route.snapshot.queryParams['ref'] ?? ''
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    // watch for login logout
    this.socialAuthService.authState.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user
      /* user logged in */
      if (user) {
        /* login */
        if (this.type == 'login') {
          this.login(user)
        }
        /* register */
        else if (this.type == 'register') {
          this._socialRegistrationModal.showModal(
            (user: SocialUser) => this.privacyAccepted(),
          )
        }
      }
    });


  }

  public signInWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  public refreshToken(): void {
    //Once a user is logged in and an access token was obtained, the access token can be refreshed (revoked)
    this.socialAuthService.refreshAccessToken(GoogleLoginProvider.PROVIDER_ID);
  }

  public signInWithLinkedin(): void {
    // todo no linkedin in angularx-social-login package
  }

  public privacyAccepted() {
    if (this.user) {
      this.register(this.user)
    }
  }

  /* handles success subscription request */
  public handleSuccessResponse(res: any): void {
    this.authService.handleLogin(res, this.user.email)
    this._socialRegistrationModal.close()
  }

  /* handles failed subscription request */
  public handleErrorResponse(err: string): void {

  }

  private signOut(): void {
      this.logoutUser()
      this.router.navigate(['/'])
  }

  private logoutUser() {
    this.socialAuthService.signOut();
    this._store.dispatch(logoutSuccess())
    this._tokenService._removeTokens()
    this._socialAuthService.signOut()
  }

  private login(user: SocialUser) {
    const provider = this.getProviderFromResponse(user)
    const token = this.getTokenFromResponse(user, provider)
    this.authService.loginWithProvider(user.email, token, provider, () => {
      if (this.redirectUrl) {
        this.router.navigate([decodeURIComponent(this.redirectUrl)])
      }
    }, () => {
      this.logoutUser()
    })
  }

  private register(user: SocialUser) {
    const provider = this.getProviderFromResponse(user)
    const token = this.getTokenFromResponse(user, provider)
    const email = user.email
    const firstName = user.firstName
    const lastName = user.lastName
    const type = this._helperService.isParkingSite() ? 'parking' : 'default'

    /* show a term acceptation modal */

    this.authService.register({ 
      emailAddress: email, 
      firstName: firstName, 
      lastName: lastName, 
      token: token,
      termsAndConds: true, 
      provider: provider,
      type: type,
    }, 
    this)
  }

  private getTokenFromResponse(user: SocialUser, provider: string): string {
    switch (provider) {
      case LoginProvider.GOOGLE: return user.idToken;
      case LoginProvider.FACEBOOK: return user.authToken;
      default: return ''
    }
  }

  private getProviderFromResponse(user: SocialUser): LoginProvider {
    return user.provider as LoginProvider
  }


}
