import { trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { mobileNavbarFade } from '../../../shared/animations/navbar/mobile-navbar-fade.animation';
import { NavbarComponent } from "../../components/navbar/navbar.component";
import { MobileNavMenuComponent } from "../../../shared/components/mobile-nav-menu/mobile-nav-menu.component";
import { FooterComponent } from "../../components/footer/footer.component";
import { AsyncPipe, NgIf } from "@angular/common";
import { MobileMenuService } from "../../services/mobile-menu/mobile-menu.service";
import { HelperService } from 'src/app/utils/helper.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'mvm-core',
  standalone: true,
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  imports: [
    NavbarComponent,
    MobileNavMenuComponent,
    FooterComponent,
    NgIf,
    AsyncPipe
  ],
  animations: [
    trigger('fade', mobileNavbarFade)
  ]
})
export class CoreComponent implements OnInit {
  public hasFooter: boolean = true
  public hasNavbar: boolean = true
  @Input() inputHasFooter: boolean = true
  @Input() inputHasNavbar: boolean = true
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private menuService: MobileMenuService,
    private _helperService: HelperService,
    private route: ActivatedRoute,
  ) {
    this.handleHasNavbar()
    const webview = this._helperService.isWebView()
    if (webview) {
      this.hasNavbar = false
      this.hasFooter = false
    }
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleHasNavbar()
      }
    });

    this.mobileNavOpened.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      this._handleOverflow(event);
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private _handleOverflow(isMobileMenuOpen: boolean) {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = isMobileMenuOpen ? "hidden" : "auto"
    }
  }

  private handleHasNavbar() {
    /* only in desktop mode */
    if (this.route.snapshot.data['hasNavbar'] === false) {
      this.hasNavbar = false
      return
    }

    this.hasNavbar = true
  }

  public mobileNavOpened = this.menuService.getObservable()
}
