import {Injectable} from '@angular/core';
import {ApiRequestService} from "../apiRequestService/api-request.service";
import {Store} from "@ngrx/store";
import {DashboardResponse} from "../../../interfaces/dashboard/dashboard.types";
import {dashboardLoaded} from "../../../../store/actions/dashboard.actions";
import { HandleErrorAndSuccess } from 'src/app/shared/interfaces/common/handle-error-and-success';


@Injectable({
  providedIn: 'root'
})
export class DashboardApiService {

  constructor(private _apiRequestService: ApiRequestService,
              private _store: Store) {
  }

  loadData( self: HandleErrorAndSuccess) {
    /*this._apiRequestService.getRequest("dashboard", (res) => {
      this.dashboardSuccess(res)
    },undefined ,true)*/
    this._apiRequestService.postRequest("dashboard", {}, (res) => {
      this.dashboardSuccess(res)
      self.handleSuccessResponse(res)
    }, 
    (err) => { self.handleErrorResponse(err) }
    ,true)
  }

  private dashboardSuccess(res: DashboardResponse) {
    this._store.dispatch(dashboardLoaded({
      invoices: res.data.invoices, 
      events: res.data.events, 
      vip_message: res.data.vip_message,
      show_vip_functions: res.data.show_vip_functions ?? false,
    }))
  }
}
