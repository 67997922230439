import { Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import { trigger } from '@angular/animations';
import { simpleFade } from '../../../animations/buttons/simple-fade.animation';
import {AngularSvgIconModule} from "angular-svg-icon";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'mvm-button-save',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, TranslateModule],
  templateUrl: './button-save.component.html',
  styleUrls: ['./button-save.component.scss'],
  animations: [
    trigger('fade', simpleFade)
  ],
})
export class ButtonSaveComponent {

  @Input() saving!: boolean
  @Input() saved!: boolean
  @Input() savedText: string = "Módosítások mentve"
  @Input() defaultText?: string
  @Input() disabled!: boolean
}
