import {Component, Input} from '@angular/core';
import {slideAppearRotateAnimation} from 'src/app/shared/animations/home/slide-appear-rotate.animation';
import {ProgramTypes} from 'src/app/shared/enums/program-types.enum';
import {ContainerTypes} from "../../../../shared/components/container/container.component";
import {EventDetailed} from "../../../../shared/services/apiServices/detailsPageService/details-page.service";
import {ViewportScroller} from "@angular/common";

export interface ProgramDetails {
  performancer: string
  programTitle: string
  programType: ProgramTypes
  cover: string
  dates: {
    dayForHumans: string,
    day: string,
    date: string
    gateOpeningAt: string
    startsAt: string
  },
  highlightedText?: string
  description: string
}

@Component({
  selector: 'mvm-home-program-details',
  templateUrl: './home-program-details.component.html',
  styleUrls: ['./home-program-details.component.scss', './home-program-details.desktop.component.scss'],
  animations: slideAppearRotateAnimation
})
export class HomeProgramDetailsComponent {
  ContainerTypes = ContainerTypes

  public longDescriptionOpen: boolean = false
  @Input() programDetails: EventDetailed | null = null


  constructor(private scroller: ViewportScroller) {
  }
  public onShowMore() {
    this.longDescriptionOpen = !this.longDescriptionOpen
  }

  get digestedDescription(): string[] {

    return this.programDetails && this.programDetails.description ? this.programDetails.description.split('\n') : []
  }

  scrollToTickets() {
    this.scroller.scrollToAnchor("event-tickets")
  }
}








