import {Component, EventEmitter, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardProgramComponent} from "../cards/card-program/card-program.component";
import {RouterLink} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {ContainerComponent, ContainerTypes} from "../container/container.component";
import {TranslateModule} from "@ngx-translate/core";
import {LocalizeRouterModule} from "@gilsdav/ngx-translate-router";
import {Store} from "@ngrx/store";
import {selectIsLoggedIn} from "../../../store/selectors/auth.selector";
import {logoutSuccess} from "../../../store/actions/auth.actions";
import {TokenService} from "../../services/auth-services/token.service";
import {MobileMenuService} from "../../../core/services/mobile-menu/mobile-menu.service";
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
  selector: 'mvm-mobile-nav-menu',
  standalone: true,
  templateUrl: './mobile-nav-menu.component.html',
  styleUrls: ['./mobile-nav-menu.component.scss'],
  imports: [CommonModule, CardProgramComponent, RouterLink, FormsModule, ContainerComponent, TranslateModule, LocalizeRouterModule]
})
export class MobileNavMenuComponent {
  ContainerTypes = ContainerTypes
  loggedIn$ = this.store.select(selectIsLoggedIn)
  //@Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>()

  constructor(
    private store: Store, 
    private _tokenService: TokenService, 
    private menuService: MobileMenuService,
    private _socialAuthService: SocialAuthService,
    ) {
  }

  close() {
    //this.closed.emit(false)
    this.menuService.closeMenu()
  }

  logout() {
    this.store.dispatch(logoutSuccess())
    this._tokenService._removeTokens()
    this._socialAuthService.signOut()
    this.close()
  }
}
