import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {fadeInOut, fadeInOutSimple} from "../../../shared/animations/fadeInOut";

export interface InteractiveMapOptions {
  entrances: boolean
  vip: boolean
  bufe: boolean
  toilets: boolean
  others: boolean
  skyboxes: boolean
  medical: boolean
  clothes: boolean
}

@Component({
  selector: 'mvm-interactive-map',
  templateUrl: './interactive-map.component.html',
  styleUrls: ['./interactive-map.component.scss'],
  animations: fadeInOutSimple
})
export class InteractiveMapComponent implements OnInit, OnDestroy {
  private destroy = new Subject()

  public mapOptions!: FormGroup
  selectedNav: string = "lodgeLevel"
  points: InteractiveMapOptions = {
    bufe: false, entrances: false, others: false, skyboxes: false, toilets: false, vip: false, medical: false, clothes: false
  }

  constructor(private _fb: FormBuilder, private change: ChangeDetectorRef) {
    /*this.mapOptions = this._fb.group({
      ground: [false],
      lower: [false],
      lodge: [false],
      upper: [false]
    })*/
  }

  ngOnInit(): void {
    /*this.mapOptions.valueChanges.pipe(takeUntil(this.destroy)).subscribe((value: InteractiveMapOptions) => {
      this.change.detectChanges()
    })*/
  }

  ngOnDestroy(): void {
    this.destroy.next(true)
    this.destroy.complete()
  }

  changeSelectedMap(selected: "lowerLevel" | "upperLevel" | "groundLevel" | "lodgeLevel") {
    this.selectedNav = selected
    this.points = {
      bufe: false, entrances: false, others: false, skyboxes: false, toilets: false, vip: false, medical: false, clothes: false
    }
  }
}
