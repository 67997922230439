import {Injectable} from '@angular/core';
import {LoadDataInterface} from "../../../interfaces/service/load-data-interface";
import {ApiRequestService} from "../apiRequestService/api-request.service";
import {Store} from "@ngrx/store";
import {detailLoaded} from "../../../../store/actions/detail.actions";
import {Router} from "@angular/router";
import {LocalizeRouterService} from "@gilsdav/ngx-translate-router";
import {ImageLinks} from "../../../interfaces/common/image-links.model";


export type DetailResponse = {
  meta: MetaData
  data: {
    event: EventDetailed,
    tickets: Ticket[],
    other_events: EventSimple[]
  }
}

export type MetaData = {
  og_image: string,
  og_title: string,
  og_description: string,
}

export type EventDetailed = {
  label: string,
  can_be_purchased: boolean,
  image: {
    300: {
      jpg: string,
      webp: string
    },
    350: {
      jpg: string,
      webp: string
    },
    600: {
      jpg: string,
      webp: string
    }
  }
  date: {
    gate_opening: string,
    event_start: string,
    //day_of_week: string,
    //day: string,
    //year_month: string,
    year: string,
    month_day: string,
  }
  extra: string, 
  title: string,
  "lead": string,
  "description": string,
  is_seasonal: boolean,
}
export type EventSimple = {
  slug: string,
  label: string,
  image: {
    300: ImageLinks,
    350: ImageLinks,
    600: ImageLinks
  },
  date: string
  title: string,
  is_seasonal: boolean,
}
export type Ticket = {
  "is_available": boolean,
  "type": "skybox" | "platinum" | "gold",
  "image": string,
  "is_full": boolean,
  "name": string,
  "price": string,
  "raw_price": number,
  "max_number": number,
  "top_image": string,
  "description": {
    "label": string,
    "text": string
  }
}


@Injectable({
  providedIn: 'root'
})
export class DetailsPageService implements LoadDataInterface {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store,
    private _router: Router,
    private _localize: LocalizeRouterService
  ) {
  }


  loadData() {
    // need slug parameter --> this.loadDataWithSlug
    //this._apiRequestService.getRequest(`events/${eventSlug}`, this.handleSuccess)
  }

  loadDataWithSlug(slug: string) {
    if (slug) this._apiRequestService.getRequest(`events/${slug}`, (res) => {
      this.handleSuccess(res)
    }, () => {
      this.handleError()
    }, true)
  }

  handleSuccess(res: DetailResponse) {
    if (!res) {
      this._router.navigate([this._localize.translateRoute('/')])
      return
    }
    let o: any = res.data
    o.meta = res.meta

    this._store.dispatch(detailLoaded(o))
  }

  handleError() {
    this._router.navigate([this._localize.translateRoute('/')])
  }

  getParkingTicketsForEvent(slug: string) {

  }
}
