import {Component, OnDestroy} from '@angular/core';
import {ContainerComponent, ContainerTypes} from "../../../shared/components/container/container.component";
import {NavigationEnd, Router, RouterLink} from "@angular/router";
import {AngularSvgIconModule} from "angular-svg-icon";
import {LanguageChooserComponent} from "../../../shared/components/language-chooser/language-chooser.component";
import {TranslateModule} from "@ngx-translate/core";
import {AsyncPipe, NgClass, NgIf} from "@angular/common";
import {LocalizeRouterModule, LocalizeRouterService} from "@gilsdav/ngx-translate-router";
import {Store} from "@ngrx/store";
import {selectIsLoggedIn} from "../../../store/selectors/auth.selector";
import {logoutSuccess} from "../../../store/actions/auth.actions";
import {TokenService} from "../../../shared/services/auth-services/token.service";
import {CartApiService} from 'src/app/shared/services/apiServices/cartApiService/cart-api.service';
import {selectCartCart} from "../../../store/selectors/cart.selector";
import {FormsModule} from "@angular/forms";
import {filter, Subject, takeUntil} from "rxjs";
import {CheckoutCart} from "../../../shared/interfaces/checkout-cart/checkout-cart.models";
import {MobileMenuService} from "../../services/mobile-menu/mobile-menu.service";
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
  selector: 'mvm-navbar',
  standalone: true,
  templateUrl: './navbar.component.html',
  imports: [
    RouterLink,
    ContainerComponent,
    AngularSvgIconModule,
    LanguageChooserComponent,
    TranslateModule,
    NgClass,
    NgIf,
    LocalizeRouterModule,
    AsyncPipe,
    FormsModule
  ],
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnDestroy {
  ContainerTypes = ContainerTypes

  //@Output() mobileNavToggledChange: EventEmitter<boolean> = new EventEmitter<boolean>()
  //@Input() mobileNavToggled: boolean = false

  public open: boolean = false

  public isLoggedIn$ = this._store.select(selectIsLoggedIn)

  public cart$ = this._store.select(selectCartCart)
  destroy = new Subject<void>()

  constructor(
    private _store: Store,
    private _tokenService: TokenService,
    private _cartApiService: CartApiService,
    private aRoute: Router,
    private localize: LocalizeRouterService,
    private menuService: MobileMenuService,
    private _socialAuthService: SocialAuthService,
  ) {
  }

  ngOnInit() {
    this._cartApiService.loadCart()
    this.aRoute.events.pipe(filter(event => event instanceof NavigationEnd)).pipe(takeUntil(this.destroy)).subscribe(e => {
      this.menuService.closeMenu()
    })
    this.menuService.getObservable().pipe(takeUntil(this.destroy)).subscribe(b => {
      this.open = b
    })

  }

  public onHamburgerClicked($event: boolean) {
    //this.open = !this.open
    //this.mobileNavToggledChange.emit(this.open)
    /*if($event) {
      this.menuService.openMenu()
    }*/
    this.menuService.toggleMenu()
  }

  public logout(): void {
    this._store.dispatch(logoutSuccess())
    this._tokenService._removeTokens()
    this.aRoute.navigate(['/'])
    this._socialAuthService.signOut()
  }

  ngOnDestroy(): void {
    this.destroy.next()
    this.destroy.complete()
  }

  getCartCount(cart: CheckoutCart | null): number | undefined {
    if (cart) {
      let count = 0
      cart.events.forEach(e => {
        count += e.tickets.length
        count += e.parking_tickets?.length ?? 0
      })
      return count
    } else {
      return undefined
    }
  }
}
