<div class="ticketinfo">

  <div class="ticketinfo-title" (click)="open = !open">
    <p class="tx-ticketinfo">{{ label }}</p>
    <svg-icon class="span-ticketinfo" [@rotate]="open" name="triangle-white"></svg-icon>
  </div>
  <div [@openClose]="open">
    <p class="tx-ticketinfo-desc">
      {{ text }}
    </p>
  </div>
</div>
