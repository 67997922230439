<div id="parking-card">
    <div class="card-border ">
        <img alt="Ticket border" class="border-image" src="/assets/images/png/ticket/ticket-border-parking.png">
    </div>
    <div class="card-body">
        <!-- default view -->
        <div id="default-view" *ngIf="isDefaultView()">
            <img alt="Cover image" class="cover-image" src="/assets/images/png/ticket/ticket-cover-parking.png">
            <p>{{ "parking-landing.parking-card.parking-ticket" | translate }}</p>
            <button class="btn-yellow" (click)="stepToEventsList()">{{ "parking-landing.parking-card.choose-event" | translate }}</button>
        </div>
        <form [formGroup]="parkingForm" (ngSubmit)="!isRequesting ? onSubmit() : null">
            <!-- events list-->
            <div id="events-list-view" *ngIf="isEventsListView()">
                <svg-icon name="event-speaker"></svg-icon>
                <p id="view-title">{{ "parking-landing.parking-card.choose-event!" | translate }}</p>

                <div id="event-checkboxes">
                    <div *ngFor="let event of events$ | async" class="event-checkbox-holder">
                        <mvm-radio-button-with-label 
                            inputName="eventSlug" 
                            [inputId]="event.slug" 
                            [value]="event.slug"
                            [control]="$any(parkingForm.controls['eventSlug'])"
                            [label]="event.title"></mvm-radio-button-with-label>
                    </div>
                </div>

                <button type="button" class="btn-yellow" (click)="stepToParkingTicketsList()">{{ "parking-landing.parking-card.to-the-tickets" | translate }}</button>
                <button type="button" class="link" (click)="stepToDefault()">{{ "parking-landing.parking-card.back" | translate }}</button>

            </div>

            <!-- parking tickets list -->
            <div id="parking-tickets-list-view" *ngIf="isParkingTicketsListView()">
                <svg-icon name="parking-ticket"></svg-icon>
                <p id="view-title">{{ "parking-landing.parking-card.choose-category" | translate }}</p>
                <div id="parking-ticket-types-checkboxes">
                    <div *ngFor="let parkingTicketType of ticketTypes$ | async" class="parking-ticket-type">
                        <div class="parking-ticket-type-title">
                            <p>{{ parkingTicketType.title }}</p>
                            <span>{{ parkingTicketType.subtitle }}</span>
                        </div>
                        <app-chooser-quantity (quantityChange)="parkingTicketQuantityChanged($event, parkingTicketType.type)" [db]="true"></app-chooser-quantity>
                    </div>
                    <p *ngIf="(ticketTypes$ | async)?.length == 0" id="missing-parking-tickets">
                        {{ "parking-landing.parking-card.missing-parking-tickets" | translate }}
                    </p>   
                </div>
                <button *ngIf="(ticketTypes$ | async)?.length != 0" type="submit" class="btn-yellow" >{{ "parking-landing.parking-card.to-the-cart" | translate }}</button>
                <button type="button" class="link" (click)="stepToEventsList()">{{ "parking-landing.parking-card.back-to-events" | translate }}</button>
            </div>
        </form>
    </div>
</div>