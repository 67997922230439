import {Component, OnDestroy, Renderer2} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorModalService} from "../../services/errorService/error-modal.service";
import {Subscription} from "rxjs";
import { SuccessModalService } from '../../services/SuccessService/success-modal.service';

@Component({
  selector: 'mvm-modal-success',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal-success.component.html',
  styleUrls: ['./modal-success.component.scss']
})

export class ModalSuccessComponent implements OnDestroy {

  visible: boolean = true
  message: string = "Ez egy teszt error üzenet. Az e-mail cím nem található!"
  private listener?: () => void
  private subscription?: Subscription;

  warning: boolean = false

  constructor(private renderer: Renderer2, private service: SuccessModalService) {
    this.subscription = this.service.state$.subscribe(state => {
      this.visible = state.visible
      this.message = state.message
      this.warning = state.warning
      if (state.visible) {
        this.listener = this.renderer.listen("document", "keyup.escape", _e => {
          this.close()
        })
      } else {
        this.close()
      }
    })
  }


  open(message: string) {
    this.message = message
    this.visible = true
  }

  close() {
    this.visible = false
    this.listener?.()
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

}

