<mvm-container>
  <div class="flex flex-col items-center gap-40px container-61-31" id="food-programs">

    <svg-icon name="mvm" [svgStyle]="{'width.px':'190'}" [stretch]="true"></svg-icon>



    <p class="text-title text-white text-center px-30px w-full">{{'foodOrder.desc' | translate}}</p>


    <div id="events" class="flex flex-col gap-20px">
      <mvm-card-program *ngFor="let p of programs" [program]="p" class="w-full" [darkFood]="true"></mvm-card-program>
    </div>

    <button class="btn-text-yellow" [routerLink]="['/dashboard' | localize]">{{'foodOrder.back' | translate}}</button>
  </div>
</mvm-container>
