import {EventEmitter, Injectable} from '@angular/core';
import {HandleErrorAndSuccess} from "../../interfaces/common/handle-error-and-success";
import {AuthApiService} from "../apiServices/authApiService/auth-api.service";
import {RefreshTokenResponse} from "../../interfaces/api-services/auth/refresh-token/refresh-token-response.interface";
import {Store} from "@ngrx/store";
import {loginSuccess, logoutSuccess} from "../../../store/actions/auth.actions";

@Injectable({
  providedIn: 'root'
})
export class TokenService implements HandleErrorAndSuccess {

  public refreshingToken: boolean = false
  public $refreshDone: EventEmitter<boolean> = new EventEmitter<boolean>()

  constructor( private _store: Store) { }

  handleSuccessResponse(res: RefreshTokenResponse): void {
    this._setBearerToken(res.data.token)
    this._setRefreshToken(res.data.refresh_token)
    this._store.dispatch(loginSuccess({ user: res.data.user }))
    this._refreshDone()
  }

  handleErrorResponse(err: string): void {
    this._store.dispatch(logoutSuccess())
    this._refreshDone(false)
  }

  public _refreshDone(success: boolean = true) {
    this.$refreshDone.emit(success)
    this.refreshingToken = false
  }

  public _setBearerToken(bearer: string): void {
    localStorage.setItem('bearer_token', bearer)
  }

  public _setRefreshToken(token: string): void {
    localStorage.setItem('refresh_token', token)
  }

  public _retrieveBearerToken(): string | null {
    return localStorage.getItem('bearer_token')
  }

  public _retrieveRefreshToken(): string | null {
    return localStorage.getItem('refresh_token')
  }

  public _removeTokens(): void {
    localStorage.removeItem('bearer_token')
    localStorage.removeItem('refresh_token')
  }
}
