<div
  (click)="clicked()"
  class="button-wrapper"
  [ngClass]="{ 'checked': value }">
  <div class="flex items-center">
    <div class="rounded-checkbox-container">
      <div class="rounded-checkbox-outer-ring">
        <div
          class="rounded-checkbox-inner-ring"
          [ngClass]="{ 'checked': value }"
        ></div>
      </div>
    </div>
    <label [title]="name" [ngClass]="{'checked': value}">
      {{ name }}
    </label>
  </div>
</div>
