import { Component } from '@angular/core';
import {ContainerComponent, ContainerTypes} from "../../../shared/components/container/container.component";
import {RouterLink} from "@angular/router";
import {AngularSvgIconModule} from "angular-svg-icon";
import {TranslateModule} from "@ngx-translate/core";
import {LocalizeRouterModule} from "@gilsdav/ngx-translate-router";
import {selectIsLoggedIn} from "../../../store/selectors/auth.selector";
import {Store} from "@ngrx/store";
import {AsyncPipe, NgIf} from "@angular/common";

@Component({
  selector: 'mvm-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  imports: [
    ContainerComponent,
    RouterLink,
    AngularSvgIconModule,
    TranslateModule,
    LocalizeRouterModule,
    NgIf,
    AsyncPipe
  ],
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  ContainerTypes = ContainerTypes
  loggedIn = this.store.select(selectIsLoggedIn)

  constructor(private store: Store,) {
  }
}
