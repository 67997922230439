import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterLink} from "@angular/router";
import {AngularSvgIconModule} from "angular-svg-icon";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {LocalizeRouterModule} from "@gilsdav/ngx-translate-router";
import {CartEvent, CartParkingTicket, CartTicket} from "../../../interfaces/checkout-cart/checkout-cart.models";
import {CartApiService} from "../../../services/apiServices/cartApiService/cart-api.service";
import {Store} from "@ngrx/store";
import {modifyTicketSet} from "../../../../store/actions/modify-ticket.actions";
import {SuccessModalService} from "../../../services/SuccessService/success-modal.service";
import { CartItemType } from 'src/app/shared/enums/cartitem-type';

export enum CartitemCardBackgroundColors {
  TranslucentWhite = 'bg-translucent-white',
  SolidDark = 'bg-solid-dark'
}

@Component({
  selector: 'mvm-cart-item-card',
  standalone: true,
  imports: [CommonModule, RouterLink, AngularSvgIconModule, TranslateModule, LocalizeRouterModule],
  templateUrl: './cartitem-card.component.html',
  styleUrls: ['./cartitem-card.component.scss']
})
export class CartitemCardComponent {
  @Input() backgroundColor: CartitemCardBackgroundColors = CartitemCardBackgroundColors.TranslucentWhite
  @Input() edit: boolean = false
  @Input() cartTicket?: CartTicket | CartParkingTicket
  @Input() cartEvent?: CartEvent
  @Input() title?: string
  @Input() type: CartItemType = CartItemType.TICKET

  constructor(private cartService: CartApiService, private _store: Store, private warning: SuccessModalService, private translate: TranslateService) {
  }

  deleteTicketTypes() {
    this.cartService.deleteTicketTypeFromCart(this.cartEvent?.slug!, this.cartTicket!.type)
    /*if(this.cartTicket?.type) {
      this.cartService.deleteTicketTypeFromCart(this.cartEvent?.slug!, this.cartTicket?.type)
    } else {
      this.cartService.deleteTicketTypeFromCart(this.cartEvent?.slug!, this.cartTicket?.type)
    }*/
    this.translate.get('deleteTicket').subscribe(res1 => {
      this.translate.get('deleteTicket2').subscribe(res2 => {
        this.warning.showWarning(res1 + this.cartEvent?.name + res2)
      })
    })
  }

  isCartItem(obj: CartTicket | CartParkingTicket): obj is CartTicket {
    return (obj as CartTicket).seat_ids !== undefined;
  }

  setModifyTicket() {
    if (this.cartTicket && this.cartEvent && this.isCartItem(this.cartTicket)) {
      this._store.dispatch(modifyTicketSet({
        ticket: {
          ticketType: this.cartTicket.type,
          event_slug: this.cartEvent.slug
        }
      }))
    }
  }

  get name() {
    if(this.type == CartItemType.PARKINGTICKET) {
      return this.cartTicket?.name + ' (' + this.cartTicket?.type + ')'
    }

    return this.cartTicket?.name 
  }


}
