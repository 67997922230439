import { createAction, props } from '@ngrx/store';
import { Program } from "../../shared/model/program.model";
import { ParkingTypes } from 'src/app/shared/model/parking-types';

export const eventsLoaded = createAction(
    '[Events] Events for parking loaded',
    props<{ events: Program[] }>()
)

export const clearParkingTypes = createAction(
    '[Parking Types] Parking types cleared'
)

export const setParkingTypes = createAction(
    '[Parking Types] Parking types loaded',
    props<{ parkingTypes: ParkingTypes[] }>()
)