import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TicketModifyComponent} from "../../../shared/components/cards/ticket-modify/ticket-modify.component";
import {AngularSvgIconModule} from "angular-svg-icon";
import {Router, RouterLink} from "@angular/router";
import {CartLayoutComponent} from "../../layouts/cart-layout/cart-layout.component";
import {LocalizeRouterModule, LocalizeRouterService} from "@gilsdav/ngx-translate-router";

@Component({
  selector: 'mvm-ticket-modify-page',
  standalone: true,
    imports: [CommonModule, TicketModifyComponent, AngularSvgIconModule, RouterLink, CartLayoutComponent, LocalizeRouterModule],
  templateUrl: './ticket-modify-page.component.html',
  styleUrls: ['./ticket-modify-page.component.scss']
})
export class TicketModifyPageComponent {
  constructor(
    private router: Router,
    private localize: LocalizeRouterService
  ) {

  }

  closeModal() {
    this.router.navigate([this.localize.translateRoute(['/checkout-cart'])[0]])
  }
}
