import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewsletterApiService } from 'src/app/shared/services/apiServices/newsletterApiService/newsletter-api.service';
import { HandleErrorAndSuccess } from 'src/app/shared/interfaces/common/handle-error-and-success';
import {SuccessModalService} from "../../../../shared/services/SuccessService/success-modal.service";

@Component({
  selector: 'mvm-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements HandleErrorAndSuccess {
  public newsletterForm!: FormGroup
  public isRequesting: boolean = false
  public done: boolean = false

  constructor(
    private _fb: FormBuilder,
    private _newsletterApiService: NewsletterApiService,
    private successService: SuccessModalService
    ) {
    this.newsletterForm = this._fb.group({
      firstName: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.email]],
      termsAndConds: [false, Validators.requiredTrue]
    })
  }

  public onSubmit(): void {
    /* mark all inputs */
    this._markAllInputs()

    /* if form is invalid */
    if(!this.newsletterForm.valid) { return ;}

    /* set isrequesting */
    this._requestStarted()

    /* sent data */
    this._newsletterApiService.subscribeToNewsletter(this.newsletterForm.value, this)
  }

  /* handles success subscription request */
  public handleSuccessResponse(): void {
    this._requestStopped()
    this.done = true
    this.newsletterForm.reset()
    this.successService.showSuccessTranslate("dynamic.text1")
  }

  /* handles failed subscription request */
  public handleErrorResponse(err: string): void {
    this._requestStopped()
  }

  /* returns validation errors */
  public getError(attribute: string, validationRule: string) {
     return this.newsletterForm.get(attribute)?.touched && this.newsletterForm.get(attribute)?.errors?.[validationRule]
  }

  /* requesting started */
  private _requestStarted(): void {
    this.isRequesting = true
  }

  /* requesting finihsed */
  accepted: boolean = false
  private _requestStopped(): void {
    this.isRequesting = false
  }

  private _markAllInputs() {
    Object.keys(this.newsletterForm.controls).map((input) => {
      this.newsletterForm.get(input)?.markAsTouched()
    })
  }
}
