import { style, query, group, animate, sequence } from "@angular/animations";

export const loginToRegisterAnimation = [
    style({ position: 'relative', maxHeight: 851 }),
    query(':enter, :leave', [
      style({
        top: 0,
        left: 0,
        position: 'absolute',
        height: '100%',
        width: '100%'
      })
    ]),
    query('#register-social', [
        style({
            visibility: 'hidden',
        })
    ]),
    query(':leave', [
        query('.logo, .text-super-h1, .register-wrapper, form, .forgot-password-wrapper, .text-white', [
            style({ opacity: 1 }),
        ]),
    ]),
    query(':enter', [
        query('.text-super-h1, .login-wrapper, form', [
            style({ opacity: 0 }),
        ]),
    ]),
    sequence([
        query(':leave', [
            group([
                query('.logo, .text-super-h1, .register-wrapper, form, .forgot-password-wrapper, .text-white', [
                    animate('100ms ease', style({ opacity: 0 })),
                ]),
                query('.login', [
                    animate('150ms 100ms ease', style({ paddingTop: window.innerWidth < 1600 ? '48px' : '152px' }))
                ]),
            ]),
        ]),
        query(':enter', [
            query('.text-super-h1, .login-wrapper, form', [
                animate('150ms 100ms ease', style({ opacity: 1 }))
            ]),
        ]),
    ])
]

export const registerToLoginAnimation = [
    style({ position: 'relative', maxHeight: 893 }),
    query(':enter, :leave', [
      style({
        top: 0,
        left: 0,
        position: 'absolute',
        height: '100%',
        width: '100%'
      })
    ]),
    query('#login-social', [
        style({
            visibility: 'hidden'
        })
    ]),
    query(':enter', [
        query('.logo, .text-super-h1, .register-wrapper, form, .forgot-password-wrapper, .text-white', [
            style({ opacity: 0 }),
        ]),
    ]),
    query(':leave', [
        query('.text-super-h1, .login-wrapper, form', [
            style({ opacity: 1 }),
        ]),
    ]),

    sequence([
        query(':leave', [
            group([
                query('.text-super-h1, .login-wrapper, form', [
                    animate('100ms ease', style({ opacity: 0}))
                ]),
                query('.text-super-h1', [
                    animate('150ms 100ms ease', style({ marginTop: window.innerWidth < 1600 ? '-46px' : '-180px' }))
                ]),
                query('.register', [
                    animate('150ms 100ms ease', style({ paddingTop: '208px' }))
                ]),
            ]),
        ]),
        query(':enter', [
            query('.logo, .text-super-h1, .register-wrapper, form, .forgot-password-wrapper, .text-white', [
                animate('150ms 100ms ease', style({ opacity: 1 })),
            ]),
        ]),
    ])
]