import {Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {fadeOut} from "../../animations/fadeInOut";

@Component({
  selector: 'mvm-cookie-banner',
  standalone: true,
    imports: [CommonModule, TranslateModule],
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
  animations: fadeOut
})
export class CookieBannerComponent implements OnInit {
  accepted: boolean = true;

  ngOnInit(): void {
    const cookie = localStorage.getItem('cookieAccept')
    this.accepted = cookie === 'accepted';
  }

  accept() {
    localStorage.setItem('cookieAccept', 'accepted')
    this.accepted = true
  }
}
