<div [ngClass]="state === 'close' ? 'card-vip-closed' : 'card-vip'">

  <svg-icon *ngIf="state != 'close'" class="absolute close" (click)="state = 'close'" name="close-white"></svg-icon>

  <div class="flex items-center w-full" [ngClass]="state === 'close' ? 'justify-between' : ''">
    <svg-icon *ngIf="state !== 'close'"  class="transition-closed" name="message"></svg-icon>
    <p class="text-white " [ngClass]="state === 'close' ? 'text-info-bold' : 'w-50 text-title'">{{'vipMessage.title' | translate}}</p>
    <svg-icon *ngIf="state === 'close'" (click)="state='default'" class="transition-3 rotate-180 cursor-pointer" name="arrow-yellow"></svg-icon>
  </div>

  <div class="flex flex-col gap-20px " [ngClass]="state === 'open' ? 'transition-vip-max' : 'transition-vip-min'">
    <div class="text-info-normal text-white" style="overflow: hidden" [innerHTML]="message"></div>
  </div>

  <div *ngIf="state !== 'close'" class="flex items-center justify-between btn-info-opener" style="margin-top: 20px" (click)="stateToggle()">
    <p class="text-white text-info-button">{{state === 'open' ? ('vipMessage.less' | translate) : ('vipMessage.more' | translate)}}</p>
    <svg-icon [ngClass]="state === 'open' ? '' : 'rotate-180'" class="transition-3"  name="arrow-yellow"></svg-icon>

  </div>
</div>
