import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {AuthApiService} from "../../../../shared/services/apiServices/authApiService/auth-api.service";
import { ErrorStatusCode } from 'src/app/shared/enums/erros-status-code.enum';

@Component({
  selector: 'mvm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public loginForm!: FormGroup
  private redirectUrl: string | null = null
  public isUserNotActivatedError: boolean  = false
  
  constructor(
    private _fb: FormBuilder,
    private authService: AuthApiService,
    private route: ActivatedRoute,
    private router: Router,
    ) {
    this.redirectUrl = route.snapshot.queryParams['ref'] ?? ''
    this.loginForm = this._fb.group({
      emailAddress: ['', [
        Validators.required, Validators.email
      ]],
      password: ['', Validators.required]
    })
  }

  public onSubmit(): void {
    this.authService.login(this.loginForm.value.emailAddress, this.loginForm.value.password, () => {
      this.loginForm.reset()
      if(this.redirectUrl) {
        this.router.navigate([decodeURIComponent(this.redirectUrl)])
      }
    }, (e: any) => {
      if(e.error.status_code == ErrorStatusCode.EXPIRED_EMAIL_VALIDATION_TOKEN) {
        this.isUserNotActivatedError = true
      }
    })
  }

  public getError(attribute: string, validationRule: string) {
    return this.loginForm.get(attribute)?.touched && this.loginForm.get(attribute)?.errors?.[validationRule]
  }
}
