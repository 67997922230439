import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParkingCardViews } from 'src/app/shared/enums/parking-card-views';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CheckboxComponent } from 'src/app/shared/components/input/checkbox/checkbox.component';
import { ChooserQuantityComponent } from 'src/app/shared/components/input/chooser-quantity/chooser-quantity.component';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Program } from "../../../../shared/model/program.model";
import { Store } from '@ngrx/store';
import { ParkingApiServiceService } from 'src/app/shared/services/apiServices/parkingApiService/parking-api-service.service';
import { selectParkingEvents, selectParkingTypes } from 'src/app/store/selectors/parking.selector';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RadioButtonComponent } from 'src/app/shared/components/input/radio-button/radio-button.component';
import { RadioButtonWithLabelComponent } from 'src/app/shared/components/input/radio-button-with-label/radio-button-with-label.component';
import { ErrorModalService } from 'src/app/shared/services/errorService/error-modal.service';
import { TicketTypes } from 'src/app/shared/enums/ticket-types.enum';
import { ParkingTypes } from 'src/app/shared/model/parking-types';
import { CartApiService } from 'src/app/shared/services/apiServices/cartApiService/cart-api.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Router } from '@angular/router';
import { HandleErrorAndSuccess } from 'src/app/shared/interfaces/common/handle-error-and-success';
import { CartTicketData } from 'src/app/shared/interfaces/checkout-cart/checkout-cart.models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'mvm-parking-card',
  standalone: true,
  imports: [
    CommonModule, 
    AngularSvgIconModule, 
    CheckboxComponent, 
    ChooserQuantityComponent,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonWithLabelComponent,
    TranslateModule,
  ],
  templateUrl: './parking-card.component.html',
  styleUrls: ['./parking-card.component.scss']
})
export class ParkingCardComponent implements HandleErrorAndSuccess{
  public events$: Observable<Program[]> = this._store.select(selectParkingEvents)
  public ticketTypes$: Observable<ParkingTypes[]> = this._store.select(selectParkingTypes)
  
  private destroy$ = new Subject<void>();
  public view: ParkingCardViews = ParkingCardViews.DEFAULT
  public parkingForm!: FormGroup
  public isRequesting: boolean = false
  public selectedParkingTicketTypes: Array<string> = []

  constructor(
    private _fb: FormBuilder,
    private _store: Store,
    private _parkingApiService: ParkingApiServiceService,
    private _errorModalService: ErrorModalService,
    private _cartApiService: CartApiService,
    private router: Router,
    private localize: LocalizeRouterService,
    ) {
      this.parkingForm = this._fb.group({
        eventSlug: ['', Validators.required],
      })
  }

  ngOnInit() {
    /* get events */
    this._parkingApiService.getEvents()

    /* subscribe to parking events */
    this.events$.pipe(takeUntil(this.destroy$)).subscribe()
    this.ticketTypes$.pipe(takeUntil(this.destroy$)).subscribe()
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSubmit(): void {    
    this.selectedParkingTicketTypes = Object.keys(this.parkingForm.value).filter((key: string) => {
      if(key == 'eventSlug') return false

      return this.parkingForm.value[key]
    })

    /* there is no selected parking ticket type */
    if(!this.selectedParkingTicketTypes.length) {
      this._errorModalService.showErrorTranslate("parking-landing.validation.parking-types-missing")
      return 
    }

    this.addParkingTicketToCart()

    /*selectedParkingTypes.map((key: string) => {
      const value = this.parkingForm.get(key)?.value
      this._cartApiService.addToCart(, this)
    })*/
  }

  addParkingTicketToCart() {
    /* get first key */
    const firstKey = this.selectedParkingTicketTypes.shift()

    /* get the value of the selected key */
    const value = this.parkingForm.value[firstKey!]

    /* add to cart */
    const ticketData = {
      event_slug : this.parkingForm.value['eventSlug'],
      parking_tickets : {
        number_of_parking_tickets: value,
        parking_ticket_type: firstKey
      }
    } as CartTicketData

    this._cartApiService.addToCart(ticketData, this)
  }

  public handleSuccessResponse() {
    /* if there is more add them */
    if(this.selectedParkingTicketTypes.length) {
      this.addParkingTicketToCart()
    }
    /* if there is no more then redirects to cart */
    else {
      this.router.navigate([this.localize.translateRoute('/cart')])
    }
  }

  public handleErrorResponse() {

  }

  public stepToEventsList() {
    this.view = ParkingCardViews.EVENTS_LIST
  }

  public stepToParkingTicketsList() {
    /* if eventSlug is not valid */
    if(!this.parkingForm.controls['eventSlug'].valid) {
      this._errorModalService.showErrorTranslate("parking-landing.validation.event-missing")
      return 
    }

    this._parkingApiService.getParkingTickets(this.parkingForm.controls['eventSlug'].value, this)
  }

  public handleParkingTypeSuccess(res: any) {
     /* next step */
     this.view = ParkingCardViews.PARKING_TICKETS_LIST
     
     /* creating form dynamically */
     let group: any = {}
     res.data.parking_tickets.map((parkingTicket: ParkingTypes) => {
      group[parkingTicket.type] = new FormControl(0, Validators.pattern('\d{1,}'))
    })
    group['eventSlug'] = new FormControl(this.parkingForm.get('eventSlug')?.value, Validators.required)
    this.parkingForm = new FormGroup(group);
  }

  public parkingTicketQuantityChanged(quantity: number, ticketType: string) {
    this.parkingForm.get(ticketType)?.setValue(quantity)
  }

  public stepToDefault() {
    this.view = ParkingCardViews.DEFAULT
  }

  public isDefaultView(): boolean {
    return this.view == ParkingCardViews.DEFAULT
  }

  public isEventsListView(): boolean {
    return this.view == ParkingCardViews.EVENTS_LIST
  }

  public isParkingTicketsListView(): boolean {
    return this.view == ParkingCardViews.PARKING_TICKETS_LIST
  }
}
