import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {AngularSvgIconModule} from "angular-svg-icon";
import {Invoice} from "../../interfaces/dashboard/dashboard.types";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'mvm-bills',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, TranslateModule],
  templateUrl: './bills.component.html',
  styleUrls: ['./bills.component.scss']
})
export class BillsComponent {

  @Input() bills: Invoice[] = []

}
