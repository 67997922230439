import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ModifyTicketState} from "../states/modify-ticket.state";

const modifyFeatureKey = 'modifyTicket';

export const selectModify = createFeatureSelector<ModifyTicketState>(modifyFeatureKey)

export const selectModifyTicket = createSelector(
  selectModify,
    (state: ModifyTicketState) => state.ticket
)
