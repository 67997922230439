import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DashboardState} from "../states/dashboard.state";

const dashboardFeatureKey = 'dashboard';

export const selectDashboard = createFeatureSelector<DashboardState>(dashboardFeatureKey)

export const selectDashboardEvents = createSelector(
    selectDashboard,
    (state: DashboardState) => state.events
)

export const selectDashboardInvoices = createSelector(
  selectDashboard,
    (state: DashboardState) => state.invoices
)

export const selectDashboardVipMessage = createSelector(
  selectDashboard,
  (state: DashboardState) => state.vip_message
)


export const selectDashboardShowVipFunctions = createSelector(
  selectDashboard,
  (state: DashboardState) => state.show_vip_functions
)