
<!--label class="container">{{name}}
  <input
    type="checkbox"
    [formControl]="checked"
    (blur)="_onTouched"
    (input)="_onTouched"
    >
  <span class="checkmark" [ngClass]="{ 'rounded': rounded }"></span>
</label-->

<div [class]="'rounded-checkbox-container ' + style">
  <div [class]="'rounded-checkbox-outer-ring ' + color"  [ngClass]="{'square' : !rounded}" >
    <div
      class="rounded-checkbox-inner-ring"

      (click)="onControlClicked($event)"
      (input)="_onTouched()"
      (blur)="_onTouched()"
      [ngClass]="{ 'checked': checked }"
      ></div>
  </div>
</div>
