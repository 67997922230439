import { Injectable } from '@angular/core';
import { ApiRequestService } from '../apiRequestService/api-request.service';
import { Store } from '@ngrx/store';
import * as ParkingActions from "../../../../store/actions/parking.actions";
import {Program} from "../../../model/program.model";
import { HandleErrorAndSuccess } from 'src/app/shared/interfaces/common/handle-error-and-success';


interface HomeResponse {
  data: Program[]
}

@Injectable({
  providedIn: 'root'
})

export class ParkingApiServiceService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store,
  ) { }

  public getEvents() {
    this._apiRequestService.getRequest('homepage', 
      (res) => { this.handleSuccess(res) },  
      () => {},
      true
    )
  }

  private handleSuccess(res: HomeResponse) {
    this._store.dispatch(ParkingActions.eventsLoaded({ events: res.data }))
  }

  public getParkingTickets(eventSlug: string, self: any) {
    this._store.dispatch(ParkingActions.clearParkingTypes())
    this._apiRequestService.getRequest(`events/${eventSlug}/parking`, 
      (res) => { this.handleSuccessTicketTypes(res); self.handleParkingTypeSuccess(res) },  
      () => {},
      true
    )
  }

  private handleSuccessTicketTypes(res: any) {
    const parkingTypes = res.data.parking_tickets
    this._store.dispatch(ParkingActions.setParkingTypes({parkingTypes: parkingTypes}))
  }
}
