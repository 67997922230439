<a
        [routerLink]="['/events'] | localize"
        [queryParams]="queryParams"
        [ngClass]="{
            'btn-color-blue' : color === Colors.blue,
            'btn-color-green': color === Colors.green,
            'btn-color-yellow': color === Colors.yellow,
            'selected': selected
        }"
        [ngStyle]="{ 'border-color': color, 'color': color }">
    {{ text }}
</a>
