<div class="flex">
  <section class="description">
    <h2 class="text-h1">{{'approach.text1' | translate}}</h2>
    <h3 class="text-tag">{{'approach.text2' | translate}}</h3>
    <ul>
      <li class="text-default">{{'approach.text3' | translate}}</li>
      <li class="text-default">{{'approach.text4' | translate}}</li>
      <li class="text-default">{{'approach.text5' | translate}}</li>
    </ul>
    <h3 class="text-tag">{{'approach.text6' | translate}}</h3>
    <p class="text-default">{{'approach.text7' | translate}}</p>
    <h3 class="text-tag">{{'approach.lime' | translate}}</h3>
    <p class="text-default">{{'approach.text9' | translate}}</p>
    <h3 class="text-tag">{{'approach.text10' | translate}}</h3>
    <p class="text-default">{{'approach.text11' | translate}}</p>
    <p class="text-default">{{'approach.text12' | translate}}</p>
  </section>
  <section class="map">
    <img class="parking-hero" src="{{ 'yellow-map' | translate }}" alt="Parking illustration">
  </section>
</div>

