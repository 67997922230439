import {Component} from '@angular/core';
import {ProgramTypes} from "../../../shared/enums/program-types.enum";
import {Event} from "../../../shared/interfaces/events/events.types";

@Component({
  selector: 'mvm-food-order-programs',
  templateUrl: './food-order-programs.component.html',
  styleUrls: ['./food-order-programs.component.scss']
})
export class FoodOrderProgramsComponent {

  programs: Event[] = []

}
