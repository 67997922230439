import { Injectable } from '@angular/core';
import { ResetPasswordBody } from 'src/app/shared/interfaces/api-services/auth/reset-password/reset-password-body';
import { HandleErrorAndSuccess } from 'src/app/shared/interfaces/common/handle-error-and-success';
import { ApiRequestService } from '../apiRequestService/api-request.service';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordApiService {

  constructor(
    private _apiRequestService: ApiRequestService,
  ) { }

  public resetPassword(resetPasswordBody: ResetPasswordBody, self: HandleErrorAndSuccess ) {

    this._apiRequestService.postRequest('resetpassword', 
    resetPasswordBody, 
      (res) => { self.handleSuccessResponse(res) },  
      (err) => { self.handleErrorResponse(err) },
      true
    )
  } 
}
