<div class="wrapper">
  <h2 class="text-h1">{{'map.title' | translate}}</h2>
  <p class="text-default">{{'map.desc' | translate}}</p>
  <div class="content-map">

    <div class="map-controls">
      <div class="form-controls">
        <mvm-bordered-checkbox-button [value]="selectedNav === 'groundLevel'" (valueChange)="changeSelectedMap('groundLevel')"
                                      name="{{'map.nav1' | translate}}"></mvm-bordered-checkbox-button>
        <mvm-bordered-checkbox-button [value]="selectedNav === 'lowerLevel'" (valueChange)="changeSelectedMap('lowerLevel')"
                                      name="{{'map.nav2' | translate}}"></mvm-bordered-checkbox-button>
        <mvm-bordered-checkbox-button [value]="selectedNav === 'lodgeLevel'" (valueChange)="changeSelectedMap('lodgeLevel')"
                                      name="{{'map.nav3' | translate}}"></mvm-bordered-checkbox-button>
        <mvm-bordered-checkbox-button [value]="selectedNav === 'upperLevel'" (valueChange)="changeSelectedMap('upperLevel')"
                                      name="{{'map.nav4' | translate}}"></mvm-bordered-checkbox-button>
      </div>
    </div>

    <div class="map-placeholder relative">
        <!--<svg-icon [name]="selectedNav"></svg-icon>- "-->
      <div [ngClass]="{'invisible': selectedNav !=='groundLevel'}">
        <img src="/assets/images/svg/map/groundLevel.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.entrances" class="points" src="/assets/images/svg/map/ground-entrances.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.vip" class="points" src="/assets/images/svg/map/ground-vip.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.toilets" class="points" src="/assets/images/svg/map/ground-toilets.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.bufe" class="points" src="/assets/images/svg/map/ground-bufe.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.others" class="points" src="/assets/images/svg/map/ground-others.svg" alt="{{'map.interactive-map' | translate}}">
      </div>

      <div [ngClass]="{'invisible': selectedNav !== 'lodgeLevel'}">
        <img  src="/assets/images/svg/map/lodgeLevel.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.vip" class="points" src="/assets/images/svg/map/lodge-vip.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.skyboxes" class="points" src="/assets/images/svg/map/lodge-skyboxes.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.toilets" class="points" src="/assets/images/svg/map/lodge-toilets.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.others" class="points" src="/assets/images/svg/map/lodge-others.svg" alt="{{'map.interactive-map' | translate}}">
      </div>

      <div [ngClass]="{'invisible': selectedNav !== 'lowerLevel'}">
        <img  src="/assets/images/svg/map/groundLevel.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.vip" class="points" src="/assets/images/svg/map/lower-vip.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.entrances" class="points" src="/assets/images/svg/map/lower-entrances.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.toilets" class="points" src="/assets/images/svg/map/lower-toilets.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.clothes" class="points" src="/assets/images/svg/map/lower-clothes.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.bufe" class="points" src="/assets/images/svg/map/lower-bufe.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.medical" class="points" src="/assets/images/svg/map/lower-medical.svg" alt="{{'map.interactive-map' | translate}}">
      </div>

      <div [ngClass]="{'invisible': selectedNav !== 'upperLevel'}">
        <img  src="/assets/images/svg/map/groundLevel.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.entrances" class="points" src="/assets/images/svg/map/upper-entrances.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.toilets" class="points" src="/assets/images/svg/map/upper-toilets.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.medical" class="points" src="/assets/images/svg/map/upper-medical.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.clothes" class="points" src="/assets/images/svg/map/upper-clothes.svg" alt="{{'map.interactive-map' | translate}}">
        <img @fadeInOutSimple *ngIf="points.bufe" class="points" src="/assets/images/svg/map/upper-bufe.svg" alt="{{'map.interactive-map' | translate}}">
      </div>

    </div>
    <div class="map-controls lower-controls">
      <div class="form-controls">
        <mvm-bordered-checkbox-button *ngIf="['groundLevel', 'lowerLevel', 'upperLevel'].includes(selectedNav)" [value]="points.entrances" (valueChange)="points.entrances = $event"
                                      name="{{'map.button1' | translate}}"></mvm-bordered-checkbox-button>

        <mvm-bordered-checkbox-button [value]="points.vip" *ngIf="selectedNav !== 'upperLevel'" (valueChange)="points.vip = $event"
                                      name="{{'map.button2' | translate}}"></mvm-bordered-checkbox-button>

        <mvm-bordered-checkbox-button *ngIf="selectedNav === 'lodgeLevel'" [value]="points.skyboxes" (valueChange)="points.skyboxes = $event"
                                      name="{{'map.button4' | translate}}"></mvm-bordered-checkbox-button>

        <mvm-bordered-checkbox-button *ngIf="selectedNav !== 'lodgeLevel'" [value]="points.bufe" (valueChange)="points.bufe = $event"
                                      name="{{'map.button3' | translate}}"></mvm-bordered-checkbox-button>

        <mvm-bordered-checkbox-button [value]="points.toilets" (valueChange)="points.toilets = $event"
                                      name="{{'map.button5' | translate}}"></mvm-bordered-checkbox-button>

        <mvm-bordered-checkbox-button [value]="points.clothes" *ngIf="['lowerLevel', 'upperLevel'].includes(selectedNav)" (valueChange)="points.clothes = $event"
                                      name="{{'map.clothes' | translate}}"></mvm-bordered-checkbox-button>

        <mvm-bordered-checkbox-button [value]="points.medical" *ngIf="['lowerLevel', 'upperLevel'].includes(selectedNav)" (valueChange)="points.medical = $event"
                                      name="{{'map.medical' | translate}}"></mvm-bordered-checkbox-button>

        <mvm-bordered-checkbox-button [value]="points.others" *ngIf="['lodgeLevel', 'groundLevel'].includes(selectedNav)" (valueChange)="points.others = $event"
                                      name="{{'map.button6' | translate}}"></mvm-bordered-checkbox-button>
      </div>
    </div>
  </div>
</div>
