<div>

  <app-radio-pay [(checkedValue)]="checkedValue" (checkedValueChange)="paymentProviderChanged()" type="bankcard"></app-radio-pay>

  <div @growInOut *ngIf="checkedValue === 'bankcard'" class="bank-transfer mb-20px">
    <p class="tx-bank-detail">{{'bankTransfer.textSimple' | translate}} <a class="tx-bank-detail" href="https://simplepay.hu/vasarlo-aff/"  [attr.aria-label]="'https://simplepay.hu/vasarlo-aff/'">https://simplepay.hu/vasarlo-aff/</a></p>
  </div>
  <!--
  <app-radio-pay [(checkedValue)]="checkedValue" type="paypal"></app-radio-pay>  -->
  <app-radio-pay [(checkedValue)]="checkedValue"  (checkedValueChange)="paymentProviderChanged()" type="banktransfer"></app-radio-pay>
  <div @growInOut *ngIf="checkedValue === 'banktransfer'" class="bank-transfer mb-20px">
    <p class="tx-bank-detail">{{'bankTransfer.textBankTransfer' | translate}}</p>
  </div>

  <!--div @growInOut *ngIf="checkedValue === 'banktransfer'" class="bank-transfer">
    <p class="tx-bank-title">{{'bankTransfer.text1' | translate}}</p>
    <div>
      <p class="tx-bank-detail">{{'bankTransfer.text2' | translate}}</p>
      <p class="tx-bank-detail">{{'bankTransfer.text3' | translate}}</p>
      <p class="tx-bank-detail">{{'bankTransfer.text4' | translate}}</p>
      <p class="tx-bank-detail">{{'bankTransfer.text5' | translate}}</p>
    </div>
    <p class="tx-bank-detail">{{'bankTransfer.text6' | translate}}</p>
  </div>-->

</div>
