import { Injectable } from '@angular/core';
import {ApiRequestService} from "../apiRequestService/api-request.service";
import {SuccessModalService} from "../../SuccessService/success-modal.service";


export type SendTicketBody = {
  emailAddress: string,
  id: string,
  firstName: string
}
@Injectable({
  providedIn: 'root'
})
export class SendTicketService {

  constructor(private _apiRequestService: ApiRequestService, private successService: SuccessModalService) { }

  sendSimpleTicket(sendTicket: SendTicketBody) {
    this._apiRequestService.putRequest('ticket/send', sendTicket, (res) => {
      this.successService.showSuccessTranslate('ticketSent')
    }, undefined, true)
  }

  sendParkingTicket(sendTicket: SendTicketBody) {
    this._apiRequestService.putRequest('parking-ticket/send', sendTicket, (res) => {
      this.successService.showSuccessTranslate('ticketSent')
    }, undefined, true)
  }
}
