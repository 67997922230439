import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ContainerTypes} from 'src/app/shared/components/container/container.component';
import {TicketTypes} from 'src/app/shared/enums/ticket-types.enum';
import {Store} from "@ngrx/store";
import {
  DetailsPageService,
  EventSimple
} from "../../../shared/services/apiServices/detailsPageService/details-page.service";
import {
  selectDetailEvent, selectDetailMeta,
  selectDetailOtherEvents,
  selectDetailTickets
} from "../../../store/selectors/detail.selector";
import {selectCartCart} from "src/app/store/selectors/cart.selector"
import {ActivatedRoute} from "@angular/router";
import {Subject, Subscription, take, takeUntil} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {Event} from "../../../shared/interfaces/events/events.types";
import {ScrollingCardService} from "../../services/scrolling/scrolling-card.service";
import {Meta, Title} from "@angular/platform-browser";
import {CheckoutCart} from "../../../shared/interfaces/checkout-cart/checkout-cart.models";
import { HelperService } from 'src/app/utils/helper.service';

@Component({
  selector: 'mvm-program-details',
  templateUrl: './program-details.component.html',
  styleUrls: ['./program-details.component.scss'],
})
export class ProgramDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  ContainerTypes = ContainerTypes

  event$ = this.store.select(selectDetailEvent)
  meta$ = this.store.select(selectDetailMeta)
  tickets$ = this.store.select(selectDetailTickets)
  other_events$ = this.store.select(selectDetailOtherEvents)
  cart$ = this.store.select(selectCartCart)
  showDesktopSeatMap: boolean = false
  desktopSeatMapSelectedTicketType: "gold" | "platinum" | "skybox" = "gold"

  private destroy$ = new Subject<void>()

  private cart: any = {}
  public event: any = {}
  public isWebView: boolean = false
  public availableTicketTypes: TicketTypes[] = [
    TicketTypes.Skybox,
    TicketTypes.VipGold,
    TicketTypes.VipPlatinum
  ]
  slug: string = ""

  constructor(
    private _detailPageService: DetailsPageService,
    private store: Store,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private scrollingService: ScrollingCardService,
    private title: Title,
    private metaService: Meta,
    private _helperService: HelperService,
  ) {
    /* is web view */
    this.isWebView = _helperService.isWebView()
  }

  scrolling = false

  ngOnInit(): void {    
    this.getUrlParamAndData()
    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.getUrlParamAndData())
    this.meta$.pipe(takeUntil(this.destroy$)).subscribe(e => {
      if(e?.og_title && e.og_description){
        this.setSeo(e?.og_title, e?.og_description, e?.og_image)
      }
    })

    this.cart$.pipe(takeUntil(this.destroy$)).subscribe(c => {
      this.cart = c
    })

    this.event$.pipe(takeUntil(this.destroy$)).subscribe(e => {
      this.event = e
    })
  }

  getNumberOfTicketsForEventAndType(ticketType: string) {
    const eventSlug = this.event?.slug ?? ''

    const events = this.cart?.events

    if(!events) return  0

    let event: any = false
    /* get the selected event */
    events.map((e: any) => {
      if(e.slug == eventSlug) {
        event = e
      }
    })

    if(!event) return 0

    const tickets = event.tickets
    let ticket: any = false
    /* get the selected ticket type */
    tickets.map((t: any) => {
      if(t.type == ticketType) {
        ticket = t
      }
    })

    if(!ticket) return 0

    if(ticket.type ==='skybox') {
      return ticket.skybbox_ids.length
    }

    return ticket.seat_ids.length
  }

  setSeo(title: string, desc: string, img: string) {
    this.metaService.updateTag({property: 'og:title', content: title})
    this.metaService.updateTag({property: 'og:description', content: desc})
    this.metaService.updateTag({property: 'og:image', content: img})
    this.title.setTitle(title)
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    document.body.style.overflow = "";
  }

  getUrlParamAndData() {
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
          this.slug = params['slug']
          this._detailPageService.loadDataWithSlug(params['slug'])
        }
      );
  }

  getTicketType(type: string): TicketTypes {
    switch (type) {
      case "skybox":
        return TicketTypes.Skybox
      case "gold":
        return TicketTypes.VipGold
      case "platinum":
        return TicketTypes.VipPlatinum
      case "parking":
        return TicketTypes.Vip
      default:
        return TicketTypes.Skybox
    }
  }

  private addSliderListener() {
    const slider = document.getElementById('details-slider')
    if (slider) {
      this.scrolling = false;
      this.scrollingService.scrolling.next(false)
      let startX: number;
      let scrollLeft: number;


      slider.addEventListener('mousedown', (e) => {
        this.scrolling = true;
        this.scrollingService.scrolling.next(true)
        e.preventDefault()
        e.stopImmediatePropagation()
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener('mouseleave', () => {
        this.scrolling = false;
        this.scrollingService.scrolling.next(false)
      });
      slider.addEventListener('mouseup', (e) => {
        this.scrolling = false;
        this.scrollingService.scrolling.next(false)
        e.stopImmediatePropagation()
        e.preventDefault()
        e.stopPropagation()
      });
      slider.addEventListener('mousemove', (e) => {
        if (!this.scrolling) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX);
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }

  private addTicketsSliderListener() {
    const slider = document.getElementById('tickets-slider')
    if (slider) {
      this.scrolling = false;
      this.scrollingService.scrollingTickets.next(false)
      let startX: number;
      let scrollLeft: number;


      slider.addEventListener('mousedown', (e) => {
        this.scrolling = true;
        this.scrollingService.scrollingTickets.next(true)
        e.preventDefault()
        e.stopImmediatePropagation()
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener('mouseleave', () => {
        this.scrolling = false;
        this.scrollingService.scrollingTickets.next(false)
      });
      slider.addEventListener('mouseup', (e) => {
        this.scrolling = false;
        this.scrollingService.scrollingTickets.next(false)
        e.stopImmediatePropagation()
        e.preventDefault()
        e.stopPropagation()
      });
      slider.addEventListener('mousemove', (e) => {
        if (!this.scrolling) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX);
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }

  getEventFromEventSimple(p: EventSimple): Event {
    return p as Event
  }

  getSelectedTicketNumber(cart: CheckoutCart | null): number {
    let n = 0
    cart?.events?.find(e => e.slug === this.event.slug)?.tickets.forEach(t => {
      n += t.number_of_tickets
    })
    return n;
  }

  getSelectedTicketsPrice(cart: CheckoutCart | null): number {
    const price = cart?.events?.find(e => e.slug === this.event.slug)?.tickets.find((t: any) => t.type === this.desktopSeatMapSelectedTicketType)?.raw_price ?? 0;
    return (this.getSelectedTicketNumber(cart) * price)
  }

  ngAfterViewInit(): void {
    this.addSliderListener()
    //this.addTicketsSliderListener()
  }

  toggleDesktopSeatMap(b: boolean){
    if(b) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    this.showDesktopSeatMap = b;
  }
}


