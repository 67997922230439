import {createReducer, on} from "@ngrx/store";
import {initialOrderState} from "../states/order.state";
import {orderCleared, orderLoaded} from "../actions/order.actions";

export const orderReducer = createReducer(
    initialOrderState,
    on(orderLoaded, (state, {order}) => ({order: order})),
    on(orderCleared, () => ({ order: null }))
)

