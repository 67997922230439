import { Injectable } from '@angular/core';
import {ApiRequestService} from "../apiRequestService/api-request.service";


export type ProfileResponse = {
  "status_code": number,
  "success": boolean,
  "data": ProfileData
}

export type ProfileData = {
  "lastName": string,
  "firstName": string,
  "phone": string,
  "email": string,
  "personalCountry": string,
  "personalPostalCode": string,
  "personalCity": string,
  "personalAddressLine": string
  "billingCountry": string,
  "billingPostalCode": string,
  "billingCity": string,
  "billingAddressLine": string,
  "billingName": string
}

export type EditProfileData = {
  lastName: string

  firstName: string

  phone: string

  email: string

  personalCountry: string

  personalPostalCode: string

  personalCity: string

  personalAddressLine: string

  billingName: string

  billingCountry: string

  billingPostalCode: string

  billingCity: string

  billingAddressLine: string

  currentPassword: string

  newPassword?: string

  newPasswordAgain?: string
}

@Injectable({
  providedIn: 'root'
})
export class ProfileApiService {

  constructor(
      private _apiRequestService: ApiRequestService
  ) {}


  getProfileData(successCb: (res: ProfileResponse) => void) {
    this._apiRequestService.postRequest('profile', {}, successCb, undefined, true)
  }

  updateProfile(profile: EditProfileData,successCb: (res: ProfileResponse) => void) {
    this._apiRequestService.postRequest('profile/update', profile, successCb, undefined, true)
  }
}
