import { trigger, state, style, transition, animate, sequence } from "@angular/animations";

export const languageChooserAnimation = [
  trigger('rotate', [
    state('false', style({
      rotate: '180deg'
    })),
    state('true', style({
      rotate: '0deg'
    })),
    transition('true => false', animate('250ms 250ms')),
    transition('false => true', animate('250ms'))
  ]),
  trigger('openClose', [
    state('false', style({
      height: 0,
      opacity: 0,
      zIndex: 2
    })),
    state('true', style({
      height: '*',
      opacity: 1,
      zIndex: 2
    })),
    transition('false => *',
      sequence([
        style({
          display: 'flex'
        }),
        animate('250ms', style({
          height: '*',
        })),
        animate('250ms', style({
          opacity: 1,
        }))
      ]),
    ),
    transition('true => *',
      sequence([
        animate('250ms', style({
          opacity: 0,
        })),
        animate('250ms', style({
          height: 0,
        })),
        style({
          /*display: 'none'*/
        })
      ]),
    )
  ])
]
