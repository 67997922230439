<mvm-navbar *ngIf="hasNavbar" class="only-desktop"></mvm-navbar>
<mvm-container [type]="ContainerTypes.Navbar" class="only-mobile">
  <nav class="mobile-nav flex items-center"
    [ngClass]="{'justify-center': done, 'justify-between': !done, 'webview' : isWebView}  ">
    <a (click)="back()" *ngIf="!done" class="back flex cursor-pointer" [attr.aria-label]="'back' | translate">
      <svg-icon name="arrow-back"></svg-icon>
      <span class="text-white">{{'back' | translate}}</span>
    </a>
    <svg-icon name="mvm"></svg-icon>
  </nav>

</mvm-container>
<div class="black-bg">
  <div class="step-counter flex justify-center" [ngSwitch]="checkOutStep">
    <ng-container *ngSwitchCase="1">
      <div class="step active">
        <span>1</span>
        <!--<svg-icon name="tick-black"></svg-icon>-->
      </div>
      <div class="step">2</div>
      <div class="step">3</div>
      <div class="step">4</div>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <div class="step done">
        <span>1</span>
        <svg-icon name="tick-black"></svg-icon>
      </div>
      <div class="step active">2</div>
      <div class="step">3</div>
      <div class="step">4</div>
    </ng-container>
    <ng-container *ngSwitchCase="4">
      <div class="step done">
        <span>1</span>
        <svg-icon name="tick-black"></svg-icon>
      </div>
      <div class="step done">
        <svg-icon name="tick-black"></svg-icon>
      </div>
      <div class="step done">
        <svg-icon name="tick-black"></svg-icon>
      </div>
      <div class="step active">4</div>
    </ng-container>


  </div>
  <h1 class="text-super-h1 text-white text-center">
    {{ title }}
  </h1>
</div>
<div class="content">
  <ng-content></ng-content>

  <section class="how-to-approach" *ngIf="!isWebView">
    <mvm-container [type]="ContainerTypes.HowToApproach">
      <mvm-how-to-approach></mvm-how-to-approach>
    </mvm-container>
  </section>
</div>
<mvm-footer *ngIf="hasFooter"></mvm-footer>