import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mvm-maps',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent {

}
