import {Injectable} from '@angular/core';
import {Body as RegisterBodyInterface} from 'src/app/shared/interfaces/api-services/auth/register/body';
import {HandleErrorAndSuccess} from 'src/app/shared/interfaces/common/handle-error-and-success';
import {ApiRequestService} from '../apiRequestService/api-request.service';
import {Store} from "@ngrx/store";
import {loginSuccess} from "../../../../store/actions/auth.actions";
import {User} from "../../../interfaces/auth/user.model";
import {Router} from "@angular/router";
import {LocalizeRouterService} from "@gilsdav/ngx-translate-router";
import { environment } from 'src/environments/environment';
import { LoginProvider } from 'src/app/shared/enums/login-provider.enum';

type LoginResponse = {

  "status_code": number,
  "success": boolean,
  "data": {
    "token": string,
    "refresh_token": string,
    "user": User
  }
}

export type AuthMeResponse = {
  "status_code": number,
  "success": boolean,
  "data": {
    "user" : {
      "name": string
    },
    "invoice_details" : {
      invoice_country: string
      invoice_zip: string,
      invoice_city: string,
      invoice_address: string,
      vat_number: string,
      company: string,
    }
  }
}

@Injectable({
  providedIn: 'root'
})

export class AuthApiService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store,
    private router: Router,
    private localize: LocalizeRouterService
  ) {
  }

  public resendValidationEmail(email: string, self: HandleErrorAndSuccess) {
    let translatedPath: any = this.localize.translateRoute('/activate');
    const url = environment.domain + translatedPath

    this._apiRequestService.postRequest('validation-email/resend',
      {email: email, url: url},
      (_res) => {
        self.handleSuccessResponse(_res)
      },
      (err) => {
        self.handleErrorResponse(err)
      },
      true, true
    )
  }



  public validate(token: string, self: HandleErrorAndSuccess) {
    this._apiRequestService.postRequest('validate',
      {token: token},
      (_res) => {
        self.handleSuccessResponse(_res)
      },
      (err) => {
        self.handleErrorResponse(err)
      },
      true, true
    )
  }

  public register(registrationData: RegisterBodyInterface, self: HandleErrorAndSuccess) {
    /* temporary sending url for redirect */
    let translatedPath: any = this.localize.translateRoute('/activate');
    registrationData.url = environment.domain + translatedPath

    this._apiRequestService.postRequest('register',
      registrationData,
      (res) => {
        self.handleSuccessResponse(res)
      },
      (err) => {
        self.handleErrorResponse(err)
      },
      true
    )
  }

  login(email: string, password: string, cb: () => void = () => {}, err: (e: any) => void = () => {}) {
    this._apiRequestService.postRequest('login', {emailAddress: email, password: password}, (res) => {
        this.handleLogin(res, email)
        cb();
      }, (e) => {err(e)}, true, true
    )
  }

  loginWithProvider(email: string, token: string, provider: LoginProvider, cb: () => void = () => {}, err: () => void = () => {}) {
    this._apiRequestService.postRequest('login', {emailAddress: email, token: token, provider: provider}, (res) => {
        this.handleLogin(res, email)
        cb();
      }, () => {
        err()
      }, true
    )
  }

  handleLogin(res: LoginResponse, email: string = "" , needRedirect: boolean = true) {
    this._store.dispatch(loginSuccess({user: { name: res?.data?.user?.name ?? email }}))
    localStorage.setItem('bearer_token', res.data.token)
    localStorage.setItem('refresh_token', res.data.refresh_token)

    if(needRedirect) {
      this.router.navigate([this.localize.translateRoute('/dashboard')])
    }
  }

  me(self: HandleErrorAndSuccess) {
    this._apiRequestService.postRequest('auth/me',
        {},
        (res) => self.handleSuccessResponse(res),
        (err) => self.handleErrorResponse(err)
    )
  }

  meSimple(cb: (res: any) => void) {
    this._apiRequestService.postRequest('auth/me', {}, cb, undefined, false)
  }

  refreshBearerToken(refreshToken: string, self: HandleErrorAndSuccess) {
    this._apiRequestService.postRequest('auth/refresh',
        { refreshToken: refreshToken },
        () => {
      self.handleSuccessResponse()
        },
        (err) => {
      self.handleErrorResponse(err)
    })
  }
}
