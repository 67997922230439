import { Component } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {ContainerComponent, ContainerTypes} from 'src/app/shared/components/container/container.component';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'mvm-home-details',
  standalone: true,
  templateUrl: './home-details.component.html',
    imports: [
        ContainerComponent, AngularSvgIconModule, TranslateModule
    ],
  styleUrls: ['./home-details.component.scss']
})
export class HomeDetailsComponent {
  ContainerTypes = ContainerTypes
}
