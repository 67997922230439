<mvm-cart-layout>
  <div class="flex flex-col justify-center items-center">

    <section class="frame-ticket-modify">
      <mvm-ticket-modify class="w-full" (modificationFinished)="closeModal()"></mvm-ticket-modify>

      <svg-icon [routerLink]="['/checkout-cart'] | localize" name="close-x" class="cursor-pointer"></svg-icon>
    </section>

  </div>
</mvm-cart-layout>
