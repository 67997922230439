import {Component, Renderer2} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardSendTicketComponent} from "../cards/card-send-ticket/card-send-ticket.component";
import {Subscription} from "rxjs";
import {BuyParkingModalService} from "../../services/buyParkingTicketModalService/buy-parking-modal.service";
import {ChooserParkingComponent} from "../cards/parking/chooser-parking/chooser-parking.component";
import {AngularSvgIconModule} from "angular-svg-icon";
import {TranslateModule} from "@ngx-translate/core";
import {CartApiService} from "../../services/apiServices/cartApiService/cart-api.service";
import {CartTicketData} from "../../interfaces/checkout-cart/checkout-cart.models";
import {HandleErrorAndSuccess} from "../../interfaces/common/handle-error-and-success";

@Component({
  selector: 'mvm-modal-buy-parking-ticket',
  standalone: true,
  imports: [CommonModule, CardSendTicketComponent, ChooserParkingComponent, AngularSvgIconModule, TranslateModule],
  templateUrl: './modal-buy-parking-ticket.component.html',
  styleUrls: ['./modal-buy-parking-ticket.component.scss']
})
export class ModalBuyParkingTicketComponent implements HandleErrorAndSuccess {

  visible: boolean = true
  eventSlug?: string

  parkingTicket?: { type: "parking"; quantity: number }
  private listener?: () => void
  private subscription?: Subscription;

  constructor(private renderer: Renderer2, private service: BuyParkingModalService, private cartService: CartApiService) {
    this.subscription = this.service.state$.subscribe(state => {
      this.visible = state.visible
      this.eventSlug = state.eventSlug
      if (this.eventSlug === undefined) {
        // do not let it open if anything undefined
        this.visible = false
      }
      if (this.visible) {
        this.listener = this.renderer.listen("document", "keyup.escape", _e => {
          this.close()
        })
      } else {
        this.close()
      }
    })
  }

  handleErrorResponse(err: string): void {
    this.close()
  }

  handleSuccessResponse(res?: any): void {
    this.close()
  }


  close() {
    this.visible = false
    this.listener?.()
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

  ticketsChange($event: { type: "parking"; quantity: number }) {
    this.parkingTicket = $event
  }

  pay() {
    if (this.parkingTicket && this.eventSlug) {
      const ticketData: CartTicketData = {
        event_slug: this.eventSlug,
        parking_tickets: {
          number_of_parking_tickets: this.parkingTicket.quantity,
          parking_ticket_type: this.parkingTicket.type
        }
      }
      this.cartService.addToCart(ticketData, this)
    }
  }

  cancel() {
    this.close()
  }
}
