<mvm-checkout-layout [title]="('checkoutBilling.title' | translate)">
    <form [formGroup]="billingDetailsForm" class="flex flex-col">

        <section class="billing">
            <mvm-container [type]="ContainerTypes.DefaultForm">
                <div class="social-icons flex justify-center">
                    <!--<button class="icon-button facebook">
                    <svg-icon [applyClass]="true" name="facebook-social-white"></svg-icon>
                </button>
                <button class="icon-button linkedin">
                    <svg-icon [applyClass]="true" name="linkedin-white"></svg-icon>
                </button>
                <button class="icon-button google">
                    <svg-icon [applyClass]="true" name="google-plus-white"></svg-icon>
                </button>-->
                </div>
                <p class="required-info text-center">{{'checkoutBilling.required' | translate}}</p>
                <div id="form-content">
                    <div class="personal-details flex flex-col">
                        <div>
                            <input type="text" [ngModel]="profileData?.lastName ?? ''"
                                placeholder="{{'checkoutBilling.lastname' | translate}}" class="input-text"
                                formControlName="lastName">
                            <mvm-error classes="mb-5" [show]="getError('lastName', 'required')"
                                [text]="'checkoutBilling.validation.lastName.required' | translate"></mvm-error>
                        </div>

                        <div>
                            <input type="text" [ngModel]="profileData?.firstName ?? ''"
                                placeholder="{{'checkoutBilling.firstname' | translate}}" class="input-text"
                                formControlName="firstName">
                            <mvm-error classes="mb-5" [show]="getError('firstName', 'required')"
                                [text]="'checkoutBilling.validation.firstName.required' | translate"></mvm-error>
                        </div>

                        <div>
                            <input type="text" [ngModel]="profileData?.phone ?? ''"

                                [patterns]="customPatterns2" mask="A0000000000000000"
                                 [dropSpecialCharacters]="false"
                                [specialCharacters]="[ '+' ]" 
                                placeholder="{{'checkoutBilling.phone' | translate}}" class="input-text"
                                formControlName="phone">
                            <mvm-error classes="mb-5" [show]="getError('phone', 'required')"
                                [text]="'checkoutBilling.validation.phone.required' | translate"></mvm-error>
                        </div>

                        <!--<div>
                            <input type="email" [ngModel]="profileData?.email ?? ''"
                                placeholder="{{'checkoutBilling.email' | translate}}" class="input-text"
                                formControlName="email">
                            <mvm-error classes="mb-5" [show]="getError('email', 'required')"
                                [text]="'checkoutBilling.validation.email.required' | translate"></mvm-error>
                            <mvm-error classes="mb-5" [show]="getError('email', 'email')"
                                [text]="'checkoutBilling.validation.email.email' | translate"></mvm-error>
                        </div>-->

                    </div>
                    <div class="personal-address flex flex-col">
                        <mvm-dropdown-chooser (countrySelected)="setCountry($event)"
                            [name]="billingDetailsForm.get('country')?.value" [options]="countries">
                        </mvm-dropdown-chooser>

                        <div>
                            <input type="text" [ngModel]="profileData?.personalPostalCode ?? ''"
                                mask="9{15}"
                                placeholder="*{{'checkoutBilling.zipcode' | translate}}" class="input-text"
                                formControlName="zipcode">
                            <mvm-error classes="mb-5" [show]="getError('zipcode', 'required')"
                                [text]="'checkoutBilling.validation.zipcode.required' | translate"></mvm-error>
                        </div>

                        <div>
                            <input type="text" [ngModel]="profileData?.personalCity ?? ''"
                                placeholder="*{{'checkoutBilling.city' | translate}}" class="input-text"
                                formControlName="city"
                                [patterns]="customPatterns" 
                                mask="*{100}" 
                                >
                            <mvm-error classes="mb-5" [show]="getError('city', 'required')"
                                [text]="'checkoutBilling.validation.city.required' | translate"></mvm-error>
                        </div>
                        <div>
                            <input type="text" [ngModel]="profileData?.personalAddressLine ?? ''"
                                placeholder="*{{'checkoutBilling.address' | translate}}" class="input-text"
                                formControlName="address">
                            <mvm-error classes="mb-5" [show]="getError('address', 'required')"
                                [text]="'checkoutBilling.validation.address.required' | translate"></mvm-error>
                        </div>
                    </div>

                    <div class="flex items-center cursor-pointer" (click)="accepted3 = !accepted3">
                        <mvm-checkbox [(ngModel)]="accepted3" [rounded]="true" color="black"
                            formControlName="hasBillingAddress"></mvm-checkbox>
                        <p>{{'checkoutBilling.bill' | translate}}</p>
                    </div>

                    <div @growInOut *ngIf="hasBillingAddress" class="billing-details flex flex-col">
                        <div>
                            <input type="text" [ngModel]="authMeResponse?.data?.invoice_details?.company ?? ''"
                                placeholder="{{'checkoutBilling.name' | translate}}" class="input-text"
                                formControlName="company">
                            <mvm-error classes="mb-5" [show]="hasBillingAddress && getError('company', 'required')"
                                [text]="'checkoutBilling.validation.company.required' | translate"></mvm-error>
                        </div>

                        <input [ngModel]="authMeResponse?.data?.invoice_details?.vat_number ?? ''" type="text"
                            placeholder="{{'checkoutBilling.ado' | translate}}" class="input-text"
                            formControlName="vat_number"
                            mask="9{15}"
                            >

                        <mvm-dropdown-chooser (countrySelected)="setInvoiceCountry($event)"
                            [name]="billingDetailsForm.get('invoice_country')?.value" [options]="countries">
                        </mvm-dropdown-chooser>

                        <div>
                            <input type="text" [ngModel]="authMeResponse?.data?.invoice_details?.invoice_zip ?? ''"
                                placeholder="{{'checkoutBilling.zipcode' | translate}}" class="input-text"
                                formControlName="invoice_zip" 
                                mask="9{15}">
                            <mvm-error classes="mb-5" [show]="hasBillingAddress && getError('invoice_zip', 'required')"
                                [text]="'checkoutBilling.validation.invoice_zip.required' | translate"></mvm-error>
                        </div>

                        <div>
                            <input type="text" [ngModel]="authMeResponse?.data?.invoice_details?.invoice_city ?? ''"
                                placeholder="{{'checkoutBilling.city' | translate}}" class="input-text"
                                formControlName="invoice_city"
                                [patterns]="customPatterns" 
                                mask="*{100}" 
                                >
                            <mvm-error classes="mb-5" [show]="hasBillingAddress && getError('invoice_city', 'required')"
                                [text]="'checkoutBilling.validation.invoice_city.required' | translate"></mvm-error>
                        </div>

                        <div>
                            <input type="text" [ngModel]="authMeResponse?.data?.invoice_details?.invoice_address ?? ''"
                                placeholder="{{'checkoutBilling.address' | translate}}" class="input-text"
                                formControlName="invoice_address">
                            <mvm-error classes="mb-5"
                                [show]="hasBillingAddress && getError('invoice_address', 'required')"
                                [text]="'checkoutBilling.validation.invoice_address.required' | translate"></mvm-error>
                        </div>
                    </div>
                </div>

            </mvm-container>
        </section>
        <section class="payment">
            <h2 class="text-h1 text-center">{{'checkoutBilling.payment' | translate}}</h2>
            <mvm-container [type]="ContainerTypes.DefaultForm">
                <div class="payment-wrapper flex flex-col">
                    <div>
                        <mvm-chooser-pay (paymentProviderSelected)="paymentProviderSelected($event)"></mvm-chooser-pay>
                        <mvm-error classes="mb-5" [show]="getError('payment_method', 'required')"
                            [text]="'checkoutBilling.validation.paymentProvider.required' | translate"></mvm-error>
                    </div>
                    <div>
                        <div class="checkbox-container flex cursor-pointer" (click)="accepted2 = !accepted2">
                            <mvm-checkbox [(ngModel)]="accepted2" [rounded]="true" color="black"
                                formControlName="terms"></mvm-checkbox>
                            <p>{{'checkoutBilling.accept1' | translate}}<a
                                    href="https://mvm-dome.hu/pdf/MVM_Dome%20honlap_adatkezelesi_tajekoztato_HUN_ENG.pdf"
                                    target="_blank"
                                    [attr.aria-label]="'checkoutBilling.accept2' | translate">{{'checkoutBilling.accept2' | translate}}</a>{{'checkoutBilling.accept3' | translate}}<a
                                    href="https://mvm-dome.hu/pdf/%C3%9Czleti_%C3%BCl%C5%91helyek_hun.pdf"
                                    target="_blank"
                                    [attr.aria-label]="'checkoutBilling.accept4' | translate">{{'checkoutBilling.accept4' | translate}}</a>.
                            </p>
                        </div>
                        <mvm-error classes="mb-5" [show]="getError('terms', 'required')"
                            [text]="'checkoutBilling.validation.accept2.required' | translate"></mvm-error>

                        <!--<div class="checkbox-container flex cursor-pointer" (click)="accepted = !accepted">
                        <mvm-checkbox [rounded]="true" [(ngModel)]="accepted" color="black" formControlName="houseRules"></mvm-checkbox>
                        <p>{{'checkoutBilling.accept5' | translate}}<a [routerLink]="['/'] | localize">{{'checkoutBilling.accept6' | translate}}</a>.</p>
                    </div>
                    <mvm-error classes="mb-5" [show]="getError('houseRules', 'required')" [text]="'checkoutBilling.validation.accept6.required' | translate"></mvm-error>
                    -->
                    </div>
                    <button type="submit" (click)="submitBilling()"
                        class="btn-yellow w-full">{{'checkoutBilling.next' | translate}}</button>

                </div>
            </mvm-container>
        </section>
    </form>
    <div [innerHTML]="simpleFormToSubmit"></div>
</mvm-checkout-layout>