<div class="modal-container" [ngClass]="visible ? 'modal-visible' : 'modal-hidden'" (click)="close()">

  <div class="modal-content-container" (click)="$event.stopPropagation()">

    <div class="buy-card">
      <svg-icon name="parking-ticket-buy"></svg-icon>
      <p></p>
      <div class="buy-actions">
        <app-chooser-parking class="w-full" (parkingChange)="ticketsChange($event)"></app-chooser-parking>
        <div class="buy-buttons">
          <button (click)="pay()" class="btn-yellow w-full">{{'pay' | translate}}</button>
          <button (click)="cancel()" class="btn-text-yellow">{{'cancel' | translate}}</button>
        </div>
      </div>
    </div>


    <!--<div class="modal-x" (click)="close()">
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1.27279" y1="2" x2="12.7279" y2="13.4551" stroke="white" stroke-width="1.8" stroke-linecap="square"/>
        <line x1="0.9" y1="-0.9" x2="17.1" y2="-0.9" transform="matrix(-0.707107 0.707107 0.707107 0.707107 14.0918 2)" stroke="white" stroke-width="1.8" stroke-linecap="square"/>
      </svg>
    </div>-->

  </div>

</div>
