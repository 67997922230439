<ng-container [ngSwitch]="type">

  <div class="radio-pay cursor-pointer" *ngSwitchCase="'bankcard'"
       [ngClass]="checkedValue === 'bankcard' ? 'bg-blue-pay-mvm' : ''"
       (click)="checkedValue = type; checkedValueChange.emit(checkedValue)">
    <div class="flex gap-10px">
      <input [ngModel]="checkedValue" (ngModelChange)="checkedValue = $event; checkedValueChange.emit(checkedValue)"
             value="bankcard" class="radio-bw" type="radio" name="radio-pay"/>
      <svg-icon name="simplePay"></svg-icon>

    </div>

    <div class="flex gap-10px">
      <svg-icon name="maestro"></svg-icon>
      <svg-icon name="mastercard"></svg-icon>
      <svg-icon name="visa"></svg-icon>
    </div>
  </div>

  <div class="radio-pay cursor-pointer" *ngSwitchCase="'apple'"
       [ngClass]="checkedValue === 'apple' ? 'bg-blue-pay-mvm' : ''"
       (click)="checkedValue = type; checkedValueChange.emit(checkedValue)">
    <div class="flex gap-10px">
      <input [ngModel]="checkedValue" (ngModelChange)="checkedValue = $event; checkedValueChange.emit(checkedValue)"
             value="apple" class="radio-bw" type="radio" name="radio-pay"/>
      <svg-icon name="apple"></svg-icon>
    </div>
  </div>

  <div class="radio-pay cursor-pointer" *ngSwitchCase="'google'"
       [ngClass]="checkedValue === 'google' ? 'bg-blue-pay-mvm' : ''"
       (click)="checkedValue = type; checkedValueChange.emit(checkedValue)">
    <div class="flex gap-10px">
      <input [ngModel]="checkedValue" (ngModelChange)="checkedValue = $event; checkedValueChange.emit(checkedValue)"
             value="google" class="radio-bw" type="radio" name="radio-pay"/>
      <svg-icon name="google"></svg-icon>
    </div>
  </div>

  <div class="radio-pay cursor-pointer" *ngSwitchCase="'paypal'"
       [ngClass]="checkedValue === 'paypal' ? 'bg-blue-pay-mvm' : ''"
       (click)="checkedValue = type; checkedValueChange.emit(checkedValue)">
    <div class="flex gap-10px items-center">
      <input [ngModel]="checkedValue" (ngModelChange)="checkedValue = $event; checkedValueChange.emit(checkedValue)"
             value="paypal" class="radio-bw" type="radio" name="radio-pay"/>
      <svg-icon name="paypal"></svg-icon>
    </div>
  </div>

  <div class="radio-pay cursor-pointer" *ngSwitchCase="'banktransfer'"
       [ngClass]="checkedValue === 'banktransfer' ? 'bg-blue-pay-mvm' : ''"
       (click)="checkedValue = type; checkedValueChange.emit(checkedValue)">
    <div class="flex gap-10px items-center">
      <input [ngModel]="checkedValue" (ngModelChange)="checkedValue = $event; checkedValueChange.emit(checkedValue)"
             value="banktransfer" class="radio-bw" type="radio" name="radio-pay"/>
      <p class="tx-pay">{{'payTransfer' | translate}}</p>
    </div>
  </div>
</ng-container>
