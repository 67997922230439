<mvm-auth-layout>
    <div class="container">
        <div class="register content">
            <h1 class="text-super-h1">{{'register.title' | translate}}</h1>
            <div class="login-wrapper flex justify-center">
                <p>{{'register.newAccount' | translate}}</p>
                <a class="text-subtitle text-yellow" [routerLink]="['/login'] | localize" [attr.aria-label]="'login' | translate">{{'login' | translate}}</a>
            </div>
            <mvm-social-login-buttons type="register" id="register-social"></mvm-social-login-buttons>
            <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">

                <input type="text" class="input-text dark" placeholder="{{'register.lastname' | translate}}" formControlName="lastName">
                <mvm-error classes="mb-5" [show]="getError('lastName', 'required')" [text]="'register.validation.lastName.required' | translate"></mvm-error>

                <input type="text" class="input-text dark" placeholder="{{'register.firstname' | translate}}" formControlName="firstName">
                <mvm-error classes="mb-5" [show]="getError('firstName', 'required')" [text]="'register.validation.firstName.required' | translate"></mvm-error>

                <input type="email" class="input-text dark" placeholder="{{'register.email' | translate}}" formControlName="emailAddress">
                <mvm-error classes="mb-5" [show]="getError('emailAddress', 'required')" [text]="'register.validation.emailAddress.required' | translate"></mvm-error>
                <mvm-error classes="mb-5" [show]="getError('emailAddress', 'email')" [text]="'register.validation.emailAddress.email' | translate"></mvm-error>

                <input type="password" class="input-text dark" placeholder="{{'register.password' | translate}}" formControlName="password">
                <mvm-error classes="mb-5" [show]="getError('password', 'required')" [text]="'register.validation.password.required' | translate"></mvm-error>

                <input type="password" class="input-text dark" placeholder="{{'register.passAgain' | translate}}"formControlName="passwordConfirmation">
                <mvm-error classes="mb-5" [show]="getError('passwordConfirmation', 'required')" [text]="'register.validation.passwordConfirmation.required' | translate"></mvm-error>
                <mvm-error classes="mb-5" [show]="getError('passwordConfirmation', 'forbiddenName')" [text]="'register.validation.passwordConfirmation.notSame' | translate"></mvm-error>

                <div class="checkbox-wrapper flex cursor-pointer" (click)="accepted = !accepted">
                    <mvm-checkbox [rounded]="true" [(ngModel)]="accepted" formControlName="termsAndConds"></mvm-checkbox>
                    <p class="text-white" [innerHTML]="'register.accept' | translate"></p>
                </div>
                <mvm-error classes="mb-5" [show]="getError('termsAndConds', 'required')" [text]="'register.validation.termsAndConds.required' | translate"></mvm-error>

                <mvm-button-save
                    [disabled]="done || !registrationForm.valid"
                    [saving]="isRequesting"
                    defaultText="{{'register.register' | translate}}"
                    savedText="{{'register.registered' | translate}}"
                    [saved]="done"
                ></mvm-button-save>
            </form>
        </div>
    </div>
</mvm-auth-layout>
