import {createReducer, on} from "@ngrx/store";
import {initialCartState} from "../states/cart.state";
import {cartLoaded, cartOrderSuccess} from "../actions/cart.actions";

export const cartReducer = createReducer(
    initialCartState,
    on(cartLoaded, (state, {cart}) => ({cart: cart})),
    on(cartOrderSuccess, () => ({ cart: null }))
)

