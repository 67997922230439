import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  BorderedButtonColors
} from 'src/app/shared/components/buttons/colored-border-button/colored-border-button.component';
import { HomepageApiService } from 'src/app/shared/services/apiServices/homepageApiService/homepage-api.service';
import { ContainerTypes } from "../../../shared/components/container/container.component";
import { Store } from "@ngrx/store";
import { selectCoverImage, selectHomeAdvert, selectHomeEvents } from "../../../store/selectors/home.selector";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, takeUntil, tap } from "rxjs";
import { Program } from "../../../shared/model/program.model";
import { ProgramTypes } from "../../../shared/enums/program-types.enum";
import { ScrollingCardService } from "../../services/scrolling/scrolling-card.service";
import { addGoogleAds } from "../../../shared/addGoogleAds";

@Component({
  selector: 'mvm-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  ProgramTypes = ProgramTypes
  ContainerTypes = ContainerTypes
  BorderedButtonColors = BorderedButtonColors

  public advert$ = this.store.select(selectHomeAdvert)
  public coverImage$ = this.store.select(selectCoverImage)
  public events$: Observable<Program[]> = this.store.select(selectHomeEvents)

  private destroy$ = new Subject<void>();
  scrolling = false
  skeleton: boolean = true;

  constructor(
    private _homepageApiService: HomepageApiService,
    private store: Store,
    private localize: LocalizeRouterService,
    private translate: TranslateService,
    private scrollingService: ScrollingCardService
  ) {
  }

  ngAfterViewInit(): void {
    //addGoogleAds("div-gpt-ad-1680630318263-0");
    this.addSliderListener()
  }

  ngOnInit() {
    this._homepageApiService.loadData()

    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(
      () => this._homepageApiService.loadData()
    )
    this.events$.pipe(tap((res) => {
      if (res && res.length > 0) {
        this.skeleton = false
      }
    })).pipe(takeUntil(this.destroy$)).subscribe()
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  rightArrow() {
    const slider = document.getElementById('home-slider')
    if(slider) {
      this.scrolling = true;
      this.scrollingService.scrolling.next(false)
      let startX = 0 - slider.offsetLeft;
      let scrollLeft = slider.scrollLeft;
      this.scrollingService.scrolling.next(true)
    }
  }

  get hasNavbar() {
    return true 
  }

  get showNavbarBg() {
    return true //window.outerWidth >= 1200
  }

  private addSliderListener() {
    const slider = document.getElementById('home-slider')
    if (slider) {
      this.scrolling = false;
      this.scrollingService.scrolling.next(false)
      let startX: number;
      let scrollLeft: number;

      slider.addEventListener('mousedown', (e) => {
        this.scrolling = true;
        this.scrollingService.scrolling.next(true)
        e.preventDefault()
        e.stopImmediatePropagation()
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener('mouseleave', () => {
        this.scrolling = false;
        this.scrollingService.scrolling.next(false)
      });
      slider.addEventListener('mouseup', (e) => {
        this.scrolling = false;
        this.scrollingService.scrolling.next(false)
        e.stopImmediatePropagation()
        e.preventDefault()
        e.stopPropagation()
      });
      slider.addEventListener('mousemove', (e) => {
        if (!this.scrolling) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX);
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }
}
