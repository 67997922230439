import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContainerTypes} from "../../../shared/components/container/container.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";
import {
  EditProfileData,
  ProfileApiService,
  ProfileData
} from "../../../shared/services/apiServices/profileApiService/profile-api.service";
import {SuccessModalService} from "../../../shared/services/SuccessService/success-modal.service";
import {TranslateService} from "@ngx-translate/core";
import {ErrorModalService} from "../../../shared/services/errorService/error-modal.service";
import { countries } from 'src/app/datas/countries';

@Component({
  selector: 'mvm-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit, OnDestroy {
  ContainerTypes = ContainerTypes

  public profileForm!: FormGroup
  public isRequesting: boolean = false
  public done: boolean = false
  private destroy$ = new Subject<void>();
  public countries: Array<string> = []  

  profileData: ProfileData = {
    billingAddressLine: "",
    billingCity: "",
    billingCountry: "",
    billingPostalCode: "",
    billingName: "",
    email: "",
    firstName: "",
    lastName: "",
    personalAddressLine: "",
    personalCity: "",
    personalCountry: "",
    personalPostalCode: "",
    phone: ""
  }

  constructor(
    _fb: FormBuilder, 
    private profileService: ProfileApiService, 
    private errorService: ErrorModalService, 
    private successService: SuccessModalService, 
    private translate: TranslateService) {

    this.profileForm = _fb.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      personalCountry: [''],
      personalPostalCode: ['', Validators.required],
      personalCity: ['', Validators.required],
      personalAddressLine: ['', Validators.required],
      billingName: [''],
      billingCountry: [''],
      billingPostalCode: [''],
      billingCity: [''],
      billingAddressLine: [''],
      currentPassword: [''],
      newPassword: [''],
      newPasswordAgain: ['']
    },)

  }

  public ngOnInit() {
    /* countries */
    this.countries = this.translate.currentLang == 'hu' ? countries.hu.sort() : countries.en.sort()

    this._registerValueChangeHandlers()
    this.profileService.getProfileData((res) => {
      this.profileData = res.data
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setCountry(country: string) {
    this.profileForm.get('personalCountry')?.setValue(country)
    this.profileData.personalCountry = country
  }

  setInvoiceCountry(country: string) {
    this.profileData.billingCountry = country
    this.profileForm.get('billingCountry')?.setValue(country)
  }

  private _registerValueChangeHandlers() {
    this.profileForm.get('password')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.profileForm.controls['passwordConfirmation'].updateValueAndValidity()
    })
  }

  public onSubmit() {
    /* mark all inputs */
    this._markAllInputs()

    /* if form is invalid */
    if (!this.profileForm.valid) {
      this.errorService.showErrorTranslate("dynamic.text8")
      return;
    }

    /* set isrequesting */
    this._requestStarted()

    const edit: EditProfileData = {
      billingAddressLine: this.profileData?.billingAddressLine ?? "",
      billingCity: this.profileData?.billingCity ?? "",
      billingCountry: this.profileData?.billingCountry ?? "",
      billingName: this.profileData?.billingName ?? "",
      billingPostalCode: this.profileData?.billingPostalCode ?? "",
      email: this.profileData?.email ?? "",
      firstName: this.profileData?.firstName ?? "",
      lastName: this.profileData?.lastName ?? "",
      personalAddressLine: this.profileData?.personalAddressLine ?? "",
      personalCity: this.profileData?.personalCity ?? "",
      personalCountry: this.profileData?.personalCountry ?? "",
      personalPostalCode: this.profileData?.personalPostalCode ?? "",
      phone: this.profileData?.phone ?? ""
    } as EditProfileData

    if(this.profileForm.value.newPassword && this.profileForm.value.newPasswordAgain && this.profileForm.value.currentPassword) {
      edit.newPassword =  this.profileForm.value.newPassword
      edit.newPasswordAgain =  this.profileForm.value.newPasswordAgain
      edit.currentPassword = this.profileForm.value.currentPassword
    }

    this.profileService.updateProfile(edit, (res) => {
      this.successService.showSuccessTranslate("dynamic.text7")
      this.profileData = res.data
    })
  }


  /* returns validation errors */
  public getError(attribute: string, validationRule: string) {
    return this.profileForm.get(attribute)?.touched && this.profileForm.get(attribute)?.errors?.[validationRule]
  }

  /* requesting started */
  private _requestStarted(): void {
    this.isRequesting = true
  }

  /* requesting finihsed */
  private _requestStopped(): void {
    this.isRequesting = false
  }

  private _markAllInputs() {
    Object.keys(this.profileForm.controls).map((input) => {
      this.profileForm.get(input)?.markAsTouched()
    })
  }
}
