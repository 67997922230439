<mvm-core>
    <mvm-container [type]="ContainerTypes.MobileFullDesktopNarrow">
        <section id="vip-dome">
            <div id="vip-dome-image">
                <img src="/assets/images/jpg/vip/dome.jpg?v=2" alt="MVM Dome VIP">
            </div>
            <div class="desktop-none-mobile-default">
                <h1>{{'vip.title' | translate}}</h1>
                <p class="yellow">{{'vip.desc1' | translate}}</p>
                <p>{{'vip.desc2' | translate}}</p>
                <p class="grey-bg">{{'vip.desc3.white1' | translate}}<span
                        class="yellow">{{'vip.desc3.yellow1' | translate}}</span>{{'vip.desc3.white2' | translate}}<span
                        class="yellow">{{'vip.desc3.yellow2' | translate}}</span>{{'vip.desc3.white3' | translate}}<span
                        class="yellow">{{'vip.desc3.yellow3' | translate}}</span>{{'vip.desc3.white4' | translate}}<span
                        class="yellow">{{'vip.desc3.yellow4' | translate}}</span>{{'vip.desc3.white5' | translate}}<span
                        class="yellow">{{'vip.desc3.yellow5' | translate}}</span>{{'vip.desc3.white6' | translate}}<span
                        class="yellow">{{'vip.desc3.yellow6' | translate}}</span>
                    {{'vip.desc3.white7' | translate}}</p>
            </div>
        </section>

        <img src="/assets/images/png/vip/section/Platinum.png" class="section-image" alt="Skybox">

        <section id="vip-platinum" class="vip-section">
            <img src="/assets/images/png/vip/platinum.png" alt="MVM VIP Platinum Lounge">
            <div class="desktop-none-mobile-default">
                <h2>{{'vip.platinum.title' | translate}}</h2>
                <p>{{'vip.platinum.desc' | translate}}</p>
                <ul>
                    <li>{{'vip.platinum.li1' | translate}}</li>
                    <li>{{'vip.platinum.li2' | translate}}</li>
                    <!--<li>{{'vip.platinum.li3' | translate}}</li>-->
                    <li>{{'vip.platinum.li4' | translate}}</li>
                    <li>{{'vip.platinum.li5' | translate}}</li>
                    <!--<li>{{'vip.platinum.li6' | translate}}</li>-->
                    <!--<li>{{'vip.platinum.li7' | translate}}</li>-->
                </ul>
                <a [routerLink]="['/vip'] | localize" [attr.aria-label]="'vip.platinum.recommend' | translate"
                   [fragment]="'contact-form'" class="btn-yellow" >{{'vip.platinum.recommend' | translate}}</a>
            </div>
        </section>

        <img src="/assets/images/png/vip/section/Skybox.png" class="section-image" alt="Skybox">
        <section id="vip-skybox" class="vip-section">
            <img src="/assets/images/png/vip/skybox.png" alt="MVM Dome Skybox">
            <div class="desktop-none-mobile-default">
                <h2>{{'vip.skybox.title' | translate}}</h2>
                <p>{{'vip.skybox.desc' | translate}}</p>
                <ul>
                    <!--<li>{{'vip.skybox.li1' | translate}}</li>-->
                    <li>{{'vip.skybox.li2' | translate}}</li>
                    <li>{{'vip.skybox.li3' | translate}}</li>
                    <li>{{'vip.skybox.li4' | translate}}</li>
                    <!--<li>{{'vip.skybox.li5' | translate}}</li>-->
                    <!--<li>{{'vip.skybox.li6' | translate}}</li>-->
                    <li>{{'vip.skybox.li7' | translate}}</li>
                    <!--<li>{{'vip.skybox.li8' | translate}}</li>-->
                    <li>{{'vip.skybox.li9' | translate}}</li>
                </ul>
                <a [routerLink]="['/vip'] | localize" [attr.aria-label]="'vip.skybox.recommend' | translate"
                   [fragment]="'contact-form'" class="btn-yellow">{{'vip.skybox.recommend' | translate}}</a>
            </div>
        </section>
        
        
        <!--<img src="/assets/images/png/vip/section/Gold.png" class="section-image" alt="Skybox">
        <section id="vip-gold" class="vip-section">
            <img src="/assets/images/png/vip/gold.png" alt="MVM VIP Gold Lounge">
            <div class="desktop-none-mobile-default">
                <h2>{{'vip.gold.title' | translate}}</h2>
                <p>{{'vip.gold.desc' | translate}}</p>
                <ul>
                    <li>{{'vip.gold.li1' | translate}}</li>
                    <li>{{'vip.gold.li2' | translate}}</li>
                    <li>{{'vip.gold.li4' | translate}}</li>
                    <li>{{'vip.gold.li5' | translate}}</li>
                </ul>
                <a [routerLink]="['/vip'] | localize" [attr.aria-label]="'vip.gold.recommend' | translate"
                   [fragment]="'contact-form'" class="btn-yellow">{{'vip.gold.recommend' | translate}}</a>
            </div>
        </section>-->
    </mvm-container>
    <mvm-container [type]="ContainerTypes.MobileDefaultDesktopExtraNarrow">
        <section id="contact-form-holder">
            <div id="benefits-holder">
                <ul>
                    <li>
                        <svg-icon name="vip-jegyek"></svg-icon>
                        <h3>{{'vip.benefits.title1' | translate}}</h3>
                        <p>{{'vip.benefits.desc1' | translate}}</p>
                    </li>
                    <li>
                        <svg-icon name="vip-parkolo"></svg-icon>
                        <h3>{{'vip.benefits.title2' | translate}}</h3>
                        <p>{{'vip.benefits.desc2' | translate}}</p>
                    </li>
                    <li>
                        <svg-icon name="vip-catering"></svg-icon>
                        <h3>{{'vip.benefits.title3' | translate}}</h3>
                        <p>{{'vip.benefits.desc3' | translate}}</p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <svg-icon name="vip-ferohely"></svg-icon>
                        <h3>{{'vip.benefits.title4' | translate}}</h3>
                        <p>{{'vip.benefits.desc4' | translate}}</p>
                    </li>
                    <li>
                        <svg-icon name="vip-belepes"></svg-icon>
                        <h3>{{'vip.benefits.title5' | translate}}</h3>
                        <p>{{'vip.benefits.desc5' | translate}}</p>
                    </li>
                    <li>
                        <svg-icon name="vip-kiszolgalas"></svg-icon>
                        <h3>{{'vip.benefits.title6' | translate}}</h3>
                        <p>{{'vip.benefits.desc6' | translate}}</p>
                    </li>
                </ul>
            </div>
            <div id="contact-form">
                <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                    <h3>{{'vip.contact.title' | translate}}</h3>
                    <!--<p class="bold">{{'vip.contact.contact' | translate}}</p>-->
                    <div id="inputs">
                        <input id="email" type="text" class="input-text dark"
                            placeholder="{{'vip.contact.lastname' | translate}}" formControlName="lastName">
                        <mvm-error [show]="getError('lastName', 'required')"
                            [text]="'vip.validation.lastName.required' | translate"></mvm-error>

                        <input id="email" type="text" class="input-text dark"
                            placeholder="{{'vip.contact.firstname' | translate}}" formControlName="firstName">
                        <mvm-error [show]="getError('firstName', 'required')"
                            [text]="'vip.validation.firstName.required' | translate"></mvm-error>

                        <input id="email" type="text" class="input-text dark"
                            placeholder="{{'vip.contact.email' | translate}}" formControlName="emailAddress">
                        <mvm-error [show]="getError('emailAddress', 'required')"
                            [text]="'vip.validation.emailAddress.required' | translate"></mvm-error>
                        <mvm-error [show]="getError('emailAddress', 'email')"
                            [text]="'vip.validation.emailAddress.email' | translate"></mvm-error>

                    </div>
                    <p id="service-selector">{{'vip.contact.services' | translate}}</p>
                    <div class="services">
                        <div class="checkbox-wrapper flex cursor-pointer" (click)="acceptedSky = !acceptedSky">
                            <mvm-checkbox [(ngModel)]="acceptedSky" [rounded]="false" formControlName="skybox"></mvm-checkbox>
                            <p>Skybox</p>
                        </div>
                        <div class="checkbox-wrapper flex cursor-pointer" (click)="acceptedPlat = !acceptedPlat">
                            <mvm-checkbox [rounded]="false" [(ngModel)]="acceptedPlat" formControlName="platinum"></mvm-checkbox>
                            <p>Platinum / Gold Lounge</p>
                        </div>
                    </div>

                    <mvm-error classes="mb-5" [show]="getError('service', 'required')" [text]="'vip.validation.service.required' | translate"></mvm-error>

                    <div class="checkbox-wrapper flex cursor-pointer" (click)="accepted = !accepted">
                        <mvm-checkbox [(ngModel)]="accepted" [rounded]="true" formControlName="termsAndConds"></mvm-checkbox>
                        <p [innerHTML]="'vip.contact.accept' | translate"></p>
                    </div>
                    <mvm-error classes="mb-5" [show]="getError('termsAndConds', 'required')" [text]="'vip.validation.termsAndConds.required' | translate"></mvm-error>

                    <button class="btn-yellow">{{'send' | translate}}</button>
                    <!--<p class="yellow">{{'vip.contact.details' | translate}}</p>-->
                </form>
            </div>
        </section>
    </mvm-container>
</mvm-core>
