import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'mvm-upcoming-events',
  standalone: true,
  imports: [CommonModule, RouterModule, LocalizeRouterModule, AngularSvgIconModule, TranslateModule],
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.scss']
})
export class UpcomingEventsComponent {

}
