import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DetailPageState} from "../states/detail-page.state";

const detailFeatureKey = 'detail';
export const selectDetail = createFeatureSelector<DetailPageState>(detailFeatureKey)

export const selectDetailEvent = createSelector(
  selectDetail,
    (state: DetailPageState) => state.event
)

export const selectDetailMeta = createSelector(
  selectDetail,
  (state: DetailPageState) => state.meta
)

export const selectDetailOtherEvents = createSelector(
  selectDetail,
    (state: DetailPageState) => state.other_events
)

export const selectDetailTickets = createSelector(
  selectDetail,
  (state: DetailPageState) => state.tickets
)
