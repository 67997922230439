import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class LanguageHeaderInterceptor implements HttpInterceptor {
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(httpRequest.clone({
            setHeaders: {
                "Accept-Language": localStorage.getItem('lang') ?? 'hu',
                "Accept" : "application/json",
            }
        }));
    }
}