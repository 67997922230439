<mvm-core>
    <mvm-container>
        <div id="email-verification-holder">
            <ng-container *ngIf="isResendingValidationToken">
                <mvm-resend-activation-token [style]="'light'"></mvm-resend-activation-token> 
             </ng-container>

             <ng-container *ngIf="!isResendingValidationToken">
                <p>{{'activate4' | translate}}</p>
                <button [disabled]="isRequesting" (click)="onSubmit()" class="btn-yellow">{{'activate5' | translate}}</button>
             </ng-container>
        </div>
    </mvm-container>
</mvm-core>
