import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerTypes } from 'src/app/shared/components/container/container.component';

@Component({
  selector: 'mvm-home-vip',
  templateUrl: './home-vip.component.html',
  styleUrls: ['./home-vip.component.scss']
})
export class HomeVipComponent {
  ContainerTypes = ContainerTypes
}
