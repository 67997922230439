<div class="modal-container" [ngClass]="visible ? 'modal-visible' : 'modal-hidden'" (click)="close()">

  <div class="modal-content-container"><!-- (click)="$event.stopPropagation()"-->

    <div class="modal-error">
      <!--<svg id="error-modal-svg" class="modal-error-svg">
        <circle style="fill:#D75A4A;" cx="25" cy="25" r="25"/>
        <polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;"
                  points="16,34 25,25 34,16"/>
        <polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;"
                  points="16,16 25,25 34,34"/>
      </svg>-->
      <svg *ngIf="!modalx" id="error-modal-svg" class="modal-error-svg" width="49" height="40" viewBox="0 0 49 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.4999 25.9239C23.6815 25.9239 23.0182 25.2604 23.0182 24.4422V14.0703C23.0182 13.2521 23.6815 12.5886 24.4999 12.5886C25.3182 12.5886 25.9816 13.2521 25.9816 14.0703V24.4422C25.9816 25.2605 25.3182 25.9239 24.4999 25.9239Z" fill="white"/>
        <path d="M47.583 33.3629L29.1368 2.61965C27.0439 -0.869835 21.9602 -0.876595 19.8633 2.61965L1.41701 33.3629C-0.327402 36.2703 1.77132 40 5.17496 40H43.8249C47.2291 40 49.327 36.2698 47.583 33.3629ZM43.8249 37.0366H5.17505C4.071 37.0366 3.39406 35.8263 3.95821 34.8872L22.4044 4.14394C23.3507 2.56658 25.6482 2.56473 26.5957 4.14394L45.0419 34.8872C45.6054 35.8252 44.93 37.0366 43.8249 37.0366Z" fill="white"/>
        <path d="M24.4999 31.8507C25.7274 31.8507 26.7224 30.8557 26.7224 29.6282C26.7224 28.4007 25.7274 27.4056 24.4999 27.4056C23.2724 27.4056 22.2773 28.4007 22.2773 29.6282C22.2773 30.8557 23.2724 31.8507 24.4999 31.8507Z" fill="white"/>
      </svg>
      <svg *ngIf="modalx" id="error-modal-svg" class="modal-error-svg" width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.4996 0C12.212 0 0.599609 11.6124 0.599609 25.9C0.599609 40.1875 12.212 51.8 26.4996 51.8C40.7872 51.8 52.3996 40.1875 52.3996 25.9C52.3996 11.6124 40.7872 0 26.4996 0ZM26.4996 49.1857C13.6712 49.1857 3.21393 38.7284 3.21393 25.9C3.21393 13.0716 13.6712 2.61432 26.4996 2.61432C39.328 2.61432 49.7853 13.0716 49.7853 25.9C49.7853 38.7284 39.328 49.1857 26.4996 49.1857Z" fill="white"/>
        <path d="M28.3234 25.7784L35.9231 18.1786C36.4095 17.6922 36.4095 16.841 35.9231 16.3547C35.4368 15.8683 34.5856 15.8683 34.0992 16.3547L26.4994 23.9544L18.8997 16.3547C18.4133 15.8683 17.5621 15.8683 17.0757 16.3547C16.5893 16.841 16.5893 17.6922 17.0757 18.1786L24.6755 25.7784L17.0757 33.3173C16.5893 33.8037 16.5893 34.6549 17.0757 35.1413C17.3189 35.3845 17.6837 35.5061 17.9877 35.5061C18.2917 35.5061 18.6565 35.3845 18.8997 35.1413L26.4994 27.5415L34.0992 35.1413C34.3424 35.3845 34.7072 35.5061 35.0112 35.5061C35.3152 35.5061 35.6799 35.3845 35.9231 35.1413C36.4095 34.6549 36.4095 33.8037 35.9231 33.3173L28.3234 25.7784Z" fill="white"/>
      </svg>



      <div class="text-subtitle text-center text-white" [innerHTML]="title"></div>
      <div class="text-price text-center text-white" [innerHTML]="message"></div>
    </div>


    <div class="modal-x" (click)="close()">
      <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="34" height="34" rx="17" fill="#F5B126"/>
        <path d="M11.8383 22.6623C11.5987 22.423 11.4863 22.1763 11.5013 21.9222C11.5163 21.668 11.6361 21.4288 11.8608 21.2045L16.1295 16.9433L11.9282 12.7493C11.7035 12.5251 11.5837 12.2858 11.5687 12.0317C11.5687 11.7924 11.6811 11.5607 11.9057 11.3364C12.1304 11.1121 12.3625 11 12.6022 11C12.8568 11.015 13.0965 11.1346 13.3211 11.3588L17.5224 15.5528L21.6563 11.4261C21.896 11.1869 22.1356 11.0673 22.3753 11.0673C22.6299 11.0523 22.877 11.1645 23.1167 11.4037C23.3563 11.6429 23.4686 11.8896 23.4537 12.1438C23.4537 12.383 23.3338 12.6223 23.0942 12.8615L18.9603 16.9881L23.1616 21.1821C23.3863 21.4063 23.4986 21.6381 23.4986 21.8773C23.5136 22.1315 23.4087 22.3707 23.1841 22.595C22.9594 22.8193 22.7197 22.9239 22.4651 22.909C22.2255 22.909 21.9933 22.7968 21.7687 22.5726L17.5674 18.3786L13.2987 22.6398C13.074 22.8641 12.8344 22.9837 12.5797 22.9987C12.3251 23.0136 12.078 22.9015 11.8383 22.6623Z" fill="black"/>
      </svg>
    </div>

  </div>

</div>
