<mvm-core>
  <p class="dashboard-welcome text-white text-super-h1 text-center">{{'dashboardTitle' | translate}} {{(auth$ | async)?.currentUser?.name}}!</p>

  <section class="container-white">

    <section class="dashboard-cards">

      <section class="container-vip" >
        <mvm-vip-message *ngIf="(vipMessage$ | async)" [message]="(vipMessage$ | async) ?? ''"></mvm-vip-message>
        <mvm-incoming-events  class="mobile-hidden-1200"></mvm-incoming-events>
      </section>

      <mvm-grey-frame type="tickets"> <!---->
        <mvm-my-ticket [isRequesting]="isRequesting" [events]="(events$ | async) ?? []" class="w-full"></mvm-my-ticket>
      </mvm-grey-frame>

      <section class="container-foodsAndBills">
        <mvm-grey-frame type="foods" *ngIf="true || (showVipFunctions$ | async)">
          <mvm-card-food-ad class="w-full"></mvm-card-food-ad>
        </mvm-grey-frame>
        <mvm-grey-frame class="mobile-hidden-1200" type="bills" *ngIf="(invoices$ | async)?.length">
          <mvm-bills [bills]="(invoices$ | async) ?? []" class="w-full"></mvm-bills>
        </mvm-grey-frame>
      </section>

      <mvm-incoming-events class="desktop-hidden-1200"></mvm-incoming-events>

      <mvm-grey-frame class="desktop-hidden-1200" type="bills" *ngIf="(invoices$ | async)?.length">
        <mvm-bills [bills]="(invoices$ | async) ?? []" class="w-full"></mvm-bills>
      </mvm-grey-frame>

    </section>

    <mvm-container [type]="ContainerType.HowToApproach">
      <div class="flex flex-col gap-45px" id="dashboard-how-to-approach">
        <section class="container-grey">
          <mvm-how-to-approach></mvm-how-to-approach>
        </section>
      </div>
    </mvm-container>

    <!--<mvm-partner-banner></mvm-partner-banner>-->
    <!-- /21705402174/mvmdome_profil -->
    <!--<div class="advert-center" id='div-gpt-ad-1680630181401-0' style='min-width: 250px; min-height: 200px;'></div>-->

    <!--<section class="container-dashboard-homeDetails">
      <mvm-home-details></mvm-home-details>
    </section>

    <mvm-container [type]="ContainerType.InteractiveMapControls">
      <mvm-interactive-map></mvm-interactive-map>
    </mvm-container>-->


  </section>
</mvm-core>

