import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader
} from "@gilsdav/ngx-translate-router";
import {TranslateService} from "@ngx-translate/core";
import {Location} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {LocalizeRouterHttpLoader} from "@gilsdav/ngx-translate-router-http-loader";

export const routes: Routes = [
  { path: '',
    loadChildren: () => import('./core/core.module').then(m => m.CoreModule),
    data: { discriminantPathKey: 'COREPATH' },
  }
];
export function HttpLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) {
  return new LocalizeRouterHttpLoader(translate, location, { ...settings }, http);
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      /*anchorScrolling: "enabled", 
      scrollPositionRestoration: "enabled", */
      onSameUrlNavigation: 'reload',
    }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: HttpLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
      },
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
