import {createFeatureSelector, createSelector} from "@ngrx/store";
import { ParkingState } from "../states/parking.state";

export const parkingFeatureKey = 'parking';

export const selectParking = createFeatureSelector<ParkingState>(parkingFeatureKey)

export const selectParkingEvents = createSelector(
    selectParking,
    (state: ParkingState) => state.events
)

export const selectParkingTypes = createSelector(
    selectParking,
    (state: ParkingState) => state.parkingTypes
)
