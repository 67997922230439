import { Component, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ContainerTypes } from "../../../../shared/components/container/container.component";
import { FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { growInOut } from "../../../../shared/animations/growInOut";
import { CheckoutApiService } from "../../../../shared/services/apiServices/checkoutApiService/checkout-api.service";
import { CartOrderData } from "../../../../shared/interfaces/checkout-cart/checkout-cart.models";
import { Subject, takeUntil } from 'rxjs';
import { HandleErrorAndSuccess } from 'src/app/shared/interfaces/common/handle-error-and-success';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrorModalService } from 'src/app/shared/services/errorService/error-modal.service';
import { AuthApiService, AuthMeResponse } from "../../../../shared/services/apiServices/authApiService/auth-api.service";
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import {
  ProfileApiService,
  ProfileData
} from "../../../../shared/services/apiServices/profileApiService/profile-api.service";
import { countries } from 'src/app/datas/countries';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mvm-checkout-billing-and-payment',
  templateUrl: './checkout-billing-and-payment.component.html',
  styleUrls: ['./checkout-billing-and-payment.component.scss'],
  animations: growInOut,
})

export class CheckoutBillingAndPaymentComponent implements OnInit, OnDestroy, HandleErrorAndSuccess {
  ContainerTypes = ContainerTypes
  public billingDetailsForm!: FormGroup
  private destroy$ = new Subject<void>()
  public simpleFormToSubmit: any = ""
  public countries = ['Magyarország', 'Anglia']
  authMeResponse?: AuthMeResponse
  profileData?: ProfileData

  public customPatterns = { '*': { optional: true, pattern: new RegExp('\[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõőűøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚŰŐÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,-\]')} };
  public customPatterns2 = { '0': { pattern: new RegExp('\[0123456789]'), optional: true}, 'A' :  { pattern: new RegExp('\[0123456789+]'), optional:true} };

  constructor(
    _fb: FormBuilder,
    private checkoutService: CheckoutApiService,
    private sanitized: DomSanitizer,
    private authApi: AuthApiService,
    private _router: Router,
    private _errorModalService: ErrorModalService,
    private localizeRouterService: LocalizeRouterService,
    private profileService: ProfileApiService,
    private translate: TranslateService,
  ) {
    this.billingDetailsForm = _fb.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      phone: ['', Validators.required],
      email: [''],
      country: ['Magyarország', Validators.required],
      zipcode: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required],
      hasBillingAddress: [false],
      invoice_country: ['Magyarország'],
      invoice_zip: [''],
      invoice_city: [''],
      invoice_address: [''],
      company: [''],
      vat_number: [''],
      terms: [false, Validators.requiredTrue],
      //houseRules : [false, Validators.requiredTrue],
      payment_method: ['', Validators.required],
    })

  }

  public ngOnInit(): void {
    this.countries = this.translate.currentLang == 'hu' ? countries.hu.sort() : countries.en.sort()


    this.billingDetailsForm.get('hasBillingAddress')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.billingAddressCheckboxChanged()
      })

    this.authApi.meSimple((res) => {
      this.authMeResponse = res
    })
    this.profileService.getProfileData((res) => {
      this.profileData = res.data
      this.billingDetailsForm?.get('email')?.setValue(this.profileData?.email)
    })

  }

  public ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  public billingAddressCheckboxChanged() {
    const invoiceInputs = ['company', 'invoice_country', 'invoice_zip', 'invoice_city', 'invoice_address',]


    invoiceInputs.map((value) => {
      if (this.billingDetailsForm.get('hasBillingAddress')?.value == true) {
        this.billingDetailsForm.get(value)?.setValidators([Validators.required])
      } else {
        this.billingDetailsForm.get(value)?.clearValidators()
      }
      this.billingDetailsForm.get(value)?.updateValueAndValidity();
    })

  }

  setCountry(country: string) {
    this.billingDetailsForm.get('country')?.setValue(country)
  }

  setInvoiceCountry(country: string) {
    this.billingDetailsForm.get('invoice_country')?.setValue(country)
  }

  get hasBillingAddress(): boolean {
    return this.billingDetailsForm.value.hasBillingAddress
  }

  paymentProviderSelected(selectedPaymentProvider: string) {
    this.billingDetailsForm.get('payment_method')?.setValue(selectedPaymentProvider)
  }

  submitBilling() {
    this._markAllInputs()
    
    if(!this.accepted2) {
      this._errorModalService.showErrorTranslate("dynamic.text9")
      return;
    }

    if (!this.billingDetailsForm.valid) {
      this._errorModalService.showErrorTranslate("dynamic.text3")
      return;
    }

    this.checkoutService.order(this.billingDetailsForm.value, this)
  }

  handleErrorResponse(err: string): void {

  }

  handleSuccessResponse(res?: any): void {

  }

  /* returns validation errors */
  accepted: boolean = true;
  accepted2: boolean = false;
  accepted3: boolean = false;

  public getError(attribute: string, validationRule: string) {
    return this.billingDetailsForm.get(attribute)?.touched && this.billingDetailsForm.get(attribute)?.errors?.[validationRule]
  }

  private _markAllInputs() {
    Object.keys(this.billingDetailsForm.controls).map((input) => {
      this.billingDetailsForm.get(input)?.markAsTouched()
    })
  }
}

