import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiRequestInterface } from './api-request.interface';
import { ErrorModalService } from "../../errorService/error-modal.service";
import { TokenService } from "../../auth-services/token.service";

@Injectable({
  providedIn: 'root'
})
export class ApiRequestService implements ApiRequestInterface {

  private _baseUri: string
  private _actionsObserver: any

  constructor(
    private _http: HttpClient,
    private errorService: ErrorModalService,
    //private tokenSerice: TokenService
  ) {
    this._baseUri = environment.apiBaseUrl
  }

  /* Handles POST Request
   *
   */
  public postRequest(route: string, data: object, successCallback: (res: any) => void = (res) => { }, errorCallback: (res: any) => void = (res) => { }, needModalOnError: boolean = false, needFullError: boolean = false): void {

    /* SET TOKEN FOR AUTHENTICATED USER (development only) */
    if (true || !environment.production) {
      const token = localStorage.getItem('bearer_token')

      if (token) {
        data = { userToken: token, ...data }
      }
    }

    /* SET OBSERVER */
    const actionsObserver = this._setActionsObserver(successCallback, errorCallback, needModalOnError, needFullError)

    /* REQUEST */
    this._http.post(this._baseUri + route, data).subscribe(actionsObserver)
  }

  /* Handles GET Request
  *
  */
  public getRequest(route: string, successCallback: (res: any) => void = (res) => { }, errorCallback: (res: any) => void = (res) => { }, needModalOnError: boolean = false, params?: HttpParams, needFullError: boolean = false): void {

    /* SET OBSERVER */
    const actionsObserver = this._setActionsObserver(successCallback, errorCallback, needModalOnError, needFullError)

    /* REQUEST */
    this._http.get(this._baseUri + route, { params: params }).subscribe(actionsObserver)
  }

  /* Handles DELETE Request
  *
  */
  public deleteRequest(route: string, successCallback: (res: any) => void = (res) => { }, errorCallback: (res: any) => void = (res) => { }, needModalOnError: boolean, needFullError: boolean = false): void {

    /* SET OBSERVER */
    const actionsObserver = this._setActionsObserver(successCallback, errorCallback, needModalOnError, needFullError)

    /* REQUEST */
    this._http.delete(this._baseUri + route).subscribe(actionsObserver)
  }

  /* Handles PUT Request
  *
  */
  public putRequest(route: string, data: object, successCallback: (res: any) => void = (res) => { }, errorCallback: (res: any) => void = (res) => { }, needModalOnError: boolean): void {

    /* SET TOKEN FOR AUTHENTICATED USER (development only) */
    if (true || !environment.production) {
      const token = localStorage.getItem('bearer_token')

      if (token) {
        data = { userToken: token, ...data }
      }
    }
    
    /* SET OBSERVER */
    const actionsObserver = this._setActionsObserver(successCallback, errorCallback, needModalOnError)

    /* REQUEST */
    this._http.put(this._baseUri + route, data).subscribe(actionsObserver)
  }

  /* Handles PATCH Request
  *
  */
  public patchRequest(route: string, data: object, successCallback: (res: any) => void = (res) => { }, errorCallback: (res: any) => void = (res) => { }, needModalOnError: boolean, params: HttpParams | undefined = undefined): void {

    /* SET OBSERVER */
    const actionsObserver = this._setActionsObserver(successCallback, errorCallback, needModalOnError)

    /* REQUEST */
    this._http.put(this._baseUri + route, data, { params: params }).subscribe(actionsObserver)
  }

  /* SETS ACTIONS OBSERVER */
  private _setActionsObserver(successCallback: (res: any) => void = (res) => { }, errorCallback: (res: any) => void = (res) => { }, needModalOnError: boolean, needFullError: boolean = false) {

    return {
      next: (res: any) => {
        successCallback(res)
      },
      error: (err: any) => {
        this._handleErrorModal(err, needModalOnError)

        errorCallback(needFullError ? err : (err.statusText ?? err.error_msg))
      },
    }
  }


  /* handles error modal if needed */
  private _handleErrorModal(e: any, showModal: boolean = false) {
    /* if modal is not needed then don't do anything */
    if (!showModal) return;

    const errorMsg = e.error.error_msg ?? e.statusText
    /* show modal with error msg */
    this.errorService.showError(errorMsg)
  }
}
