import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import { TicketTypes } from 'src/app/shared/enums/ticket-types.enum';
import { slideAppearRotateAnimation } from 'src/app/shared/animations/home/slide-appear-rotate.animation';
import {AngularSvgIconModule} from "angular-svg-icon";

interface Description {
  label: string
  text: string[]
}

@Component({
  selector: 'mvm-dropdown-ticketinfo',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  templateUrl: './dropdown-ticketinfo.component.html',
  styleUrls: ['./dropdown-ticketinfo.component.scss'],
  animations: slideAppearRotateAnimation
})
export class DropdownTicketinfoComponent {

  @Input() label: string =""
  @Input() text: string = ""
  open: boolean = false;

}
