<!-- desktop seatmap -->
<div class="desktop-seatmap"  *ngIf="showDesktopSeatMap" (click)="toggleDesktopSeatMap(false)">
  <div class="seatmap" (click)="$event.stopPropagation()">
    <h2>{{ desktopSeatMapSelectedTicketType == 'skybox' ? ('skyboxMapChoose' | translate) : ('seatMapChoose' | translate) }}</h2>
    <div id="desktop-modal-seatmap-holder">
      <div class="color-infos">
        <div class="colorText">
          <span class="colorbox red"></span>
          <p>Foglalt</p>
        </div>
        <div class="colorText">
          <span class="colorbox yellow"></span>
          <p>Kijelölve</p>
        </div>
        <div class="colorText">
          <span class="colorbox green"></span>
          <p>Szabad</p>
        </div>
      </div>
      <mvm-seat-map [eventType]="desktopSeatMapSelectedTicketType" [eventSlug]="slug"></mvm-seat-map>
    </div>
    <div id="seatmap-modal-bottom">
      <p class="auto-choose-text" (click)="toggleDesktopSeatMap(false)">{{ ('home.ticketSelector.autoChoose' | translate) }}</p>
      <div class="infos">
        <p class="text-cart-bold">
          {{getSelectedTicketNumber(cart$ | async)}} jegy kijelölve
        </p>

        <p class="text-cart-bold">
          Összesen: {{getSelectedTicketsPrice(cart$ | async)}} Ft
        </p>

        <a [routerLink]="'/cart' | localize" class="btn-yellow" [attr.aria-label]="'home.ticketSelector.navigateToCart' | translate">{{ 'home.ticketSelector.navigateToCart' | translate }}</a>
      </div>

    </div>
  </div>
</div>
<!-- desktop seatmap -->

<mvm-core>
  <section class="program-details" *ngIf="!isWebView"> 
    <mvm-home-program-details [programDetails]="(event$ | async)"></mvm-home-program-details>
  </section>
  <section id="event-tickets" *ngIf="(event$ | async)?.can_be_purchased" class="ticket-selector">
    <mvm-container [type]="ContainerTypes.ProgramDetailsTicketSelector">

      <div class="only-mobile from-tablet">
        <h2 class="text-super-h1 text-white text-center">{{'program.ticketType' | translate}}</h2>

        <div class="card-selector-wrapper" *ngFor="let t of (tickets$ | async)">
          <mvm-ticket-selector-card [event_slug]="slug" [quantity]="getNumberOfTicketsForEventAndType(t.type)"
                                    [ticket]="t" [price]="t.raw_price" [price_text]="t.price"
                                    [type]="getTicketType(t.type)">
          </mvm-ticket-selector-card>
        </div>
      </div>

      <div class="only-desktop from-tablet ticket-selector-cards">
        <div id="tickets-slider" class="cards-wrapper">
          <div class="placeholder"></div>
          <ng-container *ngFor="let t of (tickets$ | async)">
            <mvm-ticket-selector-card
              *ngIf="!t.is_full"
              [scrollable]="true"
              [quantity]="getNumberOfTicketsForEventAndType(t.type)"
              [ticket]="t"
              [event_slug]="slug"
              [price]="t.raw_price"
              [price_text]="t.price"
              [type]="getTicketType(t.type)"
              (showDesktopMap)="toggleDesktopSeatMap(true);desktopSeatMapSelectedTicketType=t.type"
            />
          </ng-container>
          <mvm-card-simple-tickets *ngIf="!isWebView"></mvm-card-simple-tickets>
          <div class="placeholder"></div>
        </div>
      </div>
    </mvm-container>
  </section>
  <section class="simple-tickets only-mobile from-tablet" *ngIf="!isWebView">
    <mvm-container>
      <mvm-card-simple-tickets></mvm-card-simple-tickets>
    </mvm-container>
  </section>
  <section class="how-to-approach" *ngIf="!isWebView">
    <mvm-container [type]="ContainerTypes.HowToApproach">
      <mvm-how-to-approach></mvm-how-to-approach>
    </mvm-container>
  </section>
  <!--<section class="interactive-map" *ngIf="!isWebView">
    <mvm-container [type]="ContainerTypes.InteractiveMapControls">
      <mvm-interactive-map></mvm-interactive-map>
    </mvm-container>
  </section>-->
  <section class="more-programs" *ngIf="!isWebView">
    <mvm-container [type]="ContainerTypes.Full">
      <h2 class="text-h1">{{'program.more' | translate}}</h2>
      <p>{{'program.interest' | translate}}</p>
      <div class="cards-container" id="details-slider">
        <mvm-card-program [scrollable]="true" *ngFor="let p of (other_events$ | async)" [program]="p">
        </mvm-card-program>
      </div>
    </mvm-container>
  </section>
  <section class="partners" *ngIf="!isWebView">
    <mvm-container [type]="ContainerTypes.Partners">
      <mvm-partners></mvm-partners>
    </mvm-container>
  </section>
</mvm-core>
