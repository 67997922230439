import {Component} from '@angular/core';
import {ChildrenOutletContexts, NavigationEnd, Router} from '@angular/router';
import {routeAnimaitons} from './shared/animations/route/route.animation';
import {TranslateService} from "@ngx-translate/core";
import {LocalizeRouterService} from "@gilsdav/ngx-translate-router";
import {ErrorModalService} from "./shared/services/errorService/error-modal.service";
import {SuccessModalService} from "./shared/services/SuccessService/success-modal.service";
import {filter, takeUntil} from "rxjs";
import {Meta, Title} from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { initializeAuthFactory } from './shared/services/app-initializer/auth-initializer.service';
import { TokenService } from './shared/services/auth-services/token.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { CartApiService } from './shared/services/apiServices/cartApiService/cart-api.service';
import { HelperService } from './utils/helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    routeAnimaitons
  ]
})
export class AppComponent {
  title = '';
  checkedValue: string = ""

  constructor(
    private titleS: Title, 
    private aRoute: Router, 
    private contexts: ChildrenOutletContexts, 
    private translate: TranslateService, 
    private localize: LocalizeRouterService, 
    private error: ErrorModalService, 
    private success: SuccessModalService,
    private metaService: Meta,
    private _helperService: HelperService,
    private _tokenService: TokenService, 
    private _httpClient: HttpClient, 
    private _store: Store, 
    private _cartApiService: CartApiService,
  ) {
    let lang = translate.currentLang

    translate.setDefaultLang(lang)
    localStorage.setItem('lang', lang)
    translate.use(lang)
    localize.changeLanguage(lang, {
      replaceUrl: true
    })
    /* set if webview */
    this.isWebView()

    /* set redirect url for web view */
    this.setRedirectUrlForWebView()
      
    this.aRoute.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(e => {
      const data = this.contexts.getContext('primary')?.route?.data
      data?.subscribe(data => {
        if(data) {
          this.translate.get(data['title']).subscribe(titleTranslated => {
            this.titleS.setTitle(titleTranslated + ' | MVM Dome')
          })
        } else {
          this.title = ''
        }
      })
    })
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  private setRedirectUrlForWebView() {
    const queryString = window.location.search;
    
    /* no query string */
    if(!queryString.length) {
      return 
    }

    const urlParams = new URLSearchParams(queryString);
    
    /* webview is not set */
    if(!urlParams.get('redirectUrl')) {
      return 
    }

    localStorage.setItem('redirect-url-for-webview', urlParams.get('redirectUrl')!)
  }

  private isWebView() {
    const queryString = window.location.search;
    
    /* no query string */
    if(!queryString.length) {
      return 
    }

    const urlParams = new URLSearchParams(queryString);
    
    /* webview is not set */
    if(!urlParams.get('webview')) {
      return 
    }

    this.setWebViewSetting(urlParams);

    /* call authentication */
    const fn = initializeAuthFactory(this._tokenService, this._httpClient, this._store, this._cartApiService )
    const a = fn();
    a.subscribe()
  }


  private setWebViewSetting(urlParams:URLSearchParams) {
    /* set webview */
    localStorage.setItem('webview', '1')
    localStorage.setItem('cookieAccept', 'accepted')

    /* set bearer token */
    if(urlParams.get('bearer_token')) {
      localStorage.setItem('bearer_token', urlParams.get('bearer_token')!)
    }

    /* set refresh token */
    if(urlParams.get('refresh_token')) {
      localStorage.setItem('refresh_token', urlParams.get('refresh_token')!)
    }

    /* get logged in user */
    
  }
}
