import {catchError, Observable, Observer, of, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {TokenService} from "../auth-services/token.service";
import {MeResponse} from "../../interfaces/api-services/auth/me/me-response.interface";
import {Store} from "@ngrx/store";
import {loginSuccess} from "../../../store/actions/auth.actions";
import {CartApiService} from "../apiServices/cartApiService/cart-api.service";


export function initializeAuthFactory(tokenService: TokenService, client: HttpClient, _store: Store, cartApiService: CartApiService): () => Observable<any> {
  const meObserver: Observer<MeResponse> = {
    next: (res: MeResponse) => {
      if (res) {
        //this._store.dispatch(loginSuccess({user: { name: res?.data?.user?.name ?? email }}))
        _store.dispatch(loginSuccess({user: { name: res?.data?.user?.name }}))
      } else {
        // api currently gives back null on error TODO: remove
        tokenService._removeTokens()
      }
      meObserver.complete()
    },
    error: (e: any) => {
      if (e.status == 422) {
        tokenService._removeTokens()
      }
      meObserver.next({} as MeResponse)
      meObserver.complete()
    },
    complete: () => {

    }
  }

  const token = tokenService._retrieveBearerToken()

  // If there is no stored token, exit init sequence
  if (!token) return () => of(true)
  return () => client.post<MeResponse>(`${environment.apiBaseUrl}auth/me`, {userToken: tokenService._retrieveBearerToken()})
    .pipe(
      tap(meObserver),
    ).pipe(catchError((err, caught) => {
      tokenService._removeTokens()
      if (err.status == 422) {
        tokenService._removeTokens()
      }
      meObserver.complete()
      return of(true)
    }))
}
