import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {CoreComponent} from "../../layouts/core-layout/core.component";

@Component({
  selector: 'mvm-impressum',
  standalone: true,
  imports: [CommonModule, TranslateModule, CoreComponent],
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent {

}
